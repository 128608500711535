import React, { Component } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Base64 } from "js-base64";
import { datahost, shkyhost, gesture, pic_host } from "../../config/Const.js";
import { passParams } from "../../config/Connector.js";
import { replaceAllFixed } from "../../function/Basic.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/CondoNews.css";

export class NewsDetail extends Component {
  state = {
    lang: enj,
    tk: null,
    id: null,
    article: null,
  };

  componentDidMount() {
    this.initLanguage();
    this.loadPage();
  }

  render() {
    return (
      <div id="newsDetail">
        <div>
          <Button id="pro-btn-mf" onClick={() => this.BackClick()}>
            <i className="fa fa-angle-left"></i> {this.state.lang.back}
          </Button>
          <Button
            id="pro-btn-mf"
            className="pull-right"
            onClick={() => this.shareMedia()}
          >
            <i className="fa fa-share"></i>
            {this.state.lang.share}
          </Button>
          <br />
        </div>
        <br />
        <strong>
          <div id="newsTitle" className="news-title"></div>
        </strong>
        <br />
        <div id="newsContent"></div>
        <div id="footer">
          <br />
          <br />
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadPage() {
    try {
      let url = document.location.toString();
      let paras = url.split("?")[1];
      let tk = paras.split("=")[0];
      let id = paras.split("=")[1];
      this.setState({ tk: tk, id: id });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'tk':'" + tk + "','id':'" + id + "'}\"";
      axios.post("/article/yijudet", param).then((res) => {
        this.setState({ article: res.data });
        var content = res.data.Content;
        if (content !== undefined) {
          content = replaceAllFixed(
            content,
            "/aprg",
            gesture + pic_host + "/aprg"
          );
          content = replaceAllFixed(
            content,
            "/eadf26jst",
            gesture + pic_host + "/eadf26jst"
          );
          document.getElementById("newsContent").innerHTML = content;
        }
        if (res.data.Title !== undefined) {
          document.getElementById("newsTitle").innerText = res.data.Title;
          document.title = res.data.Title;
        }
      });
    } catch {}
  }

  shareMedia() {
    axios.defaults.baseURL = datahost;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    let param =
      "\"{'tk':'" +
      this.state.tk +
      "','id':'" +
      this.state.id +
      "','hd':'1'}\"";
    axios.post("/share/token", param).then((res) => {
      let url = shkyhost + "/yiju/shnews?" + res.data;
      var md = this.state.article;
      // get image thumbnail by add 'tbn_' pre
      md.Image = md.Image.replace("/articleimgs/", "/articleimgs/tbn_");
      let str = url + ";" + md.Title + ";" + md.Brief + ";" + md.Image;
      let params = Base64.encode(str);
      passParams("sharemedia", params);
    });
  }

  BackClick() {
    window.history.go(-1);
  }
}
