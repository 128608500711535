import React from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import BraftEditor from "braft-editor";
import { datahost } from "../../config/Const.js";
import { replaceAllFixed } from "../../function/Basic.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "braft-editor/dist/index.css";
import "../../../style/Article.css";

export class S_C_Article extends React.Component {
  state = {
    lang: enj,
    tk: null,
    tit: null,
    editorState: null,
    link: null,
  };

  async componentDidMount() {
    // set token and pre-define draft
    this.loadToken();
    this.loadDraft();
  }

  submitContent = async () => {
    // Pressing ctrl + s when the editor has focus will execute this method
    // Before the editor content is submitted to the server, you can directly call editorState.toHTML () to get the HTML content
    const htmlContent = this.state.editorState.toHTML();
    // const result = await saveEditorContent(htmlContent);
  };

  handleEditorChange = (editorState) => {
    this.setState({ editorState });
  };

  render() {
    const { editorState } = this.state;

    return (
      <div class="my-component">
        <Button id="pro-btn-mf" onClick={() => this.BackClick()}>
          <i class="fa fa-angle-left"></i>
          {this.state.lang.back}
        </Button>
        <br />
        <input
          name="title"
          id="title"
          class="media_inputext"
          placeholder="Title"
        />
        <BraftEditor
          value={editorState}
          language="en"
          onChange={this.handleEditorChange}
          onSave={this.submitContent}
        />
        <Button id="pro-btn-mf" onClick={() => this.postMedia()}>
          <i class="fa fa-eye"></i>
          {this.state.lang.share}
        </Button>

        <div id="footer"></div>
      </div>
    );
  }

  loadToken() {
    try {
      var url = document.location.toString();
      let tk = url.split("?").splice(1, 1)[0];
      this.setState({ tk: tk });
    } catch {}
  }

  loadDraft() {
    // Assume here to get the editor content in html format from the server
    const htmlContent = "";
    // Use BraftEditor.createEditorState to convert html strings to editorState data needed by the editor
    this.setState({
      editorState: BraftEditor.createEditorState(htmlContent),
    });
  }

  async postMedia() {
    try {
      let et_title = document.getElementById("title");
      if (et_title.innerText === null || et_title.innerText === "") {
        return;
      }
      // push to server
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";

      var html = this.state.editorState.toHTML();
      html = replaceAllFixed(html, '"', "`_`");
      let param =
        "\"{'tk':'" +
        this.state.tk +
        "','tit':'" +
        et_title.value +
        "','bd':'" +
        html +
        "'}\"";
      axios.post("/article/postmedia", param).then((res) => {
        this.props.history.push("/preshmedia?" + res.data);
      });
    } catch {}
  }

  BackClick() {
    window.history.go(-1);
  }
}
