import React from "react";

const LoadingSkeleton = () => {
  return (
    <div>
      <span
        className="skeleton-box"
        style={{ width: "100%", height: "80px" }}
      ></span>
    </div>
  );
};

export default LoadingSkeleton;
