import React from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { gesture, datahost } from "../../config/Const.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "braft-editor/dist/index.css";
import "../../../style/Article.css";

export class S_L_Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      list: [],
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadList();
  }

  render() {
    return (
      <div>
        <div id="loginrequire" hidden="true">
          <div className="loginrequire">
            <div className="hide_propertysec">
              <div className="inner_hidepropsec">
                <i className="fas fa-user-lock"></i>
                <p>{this.state.lang.loginrequire}</p>
              </div>
            </div>
            <div id="detail_history">
              <ul className="main_historysec"></ul>
            </div>
          </div>
        </div>
        <div className="shared_msgs_list" id="context" hidden="true">
          <Button
            id="pro-btn-mf"
            className="new_pagelink_btn"
            onClick={() => this.jumpNew()}
          >
            <i className="fa fa-scroll"></i>
            {this.state.lang.share_newpage}
          </Button>
          {/* <Button
          id="pro-btn-mf"
          className="new_pagelink_btn"
          onClick={() => this.jumpCopy()}
        >
          <i class="fa fa-file-upload"></i> Load Link
        </Button> */}
          <div className="panel">
            <div className="inner_prohistory_sec">
              <div id="media_list">
                <ul className="main_historysec"></ul>
              </div>
            </div>
          </div>

          <div id="footer"></div>
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadList() {
    try {
      var url = document.location.toString();
      let tk = url.split("?").splice(1, 1)[0];
      this.setState({ tk: tk });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";

      // check token level
      let param_tklvl = "\"{'tk':'" + tk + "'}\"";
      axios.post("/token/level", param_tklvl).then((res) => {
        if (res.data > 2) {
          // get list data
          let param = "\"{'tk':'" + tk + "'}\"";
          axios.post("/article/medialist", param).then((res) => {
            if (res.data === "") {
              return;
            }
            this.setState({ list: this.fillMedias(res.data) });
            this.createMediaList(this.state.list);
          });
          document.getElementById("context").hidden = false;
          document.getElementById("loginrequire").hidden = true;
        } else {
          document.getElementById("context").hidden = true;
          document.getElementById("loginrequire").hidden = false;
        }
      });
    } catch {}
  }

  jumpNew() {
    this.props.history.push("/postsharedmedia?" + this.state.tk);
  }

  jumpCopy() {
    this.props.history.push("/copysharedmedia?" + this.state.tk);
  }

  fillMedias = (data) => {
    let str_0 = gesture + document.location.host + "/preshmediabase?";
    let str_1 = "=";
    data.forEach((element) => {
      // build and fill link
      element["Link"] = str_0 + this.state.tk + str_1 + element.Id;
    });
    return data;
  };

  createMediaList(arr) {
    var st = document.getElementById("media_list");
    if (arr !== null && arr !== []) {
      for (var i = 0; i < arr.length; i++) {
        st.children[0].innerHTML += this.buildMediaElement(arr[i]);
      }
    }
  }

  buildMediaElement(ele) {
    var result = "";
    result += '<li><a href="';
    result += ele.Link;
    result +=
      '"><div class="newsdetail_sec"><div class="newsimg_sec"><img src=';
    result += ele.FirstImage;
    result += ' alt="" /></div><div class="news_contsec"><h2>';
    result += ele.NTitle;
    result += " </h2><p>";
    result += ele.NBrief;
    result += " </p></div></div></a></li>";
    return result;
  }
}
