// import echarts from "echarts/lib/echarts";
// import * as echarts from "echarts/lib/echarts";
import * as echarts from "echarts/dist/echarts.js";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";
import "echarts/lib/component/legend";
import "echarts/lib/component/visualMap";

export function SetChartListing(
  mtitle,
  mlegendseries,
  xaxis,
  yaxis,
  mdata,
  mcolor,
  container
) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);
  var option = {
    color: mcolor,
    title: {
      text: mtitle[0],
      subtext: mtitle[1],
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
        color: "#000",
      },
    },
    tooltip: {
      trigger: "axis",
      position: [0, 0],
      axisPointer: {
        type: "shadow",
      },
      backgroundColor: "rgba(255,255,255,0.9)",
      borderWidth: "1",
      borderColor: "grey",
      textStyle: {
        fontSize: 14,
      },
    },
    legend: {
      top: 50,
      left: "center",
      data: mlegendseries,
    },
    grid: {
      left: 50,
      top: 170,
      bottom: 60,
    },
    xAxis: [
      {
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          onZero: false,
        },
        data: xaxis,
      },
    ],
    yAxis: [
      {
        type: "value",
        name: yaxis[0],
        axisLabel: {
          formatter: "{value}",
        },
      },
      {
        type: "value",
        name: yaxis[1],
        axisLabel: {
          formatter: "{value}",
        },
      },
    ],
    series: [
      {
        name: mlegendseries[0],
        type: "line",
        smooth: true,
        data: mdata[0],
      },
      {
        name: mlegendseries[1],
        type: "line",
        smooth: true,
        data: mdata[1],
      },
      {
        name: mlegendseries[2],
        type: "bar",
        yAxisIndex: 1,
        data: mdata[2],
      },
    ],
  };
  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }
}

export function SetChartPrice(
  mtitle,
  mlegendseries,
  xaxis,
  mdata,
  mcolor,
  container
) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);
  var option = {
    color: mcolor,
    title: {
      text: mtitle[0],
      subtext: mtitle[1],
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
        color: "#000",
      },
    },
    tooltip: {
      trigger: "axis",
      position: [0, 0],
      axisPointer: {
        type: "shadow",
      },
      backgroundColor: "rgba(255,255,255,0.9)",
      borderWidth: "1",
      borderColor: "grey",
      textStyle: {
        fontSize: 14,
      },
    },
    grid: {
      left: 80,
      top: 180,
      bottom: 60,
    },
    legend: {
      top: 50,
      left: "center",
      data: mlegendseries,
    },
    xAxis: [
      {
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          onZero: false,
        },
        data: xaxis,
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: mlegendseries[0],
        type: "line",
        smooth: true,
        data: mdata[0],
      },
      {
        name: mlegendseries[1],
        type: "line",
        smooth: true,
        data: mdata[1],
      },
      {
        name: mlegendseries[2],
        type: "line",
        smooth: true,
        data: mdata[2],
      },
      {
        name: mlegendseries[3],
        type: "line",
        smooth: true,
        data: mdata[3],
      },
    ],
  };
  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }
}

export function SetChartMarket(mtitle, xaxis, mdata, mcolor, container) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);
  var option = {
    color: mcolor,
    title: {
      text: mtitle[0],
      subtext: mtitle[1],
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
        color: "#000",
      },
    },
    tooltip: {
      trigger: "axis",
      position: [0, 0],
      axisPointer: {
        type: "shadow",
      },
      backgroundColor: "rgba(255,255,255,0.9)",
      borderWidth: "1",
      borderColor: "grey",
      textStyle: {
        fontSize: 14,
      },
    },
    // toolbox: {
    //   show: true,
    //   // feature: {
    //   //   saveAsImage: {},
    //   // },
    // },
    grid: {
      left: 80,
      top: 60,
      bottom: 60,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xaxis,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}%",
      },
      axisPointer: {
        snap: true,
      },
    },
    visualMap: {
      show: false,
      dimension: 1,
      pieces: [
        {
          lte: 40,
          color: mcolor[2],
        },
        {
          gt: 40,
          lte: 50,
          color: mcolor[1],
        },
        {
          gt: 50,
          color: mcolor[0],
        },
      ],
    },
    series: [
      {
        name: mtitle[0],
        type: "line",
        smooth: true,
        data: mdata,
        markArea: {
          data: [
            [
              {
                name: "HIGH",
                yAxis: "40%",
              },
              {
                yAxis: "60%",
              },
            ],
          ],
        },
      },
    ],
  };
  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }
}

export function SetChartPartition(mtitle, mlegend, mdata, mcolor, container) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);
  var option = {
    color: mcolor,
    title: {
      text: mtitle[0],
      subtext: mtitle[1],
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
        color: "#000",
      },
    },
    tooltip: {
      trigger: "item",
      position: [0, 50],
      formatter: "<b>{b}</b> : <b>{d}%</b> ({c})",
      textStyle: {
        fontSize: 14,
      },
    },
    legend: {
      top: 50,
      left: "center",
      data: mlegend,
    },
    series: [
      {
        name: mtitle[0] + mtitle[1],
        type: "pie",
        radius: "50%",
        center: ["50%", "60%"],
        selectedMode: "single",
        data: mdata,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }
}

export function SetChartDistribution(mcolor, mtitle, xaxis, mdata, container) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);
  var option = {
    color: mcolor,
    title: {
      text: mtitle[0],
      subtext: mtitle[1],
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
        color: "#000",
      },
    },
    tooltip: {
      trigger: "axis",
      position: [0, 30],
      axisPointer: {
        type: "shadow",
      },
      formatter: "<b>${b}</b> -> <b>{c}%</b>",
      borderWidth: "1",
      borderColor: "grey",
      textStyle: {
        fontSize: 14,
      },
    },
    toolbox: {
      show: true,
      // feature: {
      //   saveAsImage: {},
      // },
    },
    grid: {
      left: 80,
      top: 60,
      bottom: 60,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xaxis,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: mdata,
        smooth: true,
        type: "line",
        color: mcolor[0],
        areaStyle: {
          color: mcolor[1],
        },
      },
    ],
  };
  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }
}

export function SetupBothDistribution(titles, price, mcolor, div0, div1) {
  // var titles = ['Sold Price Distribution (recent 6 months)','test', 'Asking Price Distribution (recent 6 months)','test'];
  // var colors = ['#d14a61', '#5793f3', '#f8f2b8', '#54b936', '#EB68CC', '#E8BBF2', '#FEFF82', '#DF61AA'];
  var soldasks = price.split("#");
  soldasks[0] = "0;" + soldasks[0] + ";∞;0";

  var soldL = soldasks[0].split(";");
  var soldX = [];
  var soldP = [];
  for (var i = 0; i < soldL.length; i++) {
    if (i % 2 === 0) {
      if (soldL[i][0] === "-") {
        soldL[i].substring(1, soldL[i].length - 1);
      }
      if (soldL[i] === "∞") {
        soldX.push(soldL[i]);
      } else {
        if (soldL[i].length > 4) {
          soldX.push(soldL[i].substring(0, soldL[i].length - 3) + "k");
        } else {
          soldX.push(soldL[i]);
        }
      }
    } else {
      soldP.push(soldL[i]);
    }
  }
  var soldTitle = [titles[0], titles[1]];
  SetChartDistribution(mcolor, soldTitle, soldX, soldP, div0);

  // TODO : comment tempory.
  // soldasks[1] = "0;" + soldasks[1] + ";∞;0";
  // var asksL = soldasks[1].split(";");
  // var asksX = [];
  // var asksP = [];
  // for (var j = 0; j < asksL.length; j++) {
  //   if (j % 2 === 0) {
  //     if (asksL[j] === "∞") {
  //       asksX.push(asksL[j]);
  //     } else {
  //       if (asksL[j].length > 4) {
  //         asksX.push(asksL[j].substring(0, asksL[j].length - 3) + "k");
  //       } else {
  //         asksX.push(asksL[j]);
  //       }
  //     }
  //   } else {
  //     asksP.push(asksL[j]);
  //   }
  // }
  // var asksTitle = [titles[2], titles[3]];
  // SetChartDistribution(mcolor, asksTitle, asksX, asksP, div1);
}

export function SetupPriceChanges(soldMP) {
  let div = document.getElementById("tit_median");
  let soldMP_R = soldMP.reverse();
  var sum1 = 0;
  var cnt1 = 0;
  var sum2 = 0;
  var cnt2 = 0;
  var sum3 = 0;
  var cnt3 = 0;
  for (var i = 0; i < 12; i++) {
    if (soldMP[i] !== 0) {
      sum1 += soldMP_R[i];
      cnt1 += 1;
    }
    if (soldMP[12 + i] !== 0) {
      sum2 += soldMP_R[12 + i];
      cnt2 += 1;
    }
    if (soldMP[24 + i] !== 0) {
      sum3 += soldMP_R[24 + i];
      cnt3 += 1;
    }
  }
  var ave1 = 0;
  var ave2 = 0;
  var ave3 = 0;
  if (cnt1 !== 0) {
    ave1 = sum1 / cnt1;
  }
  if (cnt2 !== 0) {
    ave2 = sum2 / cnt2;
  }
  if (cnt3 !== 0) {
    ave3 = sum3 / cnt3;
  }

  var incr1 = 0;
  var incr2 = 0;
  if (ave1 !== 0 && ave2 !== 0) {
    incr1 = (ave1 / ave2) * 100;
  }
  if (ave1 !== 0 && ave3 !== 0) {
    incr2 = (ave1 / ave3) * 100;
  }
  div.innerHTML = "";
  if (incr1 !== 0) {
    let chg1 = (incr1 - 100).toFixed(2);
    var zf = "";
    if (chg1 > 0) {
      zf = "+";
    }
    div.innerHTML += `<div><span style="color:var(--font-color)">1 Year</span> <br/><b> ${zf} ${chg1}%</b></div>`;
  }
  if (incr2 !== 0) {
    let chg2 = (incr2 - 100).toFixed(2);
    var zf = "";
    if (chg2 > 0) {
      zf = "+";
    }
    div.innerHTML += `<div><span style="color:var(--font-color)">2 Years</span> <br/><b> ${zf} ${chg2}%</b></div>`;
  }
  if (incr1 === 0 && incr2 === 0) {
    let blk = document.getElementById("title_median");
    blk.hidden = true;
  }

  // let current = soldMP[soldMP.length - 1];
  // let range = parseInt(soldMP.length / 12);
  // div.innerHTML = "";
  // for (var i = 0; i < range; i++) {
  //   let increase =
  //     (current / soldMP[soldMP.length - 1 - (i + 1) * 12] - 1) * 100;
  //   if (increase.toString() === "NaN") {
  //     div.innerHTML += i + 1 + "Years : None<br/>";
  //   } else {
  //     div.innerHTML += i + 1 + "Years : " + increase.toFixed(2) + "%<br/>";
  //   }
  // }
}

export function SetChartCircle(mtitle, mdata, mcolor, container) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);
  var option = {
    color: mcolor,
    title: {
      text: mtitle,
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
        color: "#000",
      },
    },
    // tooltip: {
    //   trigger: "item",
    //   formatter: "{a} <br/>{b} : {c} ({d}%)",
    // },
    // legend: {
    //   bottom: -60,
    //   left: "center",
    //   data: mlegend,
    // },
    series: [
      {
        name: mtitle,
        type: "pie",
        radius: ["40%", "60%"],
        // center: ["50%", "60%"],
        selectedMode: "single",
        data: mdata,
        // hoverOffset: 16,
        // avoidLabelOverlap: false,
        label: {
          normal: {
            formatter: "",
            show: true,
            position: "center",
            rich: { t: { color: "black", fontSize: 14 } },
          },
          emphasis: {
            formatter: ["\n{x|{d}%}", "{y|{c}}", "{z|{b}}"].join("\n"),
            show: true,
            textStyle: { fontSize: "30", fontWeight: "bold" },
            rich: {
              x: { color: "red", fontSize: 30, fontWeight: "bold" },
              y: { color: "blue", fontSize: 18 },
              z: { color: "black", fontSize: 14 },
            },
          },
        },
        labelLine: { normal: { show: false } },
      },
    ],
  };

  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }
}

export function SetChartLineGrown(
  mtitle,
  lines,
  mdata,
  mcolor,
  mcount,
  container
) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);

  const datasetWithFilters = [];
  const seriesList = [];
  echarts.util.each(lines, function (line) {
    var datasetId = "dataset_" + line;
    datasetWithFilters.push({
      id: datasetId,
      fromDatasetId: "dataset_raw",
      transform: {
        type: "filter",
        config: {
          and: [
            { dimension: "Month", gte: 201700 },
            { dimension: "Area", "=": line },
          ],
        },
      },
    });
    seriesList.push({
      type: "line",
      datasetId: datasetId,
      showSymbol: false,
      name: line,
      endLabel: {
        show: true,
        formatter: function (params) {
          return params.value[1] + ": " + params.value[0];
        },
      },
      labelLayout: {
        moveOverlap: "shiftY",
      },
      emphasis: {
        focus: "series",
      },
      encode: {
        x: "Month",
        y: "Value",
        // label: ["Month", "Value"],
        // itemName: "Month",
        // tooltip: ["SoldAveragePrice"],
      },
    });
  });

  var option = {
    color: mcolor,
    animationDuration: mcount * 1000, // duration
    dataset: [
      {
        id: "dataset_raw",
        source: mdata,
      },
      ...datasetWithFilters,
    ],
    title: {
      text: mtitle,
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
        color: "#000",
      },
    },
    tooltip: {
      order: "valueDesc",
      trigger: "axis",
      backgroundColor: "rgba(255,255,255,0.8)",
      position: [0, 0],
      borderWidth: "1",
      borderColor: "grey",
      // formatter: "<b>{b}</b> : <b>{d}%</b> ({c})",
      textStyle: {
        fontSize: 14,
      },
    },
    xAxis: {
      type: "category",
      nameLocation: "middle",
    },
    yAxis: {
      name: "", //"VALUE",
    },
    grid: {
      left: 70,
    },
    series: seriesList,
  };

  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }
}

export function SetChartBarGrown(
  mtitle,
  lines,
  mdata,
  mcolor,
  mtimes,
  container
) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);

  const updateFrequency = 1000; // duration
  const dimension = 0;
  const items = [];
  // const items = [];
  for (let i = 0; i < lines.length; i++) {
    items[lines[i]] = mcolor[i];
  }

  const data = mdata;
  const years = mtimes;

  let startIndex = 0;
  let startYear = years[startIndex];

  var option = {
    color: mcolor,
    grid: {
      left: 90,
      right: 60,
    },
    title: {
      text: mtitle,
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
        color: "#000",
      },
    },
    xAxis: {
      max: "dataMax",
      axisLabel: {
        formatter: function (n) {
          return Math.round(n) + "";
        },
      },
    },
    dataset: {
      source: data.slice(1).filter(function (d) {
        return d[2] === startYear;
      }),
    },
    yAxis: {
      type: "category",
      inverse: true,
      max: 10,
      axisLabel: {
        show: true,
        fontSize: 14,
      },
    },
    series: [
      {
        realtimeSort: true,
        seriesLayoutBy: "column",
        type: "bar",
        itemStyle: {
          color: function (param) {
            return items[param.value[1]] || "#5470c6";
          },
        },
        encode: {
          x: dimension,
          y: 3,
        },
        label: {
          show: true,
          precision: 1,
          position: "right",
          valueAnimation: true,
          fontFamily: "monospace",
        },
      },
    ],
    // Disable init animation.
    animationDuration: 0,
    animationDurationUpdate: updateFrequency,
    animationEasing: "linear",
    animationEasingUpdate: "linear",
    graphic: {
      elements: [
        {
          type: "text",
          right: 60,
          bottom: 60,
          style: {
            text: startYear,
            font: "bolder 80px monospace",
            fill: "rgba(100, 100, 100, 0.25)",
          },
          z: 100,
        },
      ],
    },
  };

  if (option && typeof option === "object") {
    myChart.setOption(option, true);

    for (let i = startIndex; i < years.length - 1; ++i) {
      (function (i) {
        setTimeout(function () {
          updateYear(years[i + 1]);
        }, (i - startIndex) * updateFrequency);
      })(i);
    }
    function updateYear(year) {
      let source = data.slice(1).filter(function (d) {
        return d[2] === year;
      });
      option.series[0].data = source;
      option.graphic.elements[0].style.text = year;
      myChart.setOption(option);
    }
  }
}
