import React from "react";
import { Button, Image } from "react-bootstrap";
import axios from "axios";

import { datahost } from "../../config/Const.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";
import "../../../style/AgentList.css";

export class AgentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      list: [],
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadList();
  }

  render() {
    return (
      <div className="wrapper_margin">
        <Button
          id="pro-btn-mf"
          className="new_pagelink_btn"
          onClick={() => this.BackClick()}
        >
          <i className="fa fa-angle-left"></i>
          {this.state.lang.back}
        </Button>
        <br />
        <br />

        <div>
          {this.state.list.map(function (item) {
            return (
              <div key={item.I}>
                <div className="allouter_agentdet_sec">
                  <div className="left_agentdetsec">
                    <div className="main_agentpic_sec">
                      <Image src={item.Headimage} roundedCircle></Image>
                    </div>
                  </div>

                  <div className="right_agentdetsec">
                    <div className="main_agentdetsec">
                      <h2>{item.name}</h2>
                      <h3>{item.Title}</h3>
                      <p>
                        <strong>{item.Company}</strong>
                      </p>
                    </div>

                    <div className="agent_description_mainsec">
                      <p>
                        <span id={item.brifSec}>{item.brief}</span>
                        <button
                          id={item.rdbtnId}
                          className="readmore_btn"
                          onClick={() => {
                            this.Opendescription(item.I);
                          }}
                        >
                          {this.state.lang.readmore}
                          <i className="fa fa-arrow-down"></i>
                        </button>
                        <span id={item.fullSec} className="agent_fulldesc_sec">
                          {item.intro}
                          <button
                            id={item.clbtnId}
                            onClick={() => {
                              this.Closedescription(item.I);
                            }}
                          >
                            {this.state.lang.readless}
                            <i className="fa fa-arrow-up"></i>{" "}
                          </button>
                        </span>
                      </p>
                    </div>

                    <div className="agentfull_detsec">
                      <ul>
                        <li>
                          <a href={item.message}>
                            <i className="fa fa-comments"></i>
                            <p>{this.state.lang.share_message}</p>
                          </a>
                        </li>
                        <li>
                          <a href={item.phone}>
                            <i className="fa fa-phone-alt"></i>
                            <p>{this.state.lang.share_phone}</p>
                          </a>
                        </li>
                        <li>
                          <a href={item.email}>
                            <i className="fa fa-envelope"></i>
                            <p>{this.state.lang.share_email}</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            );
          }, this)}
        </div>

        <br />
        <br />
        <div>
          <div>
            <div id="agent_list">
              <ul></ul>
            </div>
          </div>
        </div>

        <div id="footer">
          <br />
          <br />
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadList() {
    try {
      var url = document.location.toString();
      let paras = url.split("?").splice(1, 1)[0].split("#")[0].split("=");
      if (paras.length < 1) {
        return;
      }
      let tk = paras[0];
      this.setState({ tk: tk });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'tk':'" + tk + "'}\"";
      axios.post("/user/agents", param).then((res) => {
        var arr = res.data;
        for (var i = 0; i < res.data.length; i++) {
          arr[i]["I"] = i + 1;
        }
        this.setState({ list: this.fillList(arr) });
      });
    } catch {}
  }

  fillList = (data) => {
    data.forEach((item) => {
      item["rdbtnId"] = "readmore_btn_" + item.I;
      item["clbtnId"] = "closedis_btn_" + item.I;
      item["fullSec"] = "full_secion_" + item.I;
      item["brifSec"] = "brif_secion_" + item.I;

      item["image"] = "";
      item["name"] = item.Firstname + " " + item.Lastname;
      item["phone"] = "tel:" + item.Phone;
      item["message"] = "sms:" + item.Phone;
      item["email"] = "mailto:" + item.Email;

      if (item.Introduction !== null) {
        if (item.Introduction.length > 50) {
          item["brief"] = item.Introduction.substring(0, 49);
          item["intro"] = item.Introduction;
        } else {
          item["brief"] = item.Introduction;
          item["intro"] = item.Introduction;
        }
      }
    });
    return data;
  };

  Opendescription(pos) {
    document.getElementById("brif_secion_" + pos).style.display = "none";
    document.getElementById("full_secion_" + pos).style.display = "block";
    document.getElementById("readmore_btn_" + pos).style.display = "none";
  }

  Closedescription(pos) {
    document.getElementById("brif_secion_" + pos).style.display = "block";
    document.getElementById("full_secion_" + pos).style.display = "none";
    document.getElementById("readmore_btn_" + pos).style.display =
      "inline-block";
  }

  BackClick() {
    window.history.go(-1);
  }
}
