import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/About.css";

export class TermOfUse extends Component {
  render() {
    return (
      <div>
        <p>
          <span>
            <strong>
              <span>Term of Use</span>
            </strong>
          </span>
        </p>
        <p>
          <strong>
            <span>Term of Serivce Agreement</span>
          </strong>
        </p>
        <p>
          <span>
            By using this web site, you are agreeing to comply and be bound by
            the following terms of service and use. Please review the following
            terms in their entirety and ensure their comprehension before using
            and viewing this web site.
          </span>
          <br />
          <span>
            The terms “us”, “we”, “our”, or “owners” refers to “Yiju” and the
            administrative operators of this web site, yiju.ca. “You” refers to
            the user or viewer of this web site.
          </span>
        </p>
        <p>
          <span>
            That this “Terms of Use“ does not impose a financial obligation on
            you or create any representation agreement between you and Yiju.
          </span>
        </p>
        <p>
          <span>
            Yiju is an Affiliated VOW Partner registered with Toronto Real
            Estate Board. All MLS® Listing data is intended only for your
            personal, non-commercial use. Users must have a bona fide interest
            in the purchase, sale, or lease of real estate of the type being
            offered through this VOW. All registrants agree that you will not
            copy, redistribute, retransmit, or otherwise use any of the data or
            Listing Information provided, except in connection with your
            consideration of the purchase, sale, or lease of an individual
            property.
          </span>
        </p>
        <p>
          <strong>
            <span>Toronto Real Estate Board’s Disclaimers</span>
          </strong>
        </p>
        <p>
          <span>
            Toronto Real Estate Board’s (“TREB“) ownership of and the validity
            of TREB’s proprietary rights and copyright in the MLS® database,
            MLS® data, TREB’s MLS® System, and Listing Information
          </span>
        </p>
        <p>
          <span>
            You will not, directly or indirectly, display, post, disseminate,
            distribute, publish, broadcast, transfer, sell or sublicense any
            Listing Information to another individual or entity. The prohibited
            uses expressly include “scraping“ (including “screen scraping“ and
            “database scraping“), “data mining“ or any other activity intended
            to collect, store, re-organize, summarize or manipulate any Listing
            Information or any related data
          </span>
        </p>
        <p>
          <span>
            The Terms of Use agreement also expressly authorizes TREB, and other
            TREB Members or their duly authorized representatives, to access the
            VOW for the purposes of verifying compliance with MLS® Rules and
            Policies (including the VOW Rules) and monitoring the display of
            Members’ Listings by the VOW
          </span>
        </p>
        <p>
          <span>
            Consent to the fact that your Personal Information, including first
            and last name, email address, and phone number may be collected,
            used or disclosed. The personal information may be shared with TREB
            for auditing and/or legal purposes.
          </span>
        </p>
        <p>
          <span>
            The information here is deemed reliable but not guaranteed by TREB
          </span>
        </p>
        <p>
          <strong>
            <span>The Canadian Real Estate Association’s Disclaimers</span>
          </strong>
        </p>
        <p>
          <span>
            CREA is the owner of the REALTOR® and MLS® trademarks: “REALTOR®,
            REALTORS®, and the REALTOR® logo are certification marks that are
            owned by REALTOR® Canada Inc. and licensed exclusively to The
            Canadian Real Estate Association (CREA). These certification marks
            identify real estate professionals who are members of CREA and who
            must abide by CREA’s By-Laws, Rules, and the REALTOR® Code. The MLS®
            trademark and the MLS® logo are owned by CREA and identify the
            quality of services provided by real estate professionals who are
            members of CREA.
          </span>
        </p>
        <p>
          <span>
            The information contained on this site is based in whole or in part
            on information that is provided by members of The Canadian Real
            Estate Association, who are responsible for its accuracy. CREA
            reproduces and distributes this information as a service for its
            members and assumes no responsibility for its accuracy
          </span>
        </p>
        <p>
          <strong>
            <span>Copyright</span>
          </strong>
        </p>
        <p>
          <span>
            The listing content on this website is protected by copyright and
            other laws, and is intended solely for the private, non-commercial
            use by individuals. Any other reproduction, distribution or use of
            the content, in whole or in part, is specifically forbidden. The
            prohibited uses include commercial use, “screen scraping”, “database
            scraping”, and any other activity intended to collect, store,
            reorganize or manipulate data on the pages produced by or displayed
            on this website.
          </span>
        </p>
        <p>
          <span>
            The content in its entirety, including text content, graphics,
            layouts, and all source code, belong to the owners of the web site.
            This term is protected by intellectual property rights and copyright
            law. Copying, redistribution, use or publication either for free or
            for monetary gain is strictly prohibited. Some of the content on the
            site is the copyrighted and licensed work of third parties.
          </span>
        </p>
        <p>
          <strong>
            <span>Terms of Use</span>
          </strong>
        </p>
        <p>
          <span>
            1. The content of this web site in its entirety is subject to change
            without notice. Its purpose is for your general information only.
          </span>
        </p>
        <p>
          <span>
            2. No parties guarantee the accuracy, timeliness, performance,
            completeness, or suitability of the content and information found on
            this site. You acknowledge that errors or inaccuracies may exist,
            and that the owners of the site are in no way liable for any such
            errors.
          </span>
        </p>
        <p>
          <span>
            3. The owners of the site are not responsible and hold no liability
            for third-party content that may be posted on the site by end users,
            or for content that is linked to from this web site including other
            web sites.
          </span>
        </p>
        <p>
          <span>
            4. The use of and viewing of information on this site is at your own
            risk. Any consequences of use that may occur are not the liability
            of the web site owners.
          </span>
        </p>
        <p>
          <span>
            5. Unauthorized use of this web site or its contents may give rise
            to a claim for damages and/or be a criminal offense enforceable by
            local and international law.
          </span>
        </p>
        <p>
          <span>
            6. We reserve the right to restrict access to certain areas of the
            web site at our own discretion. If a username and password, or any
            other access credentials, are provided to you as the end user, it is
            your responsibility to keep such information confidential.
          </span>
        </p>
        <p>
          <span>
            7. The Registrant has received, read and understand the brochure
            published by the British Columbia Real Estate Association entitled
            “Working With a REALTOR®” (this document may be linked)
          </span>
        </p>
        <p>
          <span>
            8. This Terms of Use do not create an agency relationship with the
            Registrant
          </span>
        </p>
        <p>
          <span>
            9. All data obtained from the MLS® VOW is intended only for the
            Registrant’s personal, non-commercial use
          </span>
          <br />
          <span>
            that the Registrant has a bona fide interest in the purchase, sale
            or lease of real estate of the type being offered through the
            MLS®VOW
          </span>
        </p>
        <p>
          <span>
            10. The Registrant will not copy, redistribute or retransmit any of
            the MLS® VOW Data or information provided
          </span>
        </p>
        <p>
          <span>
            11. The Registrant acknowledges the Board’s copyright in the MLS®
            VOW Data
          </span>
        </p>

        <div id="footer"></div>
      </div>
    );
  }
}
