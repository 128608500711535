import React, { Component } from "react";
import axios from "axios";
import { datahost } from "../config/Const.js";
import { replaceAllFixed } from "../function/Basic.js";

export class AppVirtualTour extends Component {
  static displayName = AppVirtualTour.name;

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div className="AppVirtualTour">
        <br />
        <div id="logout"></div>
      </div>
    );
  }

  async loadData() {
    try {
      let url = document.location.toString();
      let paras = url.split("?").splice(1, 1)[0].split("&");
      let tk = paras[0].split("=")[1];
      let ngw = paras[1].split("=")[1]; // save token and 'ngw' params
      this.setState({ tk: tk, ngw: ngw });
      let tmpngw = replaceAllFixed(ngw, "~", "=");
      let decode = window.atob(tmpngw).split(","); // load detail information
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'tk':'" + tk + "','id':'" + decode[1] + "'}\"";
      axios.post("/detail/virtualtour", param).then((res) => {
        this.JumpToVirtualTourUrl(res.data);
      });
    } catch {}
  }

  JumpToVirtualTourUrl(data) {
    if (data.indexOf("http") >= 0) {
      window.location.href = data;
    } else {
      document.getElementById("logout").innerHTML = "404 Not found";
    }
    // // if (data !== null && !data.toString().includes("Invalid")) {
    //   let target = ReadValue(data["tour_url"]);
    //   if (target.size > 0) {
    //     window.location.href = target;
    //   // }
    // } else {
    //   document.getElementById("logout").innerHTML = "404 Not found"
    // }
  }
}
