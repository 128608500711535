import React from "react";
import { Button } from "react-bootstrap";
import axios from "axios";

import {
  datahost,
  def_site,
  gesture,
  pic_host,
  pic_link,
} from "../../config/Const.js";
import { priceSwitch, replaceAllFixed } from "../../function/Basic.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

export class L_Listings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      lg: null,
      shstr: null,
      curpg: null,
      pgsize: null,
      listings: [],
      rchend: false,
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadList();
  }

  render() {
    return (
      <div className="wrapper_margin">
        <Button
          id="pro-btn-mf"
          className="new_pagelink_btn"
          onClick={() => this.BackClick()}
        >
          <i className="fa fa-angle-left"></i>
          {this.state.lang.back}
        </Button>
        <div>
          <div>
            <div id="listing_list">
              <ul></ul>
            </div>
          </div>
        </div>
        <div id="loadmore">
          <Button
            id="pro-btn-mf"
            style={{ width: "100%" }}
            onClick={() => this.LoadMoreClick()}
          >
            {this.state.lang.loadmore}
          </Button>
        </div>

        <div id="footer"></div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadList() {
    try {
      var url = document.location.toString();
      let paras = url.split("?")[1].split("=");
      let tk = paras[0];
      let shstr = window.atob(paras[1]);
      let params = shstr.split(";");
      if (params.length !== 7) {
        return;
      }
      var lg = 0;
      if (paras.length === 3) {
        lg = paras[2];
      }
      this.setState({
        tk: tk,
        lg: lg,
        shstr: shstr,
        pgsize: params[1],
        curpg: params[2],
      });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";

      // token level
      // let param_tklvl = "\"{'tk':'" + tk + "'}\"";
      // axios.post("/token/level", param_tklvl).then((res) => {
      //   if (res.data > 2) {
      //     this.setState({ lg: true });
      //   } else {
      //     this.setState({ lg: false });
      //   }

      // search listing
      let mtstr = "";
      for (var i = 0; i < params.length; i++) {
        mtstr += params[i] + ";";
      }
      mtstr = mtstr.substring(0, mtstr.length - 1);
      let param =
        "\"{'mt':'" + mtstr + "','tk':'" + tk + "','st':'" + def_site + "'}\"";
      axios.post("/search/list", param).then((res) => {
        this.setState({ listings: this.fillList(res.data) });
        this.createListingList(this.state.listings);
      });
      // });
    } catch {}
  }

  fillList = (data) => {
    // let str_0 = hostname + "/appdet?tk=";
    // let str_1 = "&ngw=";
    let str_0 = gesture + document.location.host + "/listing?";
    let str_1 = "=";
    let str_2 = "&ngw=";
    data.forEach((element) => {
      // fill detail link
      let ngw_0 = "940682259,";
      let ngw_1 = ",";
      let dt = "2021-01-08 12:25:08";
      let ngw_2 = ",";
      let ngw_str = ngw_0 + element.I + ngw_1 + dt + ngw_2 + element.O;
      var ngw = window.btoa(ngw_str);
      ngw = ngw.replace("=", "~");
      element["link"] =
        str_0 + this.state.tk + str_1 + ngw + str_1 + this.state.lg;
      // fill picture link
      element["pic"] =
        gesture + pic_host + pic_link + this.state.tk + str_2 + ngw;
      // fix beds, baths, parking.
      if (element["c1"] !== null) {
        element["b1"] += "+" + element["c1"];
      }
      // fix address
      element["c"] = replaceAllFixed(element["c"], ",", " ");
      element["c"] = replaceAllFixed(element["c"], "|", ",");
      // fix price showing
      element["a"] = priceSwitch(element["a"]);
      // fix visible tag
      if (this.state.lg > 2) {
        element.V = false;
      } else {
        if (element.V === false) {
          element.V = false;
        } else {
          element.V = true;
          element["lgrq1"] = this.state.lang.loginrequire1;
          element["lgrq0"] = this.state.lang.loginrequire0;
        }
      }
      // add Language Tag
      if (element["h"] === "A") {
        if (element["e"] === "Sale") {
          element["e"] = this.state.lang.card_listing_sale;
        } else {
          element["e"] = this.state.lang.card_listing_lease;
        }
      } else {
        if (element["h"] === "Sale") {
          element["e"] = this.state.lang.card_listing_sold;
        } else {
          element["e"] = this.state.lang.card_listing_leased;
        }
      }
    });
    return data;
  };

  createListingList(arr) {
    var st = document.getElementById("listing_list");
    if (arr !== null && arr !== []) {
      for (var i = 0; i < arr.length; i++) {
        st.children[0].innerHTML += this.buildListingElement(arr[i]);
      }
    }
  }

  buildListingElement(ele) {
    var result = "";
    result += "<div key=";
    result += ele.I;
    result += "><div ";
    if (ele.V === true) {
      result += "hidden";
    }
    result += "><a href=";
    result += ele.link;
    result +=
      '><div class="inner_listingsec"><div class="listing_imgsec"><Image src=';
    result += ele.pic;
    result += ' alt="" /></div><div class="listing_detsec"><h2>$ ';
    result += ele.a;
    result += ' </h2><span><i class="fa fa-map-marker-alt"></i>';
    result += ele.c;
    result +=
      '</span><ul><li><i class="fa fa-bed"></i><p>' +
      this.state.lang.dtl_share_beds;
    if (ele.b1 === null) result += "N/A";
    else result += ele.b1;
    result +=
      '</p></li><li><i class="fa fa-bath"></i><p>' +
      this.state.lang.dtl_share_baths;
    if (ele.a1 === null) result += "N/A";
    else result += ele.a1;
    result +=
      '</p></li><li><i class="fa fa-car"></i><p>' +
      this.state.lang.dtl_share_parkings;
    if (ele.i2 === null) result += "N/A";
    else result += ele.i2;
    result += '</p></li></ul><div class="property_status"><button>';
    result += ele.e;
    result += "</button></div></div></div></a></div></div>";
    return result;
  }

  LoadMoreClick() {
    if (this.state.rchend == false) {
      try {
        let crpg = parseInt(this.state.curpg, 10) + 1;
        this.setState({ curpg: crpg });
        let params = this.state.shstr.split(";");
        if (params.length !== 7) {
          return;
        }
        params[2] = crpg;
        axios.defaults.baseURL = datahost;
        axios.defaults.headers.post["Content-Type"] = "application/json";
        let mtstr = "";
        for (var i = 0; i < params.length; i++) {
          mtstr += params[i] + ";";
        }
        mtstr = mtstr.substring(0, mtstr.length - 1);
        let param =
          "\"{'mt':'" +
          mtstr +
          "','tk':'" +
          this.state.tk +
          "','st':'" +
          def_site +
          "'}\"";
        axios.post("/search/list", param).then((res) => {
          this.setState({ listings: this.fillList(res.data) });
          this.createListingList(this.state.listings);
        });
      } catch {}
    }
  }

  BackClick() {
    window.history.go(-1);
  }
}
