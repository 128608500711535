import React, { Component } from "react";
import { Image } from "react-bootstrap";
import axios from "axios";
import wx from "weixin-js-sdk";
import {
  SetupBothDistribution,
  SetChartListing,
  SetChartPrice,
  SetChartMarket,
  SetChartPartition,
} from "../../function/Chart.js";
import { datahost, shkyhost } from "../../config/Const.js";
import { createContact } from "../../function/Share.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/AppMarket.css";

export class S_Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      sh: null,
      repo: null,
      ls: null,
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadPage();
  }

  findKeyValue(obj, key, value) {
    for (var i = 0; i < obj.length; i++) {
      if (obj[i][key] === value) {
        return obj[i];
      }
    }
  }

  render() {
    return (
      <div className="wrapper">
        <div className="wrapper_margin">
          <div className="shared_title" id="nTitle"></div>
          <div className="shared_content" id="nContent"></div>
          <br />
          <div
            id="chart_monthly"
            className="ChartMonthly"
            style={{
              marginLeft: "0.5rem",
              width: document.body.clientWidth - 16,
            }}
          >
            <div
              id="chart_listing"
              style={{ height: document.body.clientWidth }}
            ></div>
            <div
              id="chart_price"
              style={{ height: document.body.clientWidth }}
            ></div>
            <div
              id="chart_market"
              style={{ height: document.body.clientWidth }}
            ></div>
            <div
              id="chart_partition"
              style={{ width: "100%", height: document.body.clientWidth }}
            ></div>
            <div id="pie_range">
              <br />
            </div>
            {/* <div
            id="chart_soldprice"
            style={{
              width: "100%",
              height: (document.body.clientWidth * 2) / 3,
            }}
          ></div>
          <div
            id="chart_asksprice"
            style={{
              width: "100%",
              height: (document.body.clientWidth * 2) / 3,
            }}
          ></div> */}
          </div>

          <div className="agent_detsec section_margin" id="shared_contact">
            <div className="mainagent_leftsec">
              <div className="outer_agentdet_sec">
                <div className="main_agentpic_sec">
                  <Image id="contact_hd" />
                </div>
                <div className="main_agentdetsec">
                  <h2 id="contact_name"></h2>
                  <h3 id="contact_title"></h3>
                  <p id="contact_company"></p>
                </div>
              </div>
              <div className="agent_description_mainsec">
                <h5>{this.state.lang.share_introduction}</h5>
                <p id="contact_introduction"></p>
              </div>
              <div className="agentfull_detsec">
                <ul>
                  <li>
                    <a id="contact_message">
                      <i className="fa fa-comments"></i>
                      <p>{this.state.lang.share_message}</p>
                    </a>
                  </li>
                  <li>
                    <a id="contact_phone">
                      <i className="fa fa-phone-alt"></i>
                      <p>{this.state.lang.share_phone}</p>
                    </a>
                  </li>
                  <li>
                    <a id="contact_email">
                      <i className="fa fa-envelope"></i>
                      <p>{this.state.lang.share_email}</p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mainqrcode_sec">
                <h2>[ {this.state.lang.share_scanqr} ]</h2>
                <img id="contact_qr" width="30%" thumbnail="true" />
              </div>
            </div>
          </div>

          <div className="bottom_sliding_sec">
            <div className="yiju_logosec pull-left">
              <img src={require("../../../images/yiju_logo.png")} alt="" />
              <p>
                {this.state.lang.yiju}
                {/* <i className="fa fa-angle-double-right"></i> */}
              </p>
            </div>
            <div className="detailapp_btn pull-right">
              <button
                type="button"
                className="common_mainbtn btn btn-primary"
                onClick={() => {
                  this.JumpToGuide();
                }}
              >
                {this.state.lang.dtl_detailsinapp}
              </button>
            </div>
          </div>

          <div hidden={true}>
            <p>
              <i id="wx_title"></i>
              <i id="wx_desc"></i>
              <i id="wx_imgurl"></i>
              <i id="wx_link"></i>
            </p>
          </div>

          <div id="footer">
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }

  async loadPage() {
    try {
      var url = document.location.toString();
      document.getElementById("wx_link").innerText = url;
      // be compatible with Facebook
      if (url.indexOf("&")) {
        url = url.split("&")[0];
      }
      // be compatible with WeChat
      if (url.indexOf("=")) {
        url = url.split("=")[0];
      }
      let sh = url.split("?").splice(1, 1)[0];
      this.setState({ sh: sh });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'sh':'" + sh + "'}\"";
      axios.post("/share/customereport", param).then((res) => {
        this.setState({ repo: res.data, ls: "S" });
        if (res.data.data.isSold === false) {
          this.setState({ ls: "L" });
        }
        // Set contact user
        if (this.state.repo.usr !== undefined) {
          createContact(this.state.repo.usr);
          document.getElementById("shared_contact").hidden = false;
        } else {
          document.getElementById("shared_contact").hidden = true;
        }

        // Set inner HTML
        let ntitle = document.getElementById("nTitle");
        ntitle.innerHTML = this.state.repo.data.nTitle;
        let nbrief = document.getElementById("nContent");
        nbrief.innerHTML = this.state.repo.data.nBrief;
        // create charts
        this.CreateLineCharts();
        this.CreatePieCharts();

        // Set Wechat Sharing Values
        document.getElementById("wx_title").innerText =
          this.state.repo.data.nTitle;
        document.getElementById("wx_desc").innerText =
          this.state.repo.data.nBrief;
        document.getElementById("wx_imgurl").innerText =
          "https://api.yjezimoc.com/shared/default.png";

        // WxShare Configuration
        axios.defaults.baseURL = shkyhost;
        axios
          .get(
            "/wx/wxconfig?url=" +
              encodeURI(document.getElementById("wx_link").innerText)
          )
          .then((resWC) => {
            wx.config({
              appId: resWC.data.appid,
              timestamp: resWC.data.timestamp,
              nonceStr: resWC.data.nonceStr,
              signature: resWC.data.signature,
              jsApiList: [
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ],
            });

            wx.ready(() => {
              wx.updateAppMessageShareData({
                title: document.getElementById("wx_title").innerText,
                desc: document.getElementById("wx_desc").innerText,
                link: document.getElementById("wx_link").innerText,
                imgUrl: document.getElementById("wx_imgurl").innerText,
                success: () => {},
              });

              wx.updateTimelineShareData({
                title: document.getElementById("wx_title").innerText,
                link: document.getElementById("wx_link").innerText,
                imgUrl: document.getElementById("wx_imgurl").innerText,
                success: () => {},
              });
            });
          });
      });
    } catch {}
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  GetListValue(listStr) {
    return listStr.split(",");
  }

  CreateLineCharts() {
    // generate date range
    var month = [];
    var dt = new Date(this.state.repo.data.reportDate);
    for (var i = 11; i >= 0; i--) {
      var dys = 14 * i;
      var tr = new Date();
      tr.setDate(dt.getDate() - dys);
      month.push(
        tr.getFullYear() + "-" + (tr.getMonth() + 1) + "-" + tr.getDate()
      );
    }
    // var month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    var soldL = this.GetListValue(this.state.repo.data.sold);
    var newL = this.GetListValue(this.state.repo.data.new);
    var activeL = this.GetListValue(this.state.repo.data.active);
    var soldDOM = this.GetListValue(this.state.repo.data.soldAverageDom);
    var soldMP = this.GetListValue(this.state.repo.data.soldMedianPrice);
    var soldAP = this.GetListValue(this.state.repo.data.soldAveragePrice);
    var newMP = this.GetListValue(this.state.repo.data.newMedianPrice);
    var newAP = this.GetListValue(this.state.repo.data.newAveragePrice);
    var soldMax = this.GetListValue(this.state.repo.data.soldMaxPrice);
    var soldMin = this.GetListValue(this.state.repo.data.soldMinPrice);
    var newMax = this.GetListValue(this.state.repo.data.newMaxPrice);
    var newMin = this.GetListValue(this.state.repo.data.newMinPrice);
    var absorb = [];
    for (var i = 0; i < month.length; i++) {
      if (newL[i] === null || newL[i] === 0) {
        absorb.push(52);
      } else {
        absorb.push(((soldL[i] / newL[i]) * 100).toString().substring(0, 5));
      }
      // }
    }

    // Color and XAxis(Year)
    // var colors = ['#d14a61', '#5793f3', '#f8f2b8', '#54b936', '#EB68CC', '#E8BBF2', '#FEFF82', '#DF61AA', '#FA7292', '#9AE9BC', '#675bba', '#36A1D9', '#32C5E8', '#f8f2b8', '#bc7768', '#36A1D9', '#32C5E8', '#65E1E3', '#FF6567', '#A093F2', '#8675E7', '#9BBCFF'];
    var years = month;

    // Listing LINE&BAR
    var listingTitle = [
      this.state.lang["chart1_0"],
      years[0] + "~" + years[years.length - 1],
    ];
    var chart1_1 = this.state.lang["chart1_1"];
    if (this.state.ls === "L") {
      chart1_1 = this.state.lang["chart1_1l"];
    }
    var listingLegendSeries = [
      chart1_1,
      this.state.lang["chart1_2"],
      this.state.lang["chart1_3"],
    ];
    var listingYAxis = [
      this.state.lang["chart1_4"],
      this.state.lang["chart1_5"],
    ];
    var listingData = [soldL, newL, soldDOM];
    var listingColor = ["#FFB26B", "#b06a13", "#fcc41b"];
    SetChartListing(
      listingTitle,
      listingLegendSeries,
      years,
      listingYAxis,
      listingData,
      listingColor,
      "chart_listing"
    );

    // Price LINE
    var priceTitle = [
      this.state.lang["chart2_0"],
      years[0] + "~" + years[years.length - 1],
    ];
    var chart2_1 = this.state.lang["chart2_1"];
    if (this.state.ls === "L") {
      chart2_1 = this.state.lang["chart2_1l"];
    }
    var chart2_2 = this.state.lang["chart2_2"];
    if (this.state.ls === "L") {
      chart2_2 = this.state.lang["chart2_2l"];
    }
    var priceLegendSeries = [
      chart2_1,
      chart2_2,
      this.state.lang["chart2_3"],
      this.state.lang["chart2_4"],
    ];
    var priceData = [soldMP, soldAP, newMP, newAP];
    var priceColor = ["#FFB26B", "#b06a13", "#fcc41b", "#fc601b"];
    SetChartPrice(
      priceTitle,
      priceLegendSeries,
      years,
      priceData,
      priceColor,
      "chart_price"
    );

    // Market LINE
    var chart3_0 = this.state.lang["chart3_0"];
    if (this.state.ls === "L") {
      chart3_0 = this.state.lang["chart3_0l"];
    }
    var marketTitle = [chart3_0, years[0] + "~" + years[years.length - 1]];
    var marketData = absorb;
    var marketColor = ["#FFB26B", "#b06a13", "#fcc41b"];
    SetChartMarket(marketTitle, years, marketData, marketColor, "chart_market");
  }

  CreatePieCharts() {
    var pieFillData = this.GetListValue(this.state.repo.data.soldType);

    // Partition PIE
    var pieColors = [
      "#FFB26B",
      "#b06a13",
      "#fcc41b",
      "#fc601b",
      "#b04313",
      "#ffe6c6",
    ];
    var chart4_0 = this.state.lang["chart4_0"];
    if (this.state.ls === "L") {
      chart4_0 = this.state.lang["chart4_0l"];
    }
    var pieTitle = [chart4_0, ""];
    var pieLegend = [
      this.state.lang["detached"],
      this.state.lang["semi"],
      this.state.lang["townhouse"],
      this.state.lang["condo"],
      this.state.lang["others"],
    ];
    var pieData = [
      { name: pieLegend[0], value: pieFillData[0] },
      { name: pieLegend[1], value: pieFillData[1] },
      { name: pieLegend[2], value: pieFillData[2] },
      { name: pieLegend[3], value: pieFillData[3] },
      { name: pieLegend[4], value: pieFillData[4] },
    ];
    SetChartPartition(
      pieTitle,
      pieLegend,
      pieData,
      pieColors,
      "chart_partition"
    );
  }

  // PartByDistribution() {
  //   var rg = this.state.ed.substring(0, this.state.ed.length - 3);
  //   var chart5_0 = this.state.lang["chart5_0"];
  //   if (this.state.ls === "L") {
  //     chart5_0 = this.state.lang["chart5_0l"];
  //   }
  //   var titles = [chart5_0, rg, this.state.lang["chart6_0"], rg];
  //   var distriColors = ["#d14a61", "#f8f2b8"];
  //   SetupBothDistribution(
  //     titles,
  //     this.state.distrib,
  //     distriColors,
  //     "chart_soldprice",
  //     "chart_asksprice"
  //   );
  // }

  JumpToGuide() {
    let path = "/yiju/gd";
    this.props.history.push(path);
  }
}
