import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { datahost, def_site } from "../../config/Const";
import {
  dateConvert,
  moneyConvert,
  replaceAllFixed,
} from "../../function/Basic";
import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

const P_History = ({ history }) => {
  const [burger, setBurger] = useState(history);
  const [more, setMore] = useState(false);
  const [active, setActive] = useState(false);
  const [lang, setLang] = useState(enj);

  const [tk, setTK] = useState();
  const [decode, setDecode] = useState();
  let redirect = useHistory();

  const pageInit = () => {
    let url = document.location.toString();
    if (url[url.length - 1] === "=") {
      // remove the last '=' character
      url = url.slice(0, -1);
    }
    let paras = url.split("?").splice(1, 1)[0].split("=");
    setTK(paras[0]);
    // let tmpngw = replaceAllFixed(paras[1], "~", "=");
    // setDecode(window.atob(tmpngw).split(","));
  };

  useEffect(() => {
    initLanguage();
    pageInit();
    setBurger(history);
    if (history.length > 3) {
      let tmp = [...history];
      let tmp_last = [...history];
      tmp.splice(3);
      setBurger(tmp);
      tmp_last.splice(0, 3);
      setMore(tmp_last);
    }
  }, [history]);

  const initLanguage = () => {
    try {
      let language = (
        navigator.browserLanguage || navigator.language
      ).toLowerCase();
      if (language.indexOf("zh") > -1) {
        setLang(zhj);
      }
    } catch (e) {
      setLang(zhj);
    }
  };

  const redirectURL = (ms) => {
    axios.defaults.baseURL = datahost;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    let paraASL = "\"{'tk':'" + tk + "','mls':'" + ms + "'}\"";
    axios.post("/detail/mlsngw", paraASL).then((asl) => {
      if (asl.data != "") {
        window.location.replace(`/listing?${tk}=${asl.data}=3`);
      }
    });
  };

  const renderTimeLine = (arr) => {
    return arr.map((data, index) => {
      return (
        <div className="timeLine" key={index}>
          <p className="timeLine_title" onClick={() => redirectURL(data.MlsNo)}>
            {data.MlsNo}
          </p>
          <div className="dot"></div>
          {data.LastStatus ? (
            data.Logs.length == 0 && data.LastStatus == "New" ? null : (
              <div className="timeLine_body">
                <div className="timeLine_time">
                  {dateConvert(data.LastUpdate)}
                </div>
                <div
                  className="timeLine_action"
                  style={{
                    color:
                      data.LastStatus == "Lsd" || data.LastStatus == "Sld"
                        ? "#f54336"
                        : "var(--font-color)",
                  }}
                >
                  {lang["dtl_property_status_" + data.LastStatus.toLowerCase()]}{" "}
                  {data.LastStatus == "Lsd" || data.LastStatus == "Sld"
                    ? moneyConvert(data.SoldPrice)
                    : moneyConvert(data.Price)}
                </div>
              </div>
            )
          ) : (
            ""
          )}

          {data.Logs.map((l) => {
            if (l.KeyName != "Status") {
              return (
                <div key={l.Id} className="timeLine_body">
                  <div className="timeLine_time">{dateConvert(l.OptDate)}</div>
                  <div className="timeLine_action">
                    {l.KeyName}{" "}
                    {l.KeyName == "Price"
                      ? moneyConvert(l.KeyValue0)
                      : l.KeyValue0}{" "}
                    -{" "}
                    {l.KeyName == "Price"
                      ? moneyConvert(l.KeyValue1)
                      : l.KeyValue1}
                  </div>
                </div>
              );
            }
          })}
          <div className="timeLine_body">
            <div className="timeLine_time">{dateConvert(data.ListedSince)}</div>
            <div
              className="timeLine_action"
              style={{
                color: "#2E8B57",
              }}
            >
              {lang["p_history_list"]} {moneyConvert(data.Price)}
            </div>
          </div>
          <div className="dot"></div>
        </div>
      );
    });
  };

  return (
    <div>
      <React.Fragment>
        <div className="marginTopandBottom">
          {history.length > 0 ? renderTimeLine(burger) : ""}
          {more != false && active ? renderTimeLine(more) : ""}
        </div>
        {more != false ? (
          <p className="more_btn" onClick={() => setActive(!active)}>
            {active ? (
              <i className="fa fa-chevron-up" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            )}{" "}
            {active ? lang.readless : lang.readmore}
          </p>
        ) : (
          ""
        )}
      </React.Fragment>
    </div>
  );
};

export default P_History;
