import React, { Component } from "react";
import { Button, Image, Tabs, Tab } from "react-bootstrap";
import Slider from "react-slick";
import axios from "axios";
import { Base64 } from "js-base64";

import {
  gesture,
  def_site,
  findschoolhost,
  datahost,
  pic_host,
  pic_link,
  shkyhost,
  VOW,
} from "../../config/Const.js";
import { passParams } from "../../config/Connector.js";
import {
  dateConvert,
  domCalculate,
  priceSwitch,
  replaceAllFixed,
} from "../../function/Basic.js";
import {
  SetupVowPage,
  SetupDdfPage,
  // createLog,
  createHistory,
  createCommunityChart,
  createCensusData,
  createWalkScore,
  createSchoolReport,
  createMortgage,
  fixFrameHeight,
  addAccordionClick,
  MeterFeetClick,
} from "../../function/Listing.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/Listing.css";
import { Snippet } from "../../function/Snippet.js";
import P_History from "../timeline/P_History.js";
import FloatAgent from "../popup/FloatAgent.js";
import { Translator } from "../../function/Translator.js";
import LoadingSkeleton from "../more/LoadingSkeleton.js";

export class D_AppDet extends Component {
  static displayName = D_AppDet.name;

  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      lg: null,
      dtl: null,
      asl: null,
      walkscore_loaded: false,
      mortgage_loaded: false,
      school_loaded: false,
      community_loaded: false,
      census_loaded: false,
      expandAgentCard: true,
      soldList: [],
      leasedList: [],
      featuredList: [],
      agentList: [],
      priceHistory: [],
      demographicOpen: false,
      des_en: "",
      des_ch: "",
      des_loading: false,
    };
    this.defaultCensusDisplay = this.defaultCensusDisplay.bind(this);
    this.descriptionLang = this.descriptionLang.bind(this);
  }

  componentDidMount() {
    this.initLanguage();
    this.loadDetail();
    addAccordionClick();
  }

  async descriptionLang(currentLang) {
    let targetTxtArea = document.getElementById("pro-remarks");
    if (this.state.des_en == "") {
      this.state.des_en = targetTxtArea.innerHTML;
    }
    const des_lang_en = document.getElementById("des_lang_en");
    const des_lang_ch = document.getElementById("des_lang_ch");
    if (currentLang == "ch") {
      if (this.state.des_ch == "") {
        targetTxtArea.innerHTML = "";
        this.setState({
          des_loading: true,
        });
        this.state.des_ch = await Translator(this.state.des_en);
        this.setState({
          des_loading: false,
        });
      }
      targetTxtArea.innerHTML = this.state.des_ch;
      des_lang_en.classList.remove("active");
      des_lang_ch.classList.add("active");
    } else {
      targetTxtArea.innerHTML = this.state.des_en;
      des_lang_ch.classList.remove("active");
      des_lang_en.classList.add("active");
    }
  }

  defaultCensusDisplay() {
    if (!this.state.demographicOpen) {
      const ifr = document.getElementById("iframe_census");
      try {
        ifr.contentWindow.document
          .getElementsByClassName("hori_items")[0]
          .click();
      } catch (e) {
        return false;
      }
      this.state.demographicOpen = true;
    }
  }

  render() {
    let setting0 = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let setting1 = {
      dots: false,
      infinite: true,
      speed: 300,
      // lazyLoad: true,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
    };

    return (
      <div className="AppDet">
        {this.state.agentList ? (
          <FloatAgent agentList={this.state.agentList} />
        ) : (
          ""
        )}
        <div className="wrapper">
          <div className="wrapper_margin">
            {/* <div className="property_locationsec" hidden={true}>
              <p>
                <i className="fa fa-map-marker-alt"></i>
                <i id="address"></i>
              </p>
            </div> */}
            {/* property_locationsec */}
            <div className="proprice_infosec section_margin">
              <div className="property_pricesec">
                <h1 id="price"></h1>
                <span className="sold_tag">
                  <Image id="soldtag"></Image>
                </span>
                <div id="oriprice" className="line-price"></div>
                <h2>
                  <span id="mls"></span>
                </h2>
                <p>
                  {/* {this.state.lang["dtl_types"]} */}
                  <span id="typestyle"></span>
                </p>
                {/* <div className="property_locationsec"> */}
                <p>
                  <i className="fa fa-map-marker-alt"></i> <i id="address"></i>
                </p>
              </div>
              {/* property_pricesec */}
              <ul>
                <li>
                  <i className="fa fa-bed"></i> <span id="tit-bedrooms"></span>
                </li>
                <li>
                  <i className="fa fa-bath"></i>
                  <span id="tit-bathrooms"></span>
                </li>
                <li>
                  <i className="fa fa-car"></i> <span id="tit-parking"></span>
                </li>
                <li
                  className="share_libtn"
                  onClick={() => {
                    this.ShareClick();
                  }}
                >
                  <i className="fa fa-share"></i>{" "}
                  <span>{this.state.lang.share}</span>
                </li>
              </ul>
            </div>
            {/* proprice_infosec */}

            <div className="summary_contsec section_margin">
              <Tabs
                className="tab"
                defaultActiveKey="summary"
                id="detail-tab-profile-summary"
              >
                <Tab
                  eventKey="summary"
                  className="tabcontent"
                  title={this.state.lang["dtl_summary"]}
                >
                  <div className="common_infosec summary_tabsec">
                    <ul>
                      <li>
                        <p>{this.state.lang["dtl_type"]}</p>
                        <strong>
                          <p id="pro-type"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_bedrooms"]}</p>
                        <strong>
                          <p id="pro-bedrooms"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_kitchen"]}</p>
                        <strong>
                          <p id="pro-kitchen"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_bathrooms"]}</p>
                        <strong>
                          <p id="pro-bathrooms"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_lotsize"]}</p>
                        <strong>
                          <p id="pro-lotsize"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sqft"]}</p>
                        <strong>
                          <p id="pro-sqft"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_yr-built"]}</p>
                        <strong>
                          <p id="pro-yr-built"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_taxes"]}</p>
                        <strong>
                          <p id="pro-taxes"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_maint"]}</p>
                        <strong>
                          <p id="pro-maint"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_parking"]}</p>
                        <strong>
                          <p id="pro-parking"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_listed"]}</p>
                        <strong>
                          <p id="pro-listeddate"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_update"]}</p>
                        <strong>
                          <p id="pro-updatedate"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_basement"]}</p>
                        <strong>
                          <p id="pro-basement"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_brokered"]}</p>
                        <strong>
                          <p id="pro-broker"></p>
                        </strong>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div>{this.state.lang["dtl_extras"]}</div>
                    <strong>
                      <p id="pro-extras"></p>
                    </strong>
                  </div>
                </Tab>
                <Tab
                  eventKey="details"
                  className="tabcontent"
                  title={this.state.lang["dtl_details"]}
                >
                  <div className="common_infosec detail_tabsec">
                    <ul>
                      <li>
                        <p>{this.state.lang["dtl_style"]}</p>
                        <strong>
                          <p id="pro-construction-style"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_frontingon"]}</p>
                        <strong>
                          <p id="pro-front-on"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_community"]}</p>
                        <strong>
                          <p id="pro-community"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_zip"]}</p>
                        <strong>
                          <p id="pro-zip"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_cross-st"]}</p>
                        <strong>
                          <p id="pro-cross-st"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_watersupply"]}</p>
                        <strong>
                          <p id="pro-water-supply"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_acsystem"]}</p>
                        <strong>
                          <p id="pro-ac-system"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_heatingfuel"]}</p>
                        <strong>
                          <p id="pro-heating-fuel"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_heatingtype"]}</p>
                        <strong>
                          <p id="pro-heating-type"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_fireplace"]}</p>
                        <strong>
                          <p id="pro-fire-place"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_centralvac"]}</p>
                        <strong>
                          <p id="pro-central-vac"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sewer"]}</p>
                        <strong>
                          <p id="pro-sewer"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_construction"]}</p>
                        <strong>
                          <p id="pro-construction"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_municipality"]}</p>
                        <strong>
                          <p id="pro-municipality"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_swimmingpool"]}</p>
                        <strong>
                          <p id="pro-swimming-pool"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_basementtype"]}</p>
                        <strong>
                          <p id="pro-basement-type"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_basementfeatures"]}</p>
                        <strong>
                          <p id="pro-basement-features"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sizetotal"]}</p>
                        <strong>
                          <p id="pro-size-total"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_amenities"]}</p>
                        <strong>
                          <p id="pro-amenities"></p>
                        </strong>
                      </li>
                    </ul>
                  </div>
                </Tab>
                <Tab
                  eventKey="rooms"
                  className="tabcontent"
                  title={this.state.lang["dtl_rooms"]}
                >
                  <Button
                    id="pro-btn-mf"
                    style={{ width: "98%" }}
                    onClick={() => {
                      MeterFeetClick();
                    }}
                  >
                    Change To Feet
                  </Button>
                  <div className="common_infosec room_tabsec">
                    <ul>
                      <li>
                        <div>
                          <div
                            id="pro-room0-0"
                            className="pro_roomleft"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room0-1"
                            className="pro_roomright"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p
                          id="pro-room0-2"
                          className="pro_room"
                          style={{ width: "100%" }}
                        ></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room1-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room1-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room1-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room2-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room2-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room2-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room3-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room3-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room3-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room4-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room4-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room4-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room5-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room5-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room5-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room6-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room6-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room6-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room7-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room7-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room7-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room8-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room8-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room8-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room9-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room9-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room9-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room10-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room10-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room10-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room11-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room11-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room11-2" style={{ width: "100%" }}></p>
                      </li>

                      <li>
                        <div>
                          <div
                            id="pro-closet0-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-closet0-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-closet1-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-closet1-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-closet2-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-closet2-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-closet3-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-closet3-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-closet4-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-closet4-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Tab>
                <Tab
                  eventKey="others"
                  className="tabcontent"
                  title={this.state.lang["dtl_others"]}
                >
                  <div className="common_infosec detail_tabsec">
                    <ul>
                      <li>
                        <p>{this.state.lang["dtl_ass-year"]}</p>
                        <strong>
                          <p id="pro-ass-year"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_condo-exp"]}</p>
                        <strong>
                          <p id="pro-condo-exp"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_ens-lndry"]}</p>
                        <strong>
                          <p id="pro-ens-lndry"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_farm-agri"]}</p>
                        <strong>
                          <p id="pro-farm-agri"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_gas"]}</p>
                        <strong>
                          <p id="pro-gas"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_heat-inc"]}</p>
                        <strong>
                          <p id="pro-heat-inc"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_hydro-inc"]}</p>
                        <strong>
                          <p id="pro-hydro-inc"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_irreg"]}</p>
                        <strong>
                          <p id="pro-irreg"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_locker"]}</p>
                        <strong>
                          <p id="pro-locker"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_locker-num"]}</p>
                        <strong>
                          <p id="pro-locker-num"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_occupancy"]}</p>
                        <strong>
                          <p id="pro-occupancy"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_park-chgs"]}</p>
                        <strong>
                          <p id="pro-park-chgs"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_park-desig"]}</p>
                        <strong>
                          <p id="pro-park-desig"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_park-fac"]}</p>
                        <strong>
                          <p id="pro-park-fac"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_park-spc1"]}</p>
                        <strong>
                          <p id="pro-park-spc1"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_park-spc2"]}</p>
                        <strong>
                          <p id="pro-park-spc2"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_patio-ter"]}</p>
                        <strong>
                          <p id="pro-patio-ter"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_pets"]}</p>
                        <strong>
                          <p id="pro-pets"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_pool"]}</p>
                        <strong>
                          <p id="pro-pool"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_prkg-inc"]}</p>
                        <strong>
                          <p id="pro-prkg-inc"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_secgrd-sys"]}</p>
                        <strong>
                          <p id="pro-secgrd-sys"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_unit-num"]}</p>
                        <strong>
                          <p id="pro-unit-num"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_water-inc"]}</p>
                        <strong>
                          <p id="pro-water-inc"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_kit-plus"]}</p>
                        <strong>
                          <p id="pro-kit-plus"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_retirement"]}</p>
                        <strong>
                          <p id="pro-retirement"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_waterfront"]}</p>
                        <strong>
                          <p id="pro-waterfront"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_area-code"]}</p>
                        <strong>
                          <p id="pro-area-code"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_municipality-code"]}</p>
                        <strong>
                          <p id="pro-municipality-code"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_community-code"]}</p>
                        <strong>
                          <p id="pro-community-code"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_area"]}</p>
                        <strong>
                          <p id="pro-area"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_municipality-district"]}</p>
                        <strong>
                          <p id="pro-municipality-district"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_handi-equipped"]}</p>
                        <strong>
                          <p id="pro-handi-equipped"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_area-infl1-out"]}</p>
                        <strong>
                          <p id="pro-area-infl1-out"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_area-infl2-out"]}</p>
                        <strong>
                          <p id="pro-area-infl2-out"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_cable"]}</p>
                        <strong>
                          <p id="pro-cable"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_city-water"]}</p>
                        <strong>
                          <p id="pro-city-water"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_insu-bldg"]}</p>
                        <strong>
                          <p id="pro-insu-bldg"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_oth-struc1-out"]}</p>
                        <strong>
                          <p id="pro-oth-struc1-out"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_oth-struc2-out"]}</p>
                        <strong>
                          <p id="pro-oth-struc2-out"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_rec-room"]}</p>
                        <strong>
                          <p id="pro-rec-room"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sauna"]}</p>
                        <strong>
                          <p id="pro-sauna"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_share-perc"]}</p>
                        <strong>
                          <p id="pro-share-perc"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_spec-des"]}</p>
                        <strong>
                          <p id="pro-spec-des"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sqs-rac-ct"]}</p>
                        <strong>
                          <p id="pro-sqs-rac-ct"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_stories"]}</p>
                        <strong>
                          <p id="pro-stories"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_tennis-ct"]}</p>
                        <strong>
                          <p id="pro-tennis-ct"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_wtr-suptyp"]}</p>
                        <strong>
                          <p id="pro-wtr-suptyp"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_zoning"]}</p>
                        <strong>
                          <p id="pro-zoning"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_prop-mgmt"]}</p>
                        <strong>
                          <p id="pro-prop-mgmt"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_all-inc"]}</p>
                        <strong>
                          <p id="pro-all-inc"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_furnished"]}</p>
                        <strong>
                          <p id="pro-furnished"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_pvt-ent"]}</p>
                        <strong>
                          <p id="pro-pvt-ent"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_laundry"]}</p>
                        <strong>
                          <p id="pro-laundry"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_lease-term"]}</p>
                        <strong>
                          <p id="pro-lease-term"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_pay-freq"]}</p>
                        <strong>
                          <p id="pro-pay-freq"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_pay-meth"]}</p>
                        <strong>
                          <p id="pro-pay-meth"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_app-req"]}</p>
                        <strong>
                          <p id="pro-app-req"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sec-dep"]}</p>
                        <strong>
                          <p id="pro-sec-dep"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_credit-chk"]}</p>
                        <strong>
                          <p id="pro-credit-chk"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_emply-lett"]}</p>
                        <strong>
                          <p id="pro-emply-lett"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_lease"]}</p>
                        <strong>
                          <p id="pro-lease"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_ref-req"]}</p>
                        <strong>
                          <p id="pro-ref-req"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_opt-to-buy"]}</p>
                        <strong>
                          <p id="pro-opt-to-buy"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_laundry-lev"]}</p>
                        <strong>
                          <p id="pro-laundry-lev"></p>
                        </strong>
                      </li>
                    </ul>
                  </div>
                </Tab>
              </Tabs>
              <div className="page-padding description-sec">
                <div className="flex-container">
                  {this.state.lang["dtl_remarks"]}
                  <p className="report_chart_new_radiogrp">
                    <span
                      id="des_lang_en"
                      className="active"
                      onClick={() => this.descriptionLang("en")}
                    >
                      EN
                    </span>
                    <span
                      id="des_lang_ch"
                      onClick={() => this.descriptionLang("ch")}
                    >
                      中文
                    </span>
                  </p>
                </div>
                <div id="pro-remarks" style={{ width: "98%" }}>
                  {this.state.des_loading ? <LoadingSkeleton /> : null}
                </div>
              </div>
            </div>
            {/* <Snippet /> */}

            <div className="main_accordinsec section_margin">
              {/* <button className="accordion">
                <i className="fas fa-address-card"></i>{this.state.lang.dtl_toggle_log}
              </button>
              <div className="panel">
                <div className="inner_prohistory_sec">
                  <div id="detail_log">
                    <ul className="main_historysec"></ul>
                  </div>
                </div> */}

              <button className="accordion active" id="toggle_history">
                <i className="fas fa-address-card"></i>
                {this.state.lang.dtl_toggle_history}
                {/* <i className="toggle_btn fas fa-chevron-up"></i> */}
              </button>
              <div
                className="panel"
                id="toggle_history_div"
                style={{ maxHeight: "fit-content" }}
              >
                <div className="inner_prohistory_sec">
                  {/* <div className="hide_propertysec" id="history_cover">
                    <div className="inner_hidepropsec">
                      <i className="fas fa-user-lock"></i>
                      <p>{this.state.lang.loginrequire}</p>
                    </div>
                  </div> */}
                  {/* <div id="detail_history">
                    <ul className="main_historysec"></ul>
                  </div> */}
                  <P_History history={this.state.priceHistory} />
                </div>
                {/* inner_prohistory_sec */}
              </div>
              {/* panel */}

              <button className="accordion active" id="toggle_community">
                <i className="fas fa-clipboard-list"></i>
                {this.state.lang.dtl_toggle_report}
              </button>
              <div
                className="panel iframe_community_div"
                id="toggle_community_div"
                style={{ maxHeight: "fit-content" }}
              >
                <div className="iframe_overlay">
                  <iframe
                    id="iframe_community"
                    src=""
                    style={{
                      width: "100%",
                    }}
                    frameBorder="no"
                    border="0"
                    marginWidth="0"
                    marginHeight="0"
                    scrolling="no"
                    allowtransparency="yes"
                    position="relative"
                    overflow="auto"
                  />
                  <div className="iframe_overlay_cover"></div>
                </div>
              </div>
              {/* panel */}

              <button
                className="accordion"
                onClick={() => {
                  this.defaultCensusDisplay();
                }}
              >
                <i className="fas fa-clipboard-list"></i>
                {this.state.lang.dtl_toggle_census}
              </button>
              <div className="panel">
                <iframe
                  id="iframe_census"
                  src=""
                  style={{
                    width: "100%",
                  }}
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}

              <button className="accordion" id="toggle_mortgage">
                <i className="fas fa-clipboard-list"></i>
                {this.state.lang.dtl_toggle_mortgage}
              </button>
              <div className="panel" id="toggle_mortgage_div">
                <div className="iframe_overlay">
                  <iframe
                    id="iframe_mortgage"
                    src=""
                    style={{ width: "100%" }}
                    scrolling="no"
                    border="0"
                    marginWidth="0"
                    marginHeight="0"
                    position="relative"
                    frameBorder="no"
                  />
                  <div className="iframe_overlay_cover"></div>
                </div>
              </div>
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-school"></i>
                {this.state.lang.dtl_toggle_schools}
              </button>
              <div className="panel">
                <iframe
                  id="iframe_school"
                  src=""
                  style={{
                    width: "100%",
                    height: document.body.clientWidth * 1.59,
                  }}
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-clipboard-list"></i>
                {this.state.lang.dtl_toggle_walkscore}
              </button>
              <div className="panel">
                <div className="iframe_overlay">
                  <iframe
                    id="iframe_walkscore"
                    src=""
                    style={{ width: "100%" }}
                    scrolling="no"
                    border="0"
                    marginWidth="0"
                    marginHeight="0"
                    position="relative"
                    frameBorder="no"
                  />
                  <div className="iframe_overlay_cover"></div>
                </div>
              </div>
              {/* panel */}

              {/* <button className="accordion">
                <i className="fas fa-home"></i> Home Value & Rental Evaluation
              </button>
              <div className="panel">
                <div className="property_evaluationsec">
                  <div className="evaluation_detsec">
                    <ul>
                      <li>
                        <p>Home Value Evaluation: </p>
                        <strong> $1,000,000</strong>
                      </li>
                      <li>
                        <p>Home Rental Evaluation: </p>
                        <strong> $3000/month</strong>
                      </li>
                    </ul>
                  </div> */}
              {/* evaluation_detsec */}
              {/* </div> */}
              {/* property_evaluationsec */}
              {/* </div> */}
              {/* panel */}
            </div>
            {/* main_accordinsec */}

            <div className="features_iconsec section_margin">
              <div className="inner_featiconsec">
                <ul>
                  <li>
                    <img src={require("../../../images/map_icon.png")} alt="" />
                    <span>Map</span>
                  </li>
                  <li>
                    <img
                      src={require("../../../images/transit_icon.png")}
                      alt=""
                    />
                    <span>Transit Score</span>
                  </li>
                  <li>
                    <img
                      src={
                        require("../../../images/community_icon.png").default
                      }
                      alt=""
                    />
                    <span>Community</span>
                  </li>
                  <li>
                    <img
                      src={require("../../../images/street_icon.png")}
                      alt=""
                    />
                    <span>Street View</span>
                  </li>
                </ul>
              </div>
              {/* inner_featiconsec */}
            </div>
            {/* features_iconsec */}

            <div
              className="listings_sec section_margin page-padding"
              id="slider_sold"
            >
              <div className="main_headingsec">
                <h1>{this.state.lang.dtl_card_sold_title}</h1>
                <p>{this.state.lang.dtl_card_sold_des}</p>
              </div>
              {/* main_headingsec */}

              <div className="listing_wrappersec">
                <div className="slider variable-width">
                  <Slider {...setting1}>
                    {this.state.soldList.map(function (item) {
                      return (
                        <div key={item.I}>
                          <div hidden={!item.V}>
                            <div className="inner_listingsec">
                              <div className="loginrequired_sec">
                                <p>{item.lgrq1}</p>
                                <button
                                  type="button"
                                  className="common_mainbtn btn btn-primary"
                                >
                                  {item.lgrq0}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div hidden={item.V}>
                            <a href={item.link}>
                              <div className="inner_listingsec">
                                <div className="listing_imgsec">
                                  <Image src={item.pic} alt="" />
                                </div>
                                {/* listing_imgsec */}
                                <div className="listing_detsec">
                                  <h2>${item.pr}</h2>{" "}
                                  <div className="line-price">
                                    ${item.oripr}
                                  </div>
                                  {/* <span className="sold_tag">
                                    <Image src={item.soldtag}></Image>
                                  </span> */}
                                  <span className="flex-container">
                                    <i className="fa fa-map-marker-alt"></i>
                                    <span className="eclipse-2">{item.c}</span>
                                  </span>
                                  <p className="eclipse-2 listing_city">
                                    {
                                      item.c.split(",")[
                                      item.c.split(",").length - 1
                                      ]
                                    }
                                  </p>
                                  <ul>
                                    <li>
                                      <i className="fa fa-bed"></i>
                                      <p>{item.b1.split(":")[1]}</p>
                                    </li>
                                    <li>
                                      <i className="fa fa-bath"></i>
                                      <p>{item.a1.split(":")[1]}</p>
                                    </li>
                                    <li>
                                      <i className="fa fa-car"></i>
                                      <p>{item.c1.split(":")[1]}</p>
                                    </li>
                                  </ul>
                                  <div className="flex-container listingsec-btm">
                                    <div
                                      style={{ marginBottom: 0 }}
                                      className="small-font"
                                    >
                                      <p>{enj.dtl_property_history_soldat}</p>
                                      <p
                                        style={{ color: "var(--title-color)" }}
                                      >
                                        {dateConvert(item.l.split("T")[0])}
                                      </p>
                                    </div>
                                    <div
                                      style={{ marginBottom: 0 }}
                                      className="small-font"
                                    >
                                      <p>DOM</p>
                                      <p
                                        style={{ color: "var(--title-color)" }}
                                      >
                                        {domCalculate(item.l, item.f)}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="property_status">
                                    <button>{item.e}</button>
                                  </div>
                                  {/* forsale */}
                                </div>
                                {/* listing_detsec */}
                              </div>
                              {/* inner_listingsec */}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                {/* slider */}
              </div>
              {/* listing_wrappersec */}
            </div>
            {/* listings_sec */}

            <div
              className="listings_sec section_margin page-padding"
              id="slider_leased"
            >
              <div className="main_headingsec">
                <h1>{this.state.lang.dtl_card_rent_title}</h1>
                <p>{this.state.lang.dtl_card_rent_des}</p>
              </div>
              {/* main_headingsec */}

              <div className="listing_wrappersec">
                <div className="slider variable-width">
                  <Slider {...setting1}>
                    {this.state.leasedList.map(function (item) {
                      return (
                        <div key={item.I}>
                          <div hidden={!item.V}>
                            <div className="inner_listingsec">
                              <div className="loginrequired_sec">
                                <p>{item.lgrq1}</p>
                                <button
                                  type="button"
                                  className="common_mainbtn btn btn-primary"
                                >
                                  {item.lgrq0}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div hidden={item.V}>
                            <a href={item.link}>
                              <div className="inner_listingsec">
                                <div className="listing_imgsec">
                                  <Image src={item.pic} alt="" />
                                </div>
                                {/* listing_imgsec */}
                                <div className="listing_detsec">
                                  <h2>${item.pr}</h2>
                                  <div className="line-price">
                                    ${item.oripr}
                                  </div>
                                  {/* <span className="sold_tag">
                                    <Image src={item.soldtag}></Image>
                                  </span> */}
                                  <span className="flex-container">
                                    <i className="fa fa-map-marker-alt"></i>
                                    <span className="eclipse-2">{item.c}</span>
                                  </span>
                                  <p className="eclipse-2 listing_city">
                                    {
                                      item.c.split(",")[
                                      item.c.split(",").length - 1
                                      ]
                                    }
                                  </p>
                                  <ul>
                                    <li>
                                      <i className="fa fa-bed"></i>
                                      <p>{item.b1.split(":")[1]}</p>
                                    </li>
                                    <li>
                                      <i className="fa fa-bath"></i>
                                      <p>{item.a1.split(":")[1]}</p>
                                    </li>
                                    <li>
                                      <i className="fa fa-car"></i>
                                      <p>{item.c1.split(":")[1]}</p>
                                    </li>
                                  </ul>
                                  <div className="property_status">
                                    <button>{item.e}</button>
                                  </div>
                                  {/* forsale */}
                                </div>
                                {/* listing_detsec */}
                              </div>
                              {/* inner_listingsec */}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                {/* slider */}
              </div>
              {/* listing_wrappersec */}
            </div>
            {/* listings_sec */}

            <div
              className="listings_sec section_margin page-padding"
              id="slider_feature"
            >
              <div className="main_headingsec">
                <h1>{this.state.lang.dtl_card_featured_title}</h1>
                <p>{this.state.lang.dtl_card_featured_des}</p>
              </div>
              {/* main_headingsec */}

              <div className="listing_wrappersec">
                <div className="slider variable-width">
                  <Slider {...setting1}>
                    {this.state.featuredList.map(function (item) {
                      return (
                        <div key={item.I}>
                          <div hidden={!item.V}>
                            <div className="inner_listingsec">
                              <div className="loginrequired_sec">
                                <p>{item.lgrq1}</p>
                                <button
                                  type="button"
                                  className="common_mainbtn btn btn-primary"
                                >
                                  {item.lgrq0}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div hidden={item.V}>
                            <a href={item.link}>
                              <div className="inner_listingsec">
                                <div className="listing_imgsec">
                                  <Image src={item.pic} alt="" />
                                </div>
                                {/* listing_imgsec */}
                                <div className="listing_detsec">
                                  <h2>${item.pr}</h2>
                                  <span className="flex-container">
                                    <i className="fa fa-map-marker-alt"></i>
                                    <span className="eclipse-2">{item.c}</span>
                                  </span>
                                  <p className="eclipse-2 listing_city">
                                    {
                                      item.c.split(",")[
                                      item.c.split(",").length - 1
                                      ]
                                    }
                                  </p>
                                  <ul>
                                    <li>
                                      <i className="fa fa-bed"></i>
                                      <p>{item.b1.split(":")[1]}</p>
                                    </li>
                                    <li>
                                      <i className="fa fa-bath"></i>
                                      <p>{item.a1.split(":")[1]}</p>
                                    </li>
                                    <li>
                                      <i className="fa fa-car"></i>
                                      <p>{item.c1.split(":")[1]}</p>
                                    </li>
                                  </ul>
                                  <div className="property_status">
                                    <button>{item.e}</button>
                                  </div>
                                  {/* forsale */}
                                </div>
                                {/* listing_detsec */}
                              </div>
                              {/* inner_listingsec */}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                {/* slider */}
              </div>
              {/* listing_wrappersec */}
            </div>
            {/* listings_sec */}
          </div>
          {/* wrapper_margin */}
        </div>
        {/* wrapper */}

        <div id="footer"></div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadDetail() {
    try {
      var url = document.location.toString();
      if (url[url.length - 1] === "=") {
        // remove the last '=' character
        url = url.slice(0, -1);
      }
      let paras = url.split("?").splice(1, 1)[0].split("&");
      let tk = paras[0].split("=")[1];
      let ngw = paras[1].split("=")[1];
      var lg = 0;
      if (paras.length === 3) {
        lg = paras[2].split("=")[1];
      }
      this.setState({ tk: tk, lg: lg }); // save token
      let tmpngw = replaceAllFixed(ngw, "~", "=");
      let decode = window.atob(tmpngw).split(","); // load detail information
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let paraDTL = "\"{'tk':'" + tk + "','id':'" + decode[1] + "'}\"";
      //Load one agent
      let tkDTL = "\"{'tk':'" + tk + "'}\"";
      axios.post("/agent/getone", tkDTL).then((res) => {
        this.setState({ agentList: res.data });
      });
      //Load Agents
      // axios.post("/user/agents", paraDTL).then((res) => {
      //   var arr = res.data;
      //   for (var i = 0; i < res.data.length; i++) {
      //     arr[i]["I"] = i + 1;
      //   }
      //   console.log(arr[0]);
      //   this.setState({ agentList: arr[0] });
      // });
      //Load Detail
      axios.post("/detail/id", paraDTL).then((res0) => {
        // guard null
        if (!res0.data.apsl) {
          return;
        }

        // save detail data
        this.setState({ dtl: res0.data });

        // Listing Detail Data
        if (this.state.dtl.apsl != undefined) {
          if (this.state.dtl.apsl.source === VOW) {
            SetupVowPage(this.state.dtl.data);

            // Log
            // axios.post("/detail/log", paraDTL).then((res) => {
            //   console.log("logss", res.data);
            // });

            // History
            axios.post("/detail/trans", paraDTL).then((res) => {
              this.state.priceHistory = res.data;
            });
            // axios.post("/detail/history", paraDTL).then((res) => {
            //   // createHistory(res.data, this.state.lang);
            //   let postData = [];
            //   res.data.map((data) => {
            //     const detail = data.split("#");
            //     postData.push({
            //       wnum: detail[3],
            //       listPrice: new Intl.NumberFormat("en-US", {
            //         style: "currency",
            //         currency: "USD",
            //       }).format(detail[1]),
            //       soldPrice:
            //         detail[7] != "Sld"
            //           ? ""
            //           : new Intl.NumberFormat("en-US", {
            //               style: "currency",
            //               currency: "USD",
            //             }).format(detail[2]),
            //       terPrice:
            //         detail[7] != "Ter"
            //           ? ""
            //           : new Intl.NumberFormat("en-US", {
            //               style: "currency",
            //               currency: "USD",
            //             }).format(detail[1]),
            //       terDate:
            //         detail[8] == "" ? "" : dateConvert(detail[8].split(" ")[0]),
            //       listDate: dateConvert(detail[4].split(" ")[0]),
            //       soldDate:
            //         detail[5] == "" ? "" : dateConvert(detail[5].split(" ")[0]),
            //     });
            //   });
            //   this.state.priceHistory = postData;
            // });
            // <2: Anonymous
            // if (lg > 2) {
            //   this.setState({ lg: lg });
            //   document.getElementById("history_cover").hidden = true;
            // } else {
            //   this.setState({ lg: 0 });
            //   document.getElementById("history_cover").hidden = false;
            // }
          } else {
            SetupDdfPage(this.state.dtl.data, this.state.dtl.extra);

            // History
            document.getElementById("toggle_history").hidden = true;
            document.getElementById("toggle_history_div").hidden = true;
          }
        }
        // Sold/Leased Tag
        var status = "a";
        if (this.state.dtl.apsl.status === "U") {
          status = "c";
          if (
            this.state.dtl.apsl.listingType === "Sale" ||
            this.state.dtl.apsl.listingType === "S"
          ) {
            document.getElementById(
              "soldtag"
            ).src = require("../../../images/sold_stamp.png");
          } else {
            document.getElementById(
              "soldtag"
            ).src = require("../../../images/leased_stamp.png");
          }
        }

        // Census
        this.setState({
          census_loaded: createCensusData(
            this.state.dtl.apsl.zip,
            this.state.tk,
            this.state.census_loaded
          ),
        });
        fixFrameHeight("iframe_census", 0.97);

        // Community, Only if on VOW
        if (this.state.dtl.apsl.source === VOW) {
          this.setState({
            community_loaded: createCommunityChart(
              this.state.dtl.apsl.community,
              this.state.dtl.apsl.nodeType,
              this.state.tk,
              this.state.community_loaded
            ),
          });

          fixFrameHeight("iframe_community", 1.03);

          document.getElementById("toggle_community").hidden = false;
          document.getElementById("toggle_community_div").hidden = false;
        } else {
          document.getElementById("toggle_community").hidden = true;
          document.getElementById("toggle_community_div").hidden = true;
        }

        // Mortgage
        if (
          this.state.dtl.apsl.listingType === "Sale" ||
          this.state.dtl.apsl.listingType === "S"
        ) {
          this.setState({
            mortgage_loaded: createMortgage(
              this.state.dtl.apsl.price,
              this.state.lang.site,
              this.state.mortgage_loaded
            ),
          });
          document.getElementById("iframe_mortgage").style.height =
            window.screen.width * 1.55 + "px";
          // fixFrameHeight("iframe_mortgage", 1.55);
        } else {
          document.getElementById("toggle_mortgage").hidden = true;
          document.getElementById("toggle_mortgage_div").hidden = true;
        }

        // Walkscore
        let addr =
          this.state.dtl.apsl.streetNumber +
          " " +
          this.state.dtl.apsl.streetName +
          " " +
          this.state.dtl.apsl.streetSuffix +
          "," +
          this.state.dtl.apsl.city;
        this.setState({
          walkscore_loaded: createWalkScore(
            addr,
            this.state.tk,
            this.state.walkscore_loaded
          ),
        });
        fixFrameHeight("iframe_walkscore", 0.87);

        // Check LatLng, and fill FindSchool
        if (this.state.dtl.apsl.lat === -85 || this.state.dtl.apsl.lat === 0) {
          let paraASL =
            "\"{'tk':'" +
            tk +
            "','mt':'" +
            status +
            ";1;1;dF;0;=;" +
            decode[1] +
            "','st':'" +
            def_site +
            "'}\"";
          axios.post("/search/map", paraASL).then((asl) => {
            // guard null
            if (!asl.data || asl.data.length === 0) {
              return;
            }
            // Load with LatLng
            this.loadWithLatLng(asl.data[0].X, asl.data[0].Y);
            if (document.location.host == findschoolhost) {
              fixFrameHeight("iframe_school", 0.55);
            }
          });
        } else {
          // Load with LatLng
          this.loadWithLatLng(this.state.dtl.apsl.lat, this.state.dtl.apsl.lng);
        }
      });
    } catch { }
  }

  loadWithLatLng(lat, lng) {
    // Setup School Report
    this.setState({
      school_loaded: createSchoolReport(lat, lng, this.state.school_loaded),
    });
    if (document.location.host == findschoolhost) {
      fixFrameHeight("iframe_school", 0.55);
    }

    // make sold/leased date tag
    var cur = new Date();
    cur.setMonth(cur.getMonth() - 6);
    let dtStr =
      cur.getFullYear() + "-" + (cur.getMonth() + 1) + "-" + cur.getDate();
    // similar sold/leased listings, Only if on VOW
    if (this.state.dtl.apsl.source === VOW) {
      // sold
      let param_sold =
        "\"{'mt':'c;20;1;dF;u4,E,3,w7,x2;!,=,=,=,>;COM,S," +
        lat +
        "_" +
        lng +
        "|" +
        0.007 +
        ",Sld," +
        dtStr +
        "','tk':'" +
        this.state.tk +
        "','st':'" +
        def_site +
        "'}\"";
      axios.post("/search/list", param_sold).then((res) => {
        if (res.data) {
          this.setState({ soldList: this.fillList(res.data) });
          if (res.data.length < 1) {
            document.getElementById("slider_sold").hidden = true;
          }
        }
      });

      // leased
      let param_leased =
        "\"{'mt':'c;20;1;F;u4,E,3,w7,x2;!,=,=,=,>;COM,L," +
        lat +
        "_" +
        lng +
        "|" +
        0.007 +
        ",Lsd," +
        dtStr +
        "','tk':'" +
        this.state.tk +
        "','st':'" +
        def_site +
        "'}\"";
      axios.post("/search/list", param_leased).then((res) => {
        if (res.data) {
          this.setState({ leasedList: this.fillList(res.data) });
          if (res.data.length < 1) {
            document.getElementById("slider_leased").hidden = true;
          }
        }
      });
    } else {
      document.getElementById("slider_sold").hidden = true;
      document.getElementById("slider_leased").hidden = true;
    }

    // similar featured listings
    let param_featured =
      "\"{'mt':'a;20;1;dF;u4,E,3;!,=,=;COM,S," +
      lat +
      "_" +
      lng +
      "|" +
      0.007 +
      "','tk':'" +
      this.state.tk +
      "','st':'" +
      def_site +
      "'}\"";
    axios.post("/search/list", param_featured).then((res) => {
      if (res.data) {
        this.setState({ featuredList: this.fillList(res.data) });
        if (res.data.length < 1) {
          document.getElementById("slider_feature").hidden = true;
        }
      }
    });
  }

  fillList = (data) => {
    let str_0 = gesture + document.location.host + "/listing?";
    let str_1 = "=";
    let str_2 = "&ngw=";
    data.forEach((element) => {
      // fill detail link
      let ngw_0 = "940682259,";
      let ngw_1 = ",";
      let dt = "2021-01-08 12:25:08";
      let ngw_2 = ",";
      var ngw = window.btoa(ngw_0 + element.I + ngw_1 + dt + ngw_2 + element.O);
      ngw = ngw.replace("=", "~");
      element["link"] =
        str_0 + this.state.tk + str_1 + ngw + str_1 + this.state.lg;
      // fill picture link
      element["pic"] =
        gesture + pic_host + pic_link + this.state.tk + str_2 + ngw;
      // fix parking
      if (element["c1"] === null) {
        element["c1"] = 0;
      }
      // fix address
      element["c"] = replaceAllFixed(element["c"], ",", " ");
      element["c"] = replaceAllFixed(element["c"], "|", ",");
      // fix price showing
      if (element["h"] === "A") {
        element["pr"] = priceSwitch(element["a"]);
      } else {
        if (element["g"] !== null) {
          element["pr"] = priceSwitch(element["g"]);
          element["oripr"] = priceSwitch(element["a"]);
        } else {
          element["pr"] = priceSwitch(element["a"]);
        }
      }
      // fix visible tag
      if (this.state.lg > 2) {
        element.V = false;
      } else {
        if (element.V === false) {
          element.V = false;
        } else {
          element.V = true;
          element["lgrq1"] = this.state.lang.loginrequire1;
          element["lgrq0"] = this.state.lang.loginrequire0;
        }
      }
      // add Language Tag
      element["b1"] = this.state.lang.card_listing_bed + element["b1"];
      element["a1"] = this.state.lang.card_listing_bath + element["a1"];
      element["c1"] = this.state.lang.card_listing_parking + element["c1"];
      if (element["e"] === "Sale" || element["e"] === "S") {
        if (element["h"] === "A" || element["h"] === null) {
          element["e"] = this.state.lang.card_listing_sale;
        } else {
          element["e"] = this.state.lang.card_listing_sold;
          element["soldtag"] = require("../../../images/sold_stamp.png");
        }
      } else {
        if (element["h"] === "A") {
          element["e"] = this.state.lang.card_listing_lease;
        } else {
          element["e"] = this.state.lang.card_listing_leased;
          element["soldtag"] = require("../../../images/leased_stamp.png");
        }
      }
    });
    return data;
  };

  ShareClick() {
    let nTitle =
      document.getElementById("price").innerText +
      " --- " +
      document.getElementById("address").innerText;

    var share_listingType = this.state.lang.dtl_share_sale;
    if (
      this.state.dtl.apsl.listingType === "Lease" ||
      this.state.dtl.apsl.listingType === "L"
    ) {
      share_listingType = this.state.lang.dtl_share_lease;
    }
    let nBrief =
      this.state.dtl.apsl.propertyType +
      share_listingType +
      this.state.lang.dtl_share_beds +
      document.getElementById("tit-bedrooms").innerText +
      this.state.lang.dtl_share_baths +
      document.getElementById("tit-bathrooms").innerText +
      this.state.lang.dtl_share_parkings +
      document.getElementById("tit-parking").innerText;
    var nImage = "";
    if (this.state.images[0] !== undefined) {
      nImage = this.state.images[0].link;
    }

    let paraDTL =
      "\"{'tk':'" +
      this.state.tk +
      "','id':'" +
      this.state.dtl.apsl.propertyId +
      "','hd':'1'}\"";
    axios.post("/share/token", paraDTL).then((res) => {
      let url = shkyhost + "/yiju/shdtl?" + res.data;
      let str = url + ";" + nTitle + ";" + nBrief + ";" + nImage;
      let params = Base64.encode(str);
      passParams("sharemedia", params);
    });
  }
}
