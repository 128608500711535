import React, { Component } from "react";
import { replaceAllFixed } from "../../function/Basic.js";

export class WalkScoreSharing extends Component {
  static displayName = WalkScoreSharing.name;

  constructor(props) {
    super(props);
    this.state = {
      wskey: null,
    };
  }

  componentDidMount() {
    this.createWalkScore();

    // window.onload = function () {
    //   let footer = document.getElementById("ws-footer");
    //   footer.remove();
    // };

    // setTimeout(function () {
    //   let footer = document.getElementById("ws-footer");
    //   footer.remove();
    // }, 3000);
  }

  render() {
    return (
      <div>
        <div id="ws-walkscore-tile"></div>
      </div>
    );
  }

  importWalkSchoolJS() {
    let script = document.querySelector("#script");
    if (script) {
      return;
    }
    script = document.createElement("script");
    script.id = "script_import";
    script.src = "https://www.walkscore.com/tile/show-walkscore-tile.php";
    document.querySelector("body").appendChild(script);
  }

  removeFooter() {
    let footer = document.getElementById("ws-street");
    footer.remove();
  }

  async createWalkScore() {
    var url = document.location.toString();
    let paras = url.split("?").splice(1, 1)[0].split("#")[0].split("=");
    if (paras.length < 2) {
      return;
    }
    this.setState({
      wskey: paras[0],
    });
    let tmpparas1 = replaceAllFixed(paras[1], "~", "=");
    let address = window.atob(tmpparas1);
    let style = "tall";
    let width = window.screen.width;
    let height = width * 1.5;

    const jsCode =
      "var ws_wsid = '" +
      this.state.wskey +
      "'; var ws_address = '" +
      address +
      "'; var ws_format = '" +
      style +
      "'; var ws_width = '" +
      width +
      "'; var ws_height = '" +
      height +
      "';";
    let script = document.querySelector("#script");
    if (script) {
      return;
    }
    script = document.createElement("script");
    script.id = "script_init";
    script.innerHTML = jsCode;
    document.querySelector("body").appendChild(script);

    this.importWalkSchoolJS();
  }
}
