import React, { useEffect, useState } from "react";
import socialIcons from "./socialIcons.json";
import { useSwipeable } from "react-swipeable";

const FloatAgent = ({ agentList }) => {
  const [expandAgentCard, useExpandAgentCard] = useState(true);
  const [layout, setLayout] = useState("row");

  const config = {
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      expandAgent();
    },
    ...config,
  });

  const expandAgent = () => {
    if (expandAgentCard) {
      document.querySelector("#float_agent").classList.toggle("expand");
    } else {
      document.querySelector("#float_agent").classList.remove("expand");
    }
    useExpandAgentCard(!expandAgentCard);
  };

  useEffect(() => {
    //disable canva scroll
    document
      .getElementById("float_agent")
      .addEventListener("touchmove", function (event) {
        event.preventDefault();
      });
    if (agentList.length > 0 && agentList.Socials.length > 0) {
      if (agentList.Socials.length > 6) {
        setLayout("column");
      } else {
        setLayout("row");
      }
    }
  }, [agentList]);

  const renderSocialIcons = () => {
    let hasDomain = false;
    let domainEle = document.getElementById("agent_domain");
    return agentList.Socials.map((i, index) => {
      if (i.icon == "website") {
        domainEle.href = i.url;
        domainEle.innerHTML = i.url;
        hasDomain = true;
      }
      if (index == agentList.Socials.length - 1 && hasDomain == false) {
        document.getElementById("agent_domain_container").style.display =
          "none";
      }
      return socialIcons[i.icon] ? (
        <a href={i.url} target="_blank" key={index}>
          <div dangerouslySetInnerHTML={{ __html: socialIcons[i.icon] }}></div>
        </a>
      ) : null;
    });
  };

  return (
    <div {...handlers}>
      <div className="float_agent" id="float_agent">
        <div className="float_agent_top">
          <div className="float_agent_top_bar"></div>
        </div>
        <div className="float_agent_detail">
          <div className="float_agent_d_left">
            <div className="agent_ava">
              <img src={agentList.Headimage} />
            </div>
            <div className="agent_info">
              <p className="agent_name">
                {agentList.Firstname} {agentList.Lastname}
              </p>
              <p className="unimportant-txt">{agentList.Title}</p>
              <p className="unimportant-txt">{agentList.Company}</p>
            </div>
          </div>
          <div className="badges_with_bk">
            <a href={"tel:" + agentList.Phone}>
              <i className="fa fa-phone-square" aria-hidden="true"></i>
            </a>
            <a href={"mailto:" + agentList.Email}>
              <i className="fa fa-envelope" aria-hidden="true"></i>
            </a>
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              onClick={() => expandAgent()}
            ></i>
          </div>
        </div>
        <div className="float_agent_domain" id="agent_domain_container">
          <a href="" target="_blank" id="agent_domain"></a>
        </div>
        <div className="float_agent_hidden" style={{ flexDirection: layout }}>
          {agentList.Qrimage ? (
            <img
              style={{ margin: layout == "column" ? "auto" : "0" }}
              src={agentList.Qrimage}
            />
          ) : null}
          <div
            className="float_agent_hidden_left"
            style={{
              textAlign: layout == "column" ? "center" : "left",
              margin: layout == "column" ? "auto" : "0",
            }}
          >
            {agentList.Slogan ? (
              <p
                className="slogan"
                style={{
                  marginBottom: layout == "column" ? "revert" : "auto",
                }}
              >
                {agentList.Slogan}
              </p>
            ) : null}
            {agentList.Socials ? (
              <div
                className="iconList"
                style={{
                  justifyContent: layout == "column" ? "center" : "left",
                }}
              >
                {renderSocialIcons()}
              </div>
            ) : null}
          </div>
        </div>
        <p className="float_agent_intro">{agentList.Introduction}</p>
      </div>
    </div>
  );
};

export default FloatAgent;
