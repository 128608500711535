import React, { Component } from "react";
import axios from "axios";
import ReactSelect from "react-select";
import { datahost } from "../../config/Const.js";
import { JsonToKeyValue } from "../../function/Basic.js";
import { SetChartCircle } from "../../function/Chart.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/Chart.css";

export class CensusData2016Sharing extends Component {
  static displayName = CensusData2016Sharing.name;

  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      sh: null,
      pc: null,
      data: null,
      allTopic: [],
      ddOpen: false,
    };

    // this.pickTopic = this.pickTopic.bind(this);
    // this.dropdownToggle = this.dropdownToggle.bind(this);
  }

  componentDidMount() {
    this.initLanguage();
    this.loadDetail();
  }

  render() {
    return (
      <div>
        <div id="censusdata"></div>

        <div id="topic" className="mb-2">
          <ReactSelect
            id="select_topic"
            className="select_topic"
            style={{ width: "100%" }}
            placeholder="Choose Topic"
            options={JsonToKeyValue(this.state.allTopic, "t", "k")}
            onChange={this.handleAreaChange}
            defaultValue={JsonToKeyValue(this.state.allTopic, "t")[0]}
            isSearchable={false}
          ></ReactSelect>
        </div>

        <br />

        <div
          id="chart_census"
          style={{ width: "100%", height: document.body.clientWidth * 1.1 }}
        ></div>

        <br />

        {/* <div id="topic" className="mb-2">
          <Dropdown isOpen={this.state.ddOpen} toggle={this.dropdownToggle}>
            <DropdownToggle caret>Dropdown</DropdownToggle>
            <DropdownMenu>
              
              {this.state.allTopic.map(function (item) {
                return (
                  <DropdownItem
                    key={item.k}
                    onClick={() => {
                      let title = document.getElementById("topic");
                      title.children[0].children[0].innerHTML = item.t;
                    }}
                  >
                    {item.t}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </div> */}
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadDetail() {
    try {
      var url = document.location.toString();
      let paras = url.split("?").splice(1, 1)[0].split("#")[0].split("=");
      if (paras.length < 2) {
        return;
      }
      this.setState({
        sh: paras[0],
        pc: paras[1],
      });
      this.initTopic(paras[0]);
      this.initCensusData(paras[0], paras[1]);

      // init description
      let census = document.getElementById("chart_census");
      census.innerHTML = this.state.lang.chart_ddl_selection;
    } catch {}
  }

  initTopic(sh) {
    try {
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      var idx_lang = "_en";
      if (this.state.lang === zhj) {
        idx_lang = "_zh";
      }
      let params = "\"{'sh':'" + sh + "','pc':'" + idx_lang + "'}\"";
      axios.post("/share/census2016", params).then((res) => {
        let topics = JSON.parse(window.atob(res.data));
        this.setState({
          allTopic: topics,
        });
      });
    } catch {}
  }

  initCensusData(sh, pc) {
    try {
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let params = "\"{'sh':'" + sh + "','pc':'" + pc + "'}\"";
      axios.post("/share/census2016", params).then((res) => {
        if (res.data !== null) {
          this.setState({
            data: res.data,
          });
        } else {
          this.nodataError();
        }
      });
    } catch {}
  }

  nodataError() {
    // console.log("No Data Error.");
  }

  handleAreaChange = (selectedOption) => {
    var picked = [];
    this.state.allTopic.forEach((curTopic) => {
      if (curTopic.k == selectedOption.value) {
        curTopic.v.forEach((curData) => {
          this.state.data.DATA.forEach((row) => {
            if (row[8] === curData) {
              picked.push(row);
            }
          });
        });
      }
    });
    // fill data
    var filled = [];
    picked.forEach((ele) => {
      let tmpLeg = String(ele[10]).trim();
      filled.push({ name: tmpLeg, value: ele[13] });
    });
    var pieColors = [
      "#d14a61",
      "#5793f3",
      "#54b936",
      "#bc7768",
      "#32C5E8",
      "#65E1E3",
      "#d14a61",
      "#5793f3",
      "#54b936",
      "#bc7768",
      "#32C5E8",
      "#65E1E3",
    ];
    SetChartCircle(selectedOption.label, filled, pieColors, "chart_census");
  };
}
