import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/About.css";

export class AboutUs extends Component {
  render() {
    return (
      <div>
        <p>
          <br />
          <strong>
            <span>About Us</span>
          </strong>
        </p>
        <p>
          <br />
          <span>
            Yiju is a leading real estate technology platform dedicated to
            empowering consumers with data, inspiration and knowledge around the
            place they call home, and connecting them with the best local
            professionals who can help.
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            Yiju focus on all stages of the home lifecycle: renting, buying,
            selling, financing and home improvement. In addition, Yiju Inc.
            develops a comprehensive suite of marketing software and technology
            solutions to help real estate, rental and mortgage professionals
            maximize business opportunities and connect with millions of
            consumers.
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            Within one click, home buyers can obtain an accurate automated home
            valuation for every listing within a second. Yiju algorithm also
            correctly identify similar nearby sold to help buyers to determine
            their final offer price!
          </span>
        </p>

        <div id="footer"></div>
      </div>
    );
  }
}
