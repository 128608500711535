import React from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { Base64 } from "js-base64";

import {
  datahost,
  def_site,
  shkyhost,
  gesture,
  pic_host,
  pic_link,
  DDF,
} from "../../config/Const.js";
import { priceSwitch, replaceAllFixed } from "../../function/Basic.js";
import { passParams } from "../../config/Connector.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "../../../style/Article.css";

export class Pre_S_L_Listings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      ac: null,
      listings: [],
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadList();
  }

  render() {
    return (
      <div>
        <div id="loginrequire" hidden={true}>
          <div className="loginrequire">
            <div className="hide_propertysec">
              <div className="inner_hidepropsec">
                <i className="fas fa-user-lock"></i>
                <p>{this.state.lang.loginrequire}</p>
              </div>
            </div>
            <div id="detail_history">
              <ul className="main_historysec"></ul>
            </div>
          </div>
        </div>
        <div id="contact">
          <div className="wrapper_margin">
            <div className="wrapper_margin">
              <Button
                id="pro-btn-mf"
                className="pull-right"
                onClick={() => this.shareMedia()}
              >
                <i className="fa fa-share"></i>
                {this.state.lang.share}
              </Button>
              <div className="shared_title" id="nTitle"></div>
              <br />
              <br />
              <div className="shared_content" id="nContent"></div>
            </div>
            <div class="main_headingsec topshare_sec">
              <h1>{this.state.lang.listingshpage}</h1>
            </div>

            <div className="detcontact_fieldsec condodetcont_sec">
              <div className="inputsel_iconsec">
                <i class="fas fa-address-book"></i>
                <input id="title"></input>
              </div>
              <div className="inputsel_iconsec">
                <i class="far fa-comments"></i>
                <textarea className="brief_textarea" id="brief"></textarea>
              </div>
            </div>

            <div>
              <div>
                <div id="listing_list">
                  <ul></ul>
                </div>
              </div>
            </div>
            <div id="expids" hidden={true}></div>
            <div id="footer"></div>
          </div>
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadList() {
    try {
      var url = document.location.toString();
      let paras = url.split("?").splice(1, 1)[0].split("=");
      let tk = paras[0];
      let lts = Base64.decode(paras[1]);
      this.setState({ tk: tk, ac: lts[0] });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param_tklvl = "\"{'tk':'" + tk + "'}\"";
      axios.post("/token/level", param_tklvl).then((res) => {
        if (res.data > 2) {
          let ltstr = lts.split(",");
          let mtstr = ltstr[0] + ";50;1;dF;0;=;" + ltstr[1];
          let param =
            "\"{'tk':'" +
            tk +
            "','mt':'" +
            mtstr +
            "','st':'" +
            def_site +
            "'}\"";
          axios.post("/search/list", param).then((res) => {
            this.setState({ listings: this.fillList(res.data) });
            this.createListingList(this.state.listings);
            // set pre-define title and brief
            var myDate = new Date();
            document.getElementById("title").value =
              myDate.toLocaleDateString() +
              " " +
              this.state.lang.featuredlistings +
              " " +
              this.state.listings.length;
            document.getElementById("brief").value =
              this.state.lang.featuredbrief;
          });
          document.getElementById("contact").hidden = false;
          document.getElementById("loginrequire").hidden = true;
        } else {
          document.getElementById("contact").hidden = true;
          document.getElementById("loginrequire").hidden = false;
        }
      });
    } catch {}
  }

  fillList = (data) => {
    // let str_0 = hostname + "/appdet?tk=";
    // let str_1 = "&ngw=";
    let str_0 = gesture + document.location.host + "/preshlistingdtl?";
    let str_1 = "=";
    let str_2 = "&ngw=";
    data.forEach((element) => {
      // fill detail link
      let ngw_0 = "940682259,";
      let ngw_1 = ",";
      let dt = "2021-01-08 12:25:08";
      let ngw_2 = ",";
      let ngw_str = ngw_0 + element.I + ngw_1 + dt + ngw_2 + element.O;
      var ngw = Base64.encode(ngw_str);
      ngw = ngw.replace("=", "~");
      element["link"] = str_0 + this.state.tk + str_1 + ngw;
      // fill picture link
      element["pic"] =
        gesture + pic_host + pic_link + this.state.tk + str_2 + ngw;
      // fix parking
      if (element["c1"] !== null) {
        element["b1"] += "+" + element["c1"];
      }
      // fix address
      element["c"] = replaceAllFixed(element["c"], ",", " ");
      element["c"] = replaceAllFixed(element["c"], "|", ",");
      // fix price showing
      if (element["h"] === "A" || element["g"] === null) {
        element["ag"] = priceSwitch(element["a"]);
      } else {
        element["ag"] = priceSwitch(element["g"]);
      }
      // sold/leased
      if (element["e"] === "Sale" || element["e"] === "S") {
        if (element["h"] === "A" || element["g"] === null) {
          element["e"] = this.state.lang.card_listing_sale;
        } else {
          element["e"] = this.state.lang.card_listing_sold;
          element["soldtag"] = require("../../../images/sold_stamp.png");
        }
      } else {
        if (element["h"] === "A" || element["g"] === null) {
          element["e"] = this.state.lang.card_listing_lease;
        } else {
          element["e"] = this.state.lang.card_listing_leased;
          element["soldtag"] = require("../../../images/leased_stamp.png");
        }
      }
    });
    return data;
  };

  createListingList(arr) {
    var st = document.getElementById("listing_list");
    if (arr !== null && arr !== []) {
      for (var i = 0; i < arr.length; i++) {
        st.children[0].innerHTML += this.buildListingElement(arr[i]);
      }
    }
  }

  buildListingElement(ele) {
    var result = "";
    result += "<div id=";
    result += "li" + ele.I;
    result += " key=";
    result += ele.I;
    result +=
      '><Button class="remove_crossec" onClick=(function(){document.getElementById("li';
    result += ele.I;
    result += '").remove();document.getElementById("expids").innerHTML+="';
    result += ele.I + ';"';
    result += "})()>&#10005; Remove</Button><div><a href=";
    result += ele.link;
    result +=
      '><div class="inner_listingsec"><div class="listing_imgsec"><Image src=';
    result += ele.pic;
    result += ' alt="" /></div><div class="listing_detsec"><h2>$ ';
    result += ele.ag;
    result += " </h2>";
    if (ele.soldtag != null) {
      result +=
        '<span class="sold_tag"><Image src="' +
        ele.soldtag +
        '"></Image></span>';
    }
    result += '<span><i class="fa fa-map-marker-alt"></i>';
    result += ele.c;
    result +=
      '</span><ul><li><i class="fa fa-bed"></i><p>' +
      this.state.lang.dtl_share_beds;
    if (ele.b1 === null) result += "N/A";
    else result += ele.b1;
    result +=
      '</p></li><li><i class="fa fa-bath"></i><p>' +
      this.state.lang.dtl_share_baths;
    if (ele.a1 === null) result += "N/A";
    else result += ele.a1;
    result +=
      '</p></li><li><i class="fa fa-car"></i><p>' +
      this.state.lang.dtl_share_parkings;
    if (ele.i2 === null) result += "N/A";
    else result += ele.i2;
    result += '</p></li></ul><div class="property_status"><button>';
    result += ele.e;
    result += "</button></div></div></div></div></div>";
    return result;
  }

  shareMedia() {
    let title = document.getElementById("title").value;
    let brief = document.getElementById("brief").value;
    let firstImage = datahost + "/shared/default.png";
    axios.defaults.baseURL = datahost;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    // get item exception
    let expids = document.getElementById("expids").innerText;
    var lts = "";
    for (var i = 0; i < this.state.listings.length; i++) {
      if (!expids.includes(this.state.listings[i].I)) {
        lts += this.state.listings[i].I + "_";
      }
    }
    // prepare bd params
    var so = DDF; // initialize Source
    if (this.state.listings !== null && this.state.listings.length > 0) {
      so = this.state.listings[0].S;
    }
    lts = lts.substr(0, lts.length - 1);
    lts = this.state.ac + "," + lts + "," + def_site + "," + so;

    if (lts.length < 7) {
      return;
    }
    // prepare encoding params
    var bdstr = Base64.encode(title) + ";";
    bdstr += Base64.encode(brief) + ";";
    bdstr += firstImage + ";";
    bdstr += lts;

    let param = "\"{'tk':'" + this.state.tk + "','bd':'" + bdstr + "'}\"";
    axios.post("/article/postlists", param).then((res) => {
      let sh = res.data;
      if (sh.indexOf(" ") > -1) {
        alert("Login Required.");
        return;
      }
      let url = shkyhost + "/shlistings?" + res.data;
      let str =
        url + ";" + title + ";" + brief + ";" + this.state.listings[0].pic;
      let params = Base64.encode(str);
      passParams("sharemedia", params);
      document.getElementById("pro-btn-mf").hidden = true;
      document.getElementById("title").disabled = true;
      document.getElementById("brief").disabled = true;
    });
  }
}
