export function createContact(usr) {
  document.getElementById("contact_name").innerText =
    usr.firstname + " " + usr.lastname;
  document.getElementById("contact_title").innerText = usr.title;
  document.getElementById("contact_company").innerText = usr.company;
  document.getElementById("contact_introduction").innerText = usr.introduction;

  document
    .getElementById("contact_message")
    .setAttribute("href", "sms:" + usr.phone);
  // document.getElementById("contact_message_txt").innerHTML = usr.phone;

  document
    .getElementById("contact_phone")
    .setAttribute("href", "tel:" + usr.phone);
  // document.getElementById("contact_phone_txt").innerHTML = usr.phone;

  document
    .getElementById("contact_email")
    .setAttribute("href", "mailto:" + usr.email);
  // document.getElementById("contact_email_txt").innerHTML = usr.email;

  document.getElementById("contact_hd").src = usr.headimage;
  document.getElementById("contact_qr").src = usr.qrimage;
}

export function getBrowserSys() {
  let client = "";
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    //判断iPhone|iPad|iPod|iOS
    client = 1; // "iOS";
  } else if (/(Android)/i.test(navigator.userAgent)) {
    //判断Android
    client = 2; // "Android";
  } else {
    client = 0; // "PC";
  }
  return client;
}
