import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import axios from "axios";
import { Base64 } from "js-base64";
import {
  datahost,
  shkyhost,
  gesture,
  pic_host,
  pic_condos0,
  pic_condos1,
} from "../../config/Const.js";
import { passParams } from "../../config/Connector.js";
import { replaceAllFixed, priceSwitch } from "../../function/Basic.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/CondoNews.css";

export class CondosDetail extends Component {
  state = {
    lang: enj,
    tk: null,
    condosData: null,
    images: [],
  };

  componentDidMount() {
    this.initLanguage();
    this.loadPage();
  }

  render() {
    let setting = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div id="newsDetail">
        <div>
          <Button id="pro-btn-mf" onClick={() => this.BackClick()}>
            <i className="fa fa-angle-left"></i> {this.state.lang.back}
          </Button>
          <Button
            id="pro-btn-mf"
            className="pull-right"
            onClick={() => this.shareMedia()}
          >
            <i className="fa fa-share"></i>
            {this.state.lang.share}
          </Button>
          <br />
        </div>
        <br />

        <div className="detail_banner_sec">
          <div className="slider single-item">
            <Slider {...setting}>
              {this.state.images.map(function (item) {
                return (
                  <div key={item.PicId}>
                    <div className="inner_bannersec">
                      <img src={item.Link} alt="" />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>

        <div className="common_infosec summary_tabsec">
          <ul>
            <li>
              <p>{this.state.lang["ncdo_proname"]}</p>
              <strong>
                <p id="pro-proname"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_prostatus"]}</p>
              <strong>
                <p id="pro-prostatus"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_startprice"]}</p>
              <strong>
                <p id="pro-startprice"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_developer"]}</p>
              <strong>
                <p id="pro-developer"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_type"]}</p>
              <strong>
                <p id="pro-type"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_unitsum"]}</p>
              <strong>
                <p id="pro-unitsum"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_buildingsize"]}</p>
              <strong>
                <p id="pro-buildingsize"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_occuyear"]}</p>
              <strong>
                <p id="pro-occuyear"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_intersection"]}</p>
              <strong>
                <p id="pro-intersection"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_address"]}</p>
              <strong>
                <p id="pro-address"></p>
              </strong>
            </li>
          </ul>
        </div>

        <div id="condosContent"></div>

        <div id="footer">
          <br />
          <br />
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadPage() {
    try {
      let url = document.location.toString();
      let paras = url.split("?")[1];
      let tk = paras.split("=")[0];
      let article = paras.split("=")[1];
      this.setState({ tk: tk, article: article });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'tk':'" + tk + "','id':'" + article + "'}\"";
      axios.post("/condos/project", param).then((res) => {
        this.setState({ condosData: res.data });
        this.setState({ images: this.fillCondosImages(res.data.Pics) });
        // fill image address in content
        var content = res.data.ProFeatures;
        if (content != undefined) {
          content = replaceAllFixed(
            content,
            "/aprg",
            gesture + pic_host + "/aprg"
          );

          content = replaceAllFixed(
            content,
            "/eadf26jst",
            gesture + pic_host + "/eadf26jst"
          );
          document.getElementById("condosContent").innerHTML = content;
        }
        // fill form
        document.getElementById("pro-proname").innerText = res.data.ProName;
        document.getElementById("pro-prostatus").innerText =
          res.data.ProjectStatus;
        document.getElementById("pro-startprice").innerText = priceSwitch(
          res.data.PriceStart
        );
        document.getElementById("pro-developer").innerText =
          res.data.BuilderName;
        document.getElementById("pro-type").innerText = res.data.CondoType;
        document.getElementById("pro-unitsum").innerText =
          res.data.NumberOfUnits;
        document.getElementById("pro-buildingsize").innerText =
          res.data.BuildingSize;
        document.getElementById("pro-occuyear").innerText = res.data.OccuYear;
        document.getElementById("pro-intersection").innerText =
          res.data.MainIntersection;
        document.getElementById("pro-address").innerText =
          res.data.ProLocation +
          ", " +
          res.data.City +
          ", " +
          res.data.Province;
      });
    } catch {}
  }

  fillCondosImages = (data) => {
    if (data === undefined) {
      return;
    }
    var imgs = [];
    data.forEach((element) => {
      let ppm = element.FileName.split(",");
      element["Link"] =
        gesture + pic_host + pic_condos0 + ppm[0] + pic_condos1 + ppm[1];
      imgs.push(element);
    });
    return imgs;
  };

  shareMedia() {
    axios.defaults.baseURL = datahost;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    let param =
      "\"{'tk':'" +
      this.state.tk +
      "','id':'" +
      this.state.condosData.ProId +
      "','hd':'1'}\"";
    axios.post("/share/token", param).then((res) => {
      let url = shkyhost + "/yiju/shcondos?" + res.data;
      let md = this.state.condosData;
      if (md.ProBrief === null) {
        md.ProBrief = md.ProName;
      }
      let pm = md.Thumbnail.split(",");
      let image =
        gesture + pic_host + pic_condos0 + pm[0] + pic_condos1 + pm[1];
      let str = url + ";" + md.ProName + ";" + md.ProBrief + ";" + image;
      let params = Base64.encode(str);
      passParams("sharemedia", params);
    });
  }

  BackClick() {
    window.history.go(-1);
  }
}
