import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import axios from "axios";
import { Base64 } from "js-base64";
import { passParams } from "../../config/Connector.js";
import {
  gesture,
  datahost,
  pic_host,
  pic_condos0,
  pic_condos1,
} from "../../config/Const.js";
import { replaceAllFixed, priceSwitch } from "../../function/Basic.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/CondoNews.css";

export class CondosDetailShNt extends Component {
  state = {
    lang: enj,
    url: null,
    tk: null,
    condo: null,
    usr: null,
    images: [],
  };

  componentDidMount() {
    this.initLanguage();
    this.loadPage();
  }

  render() {
    let setting = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div id="newsDetail">
        <div className="detail_banner_sec">
          <div className="slider single-item">
            <Slider {...setting}>
              {this.state.images.map(function (item) {
                return (
                  <div key={item.picId}>
                    <div className="inner_bannersec">
                      <img src={item.Link} alt="" />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>

        <div>
          <Button
            id="pro-btn-mf"
            className="pull-right"
            onClick={() => this.shareMedia()}
          >
            <i className="fa fa-share"></i>
            {this.state.lang.share}
          </Button>
        </div>

        <div className="common_infosec summary_tabsec">
          <ul>
            <li>
              <p>{this.state.lang["ncdo_proname"]}</p>
              <strong>
                <p id="pro-proname"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_prostatus"]}</p>
              <strong>
                <p id="pro-prostatus"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_startprice"]}</p>
              <strong>
                <p id="pro-startprice"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_developer"]}</p>
              <strong>
                <p id="pro-developer"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_type"]}</p>
              <strong>
                <p id="pro-type"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_unitsum"]}</p>
              <strong>
                <p id="pro-unitsum"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_buildingsize"]}</p>
              <strong>
                <p id="pro-buildingsize"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_occuyear"]}</p>
              <strong>
                <p id="pro-occuyear"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_intersection"]}</p>
              <strong>
                <p id="pro-intersection"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_address"]}</p>
              <strong>
                <p id="pro-address"></p>
              </strong>
            </li>
          </ul>
        </div>

        <div id="condosContent"></div>

        <div id="footer">
          <br />
          <br />
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadPage() {
    try {
      var url = document.location.toString();
      if (url.indexOf("=")) {
        url = url.split("=")[0];
      }
      this.setState({ url: url });
      var sh = url.split("?").splice(1, 1);
      this.setState({ sh: sh });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'sh':'" + sh + "'}\"";
      axios.post("/share/condos", param).then((res) => {
        this.setState({ condo: res.data.data, usr: res.data.usr });
        // fill image address in content
        this.setState({ images: this.fillCondosImages(this.state.condo.pics) });
        // fill image address in content
        var content = this.state.condo.proFeatures;
        if (content !== undefined) {
          content = replaceAllFixed(
            content,
            "/aprg",
            gesture + pic_host + "/aprg"
          );
          content = replaceAllFixed(
            content,
            "/eadf26jst",
            gesture + pic_host + "/eadf26jst"
          );
          document.getElementById("condosContent").innerHTML = content;
        }
        // fill form
        document.getElementById("pro-proname").innerText =
          this.state.condo.proName;
        document.getElementById("pro-prostatus").innerText =
          this.state.condo.projectStatus;
        document.getElementById("pro-startprice").innerText = priceSwitch(
          this.state.condo.priceStart
        );
        document.getElementById("pro-developer").innerText =
          this.state.condo.builderName;
        document.getElementById("pro-type").innerText =
          this.state.condo.condoType;
        document.getElementById("pro-unitsum").innerText =
          this.state.condo.numberOfUnits;
        document.getElementById("pro-buildingsize").innerText =
          this.state.condo.buildingSize;
        document.getElementById("pro-occuyear").innerText =
          this.state.condo.occuYear;
        document.getElementById("pro-intersection").innerText =
          this.state.condo.mainIntersection;
        document.getElementById("pro-address").innerText =
          this.state.condo.proLocation +
          ", " +
          this.state.condo.city +
          ", " +
          this.state.condo.province;
      });
    } catch {}
  }

  fillCondosImages = (data) => {
    var imgs = [];
    data.forEach((element) => {
      let ppm = element.fileName.split(",");
      element["Link"] =
        gesture + pic_host + pic_condos0 + ppm[0] + pic_condos1 + ppm[1];
      imgs.push(element);
    });
    return imgs;
  };

  shareMedia() {
    let str =
      this.state.url +
      ";" +
      this.state.condo.proName +
      ";" +
      this.state.condo.proBrief +
      ";" +
      this.state.condo.pics[0].Link;
    let params = Base64.encode(str);
    passParams("copySharing", params);
  }
}
