import React, { Component } from "react";
import { Button, Image } from "react-bootstrap";
// import ReactDOM from 'react-dom';
import axios from "axios";
import Slider from "react-slick";
import {
  datahost,
  gesture,
  def_site,
  pic_host,
  pic_link,
  pic_condos0,
  pic_condos1,
} from "../config/Const.js";
import { passParams } from "../config/Connector.js";
import { priceSwitch, replaceAllFixed } from "../function/Basic.js";

import enj from "../language/en.json";
import zhj from "../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../style/AppHome.css";

export class AppHome extends Component {
  static displayName = AppHome.name;

  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      lg: null,
      news: [],
      featureList: [],
      newCondos: [],
      rentalList: [],
      paraHelp: [], // help See All Jump
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadData();
  }

  render() {
    let setting0 = {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let setting1 = {
      dots: false,
      infinite: true,
      speed: 300,
      // lazyLoad: true,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
    };

    return (
      <div className="wrapper">
        <div className="wrapper_margin">
          <div className="property_searchsec">
            <div className="main_searchsec">
              <img src={require("../../images/search_icon.png")} alt="" />
              <input
                type="text"
                className=""
                name=""
                placeholder={this.state.lang.home_searchbar}
                id="searchbar"
              />
            </div>
            <div className="main_selectsec">
              <select>
                <option>Toronto</option>
                {/* <option>Vancouver</option>
                <option>Calgary</option> */}
              </select>
            </div>
            {/* main_selectsec */}
            <div className="map_searchsec">
              <Button
                onClick={() =>
                  passParams(
                    "searchmls",
                    document.getElementById("searchbar").value
                  )
                }
              >
                <i className="fa fa-map-marked-alt"></i>
              </Button>
            </div>
            {/* map_search */}
          </div>
          {/* property_searchsec */}

          <div className="banner_sec section_margin">
            <div className="slider single-item">
              <Slider {...setting0}>
                <div className="inner_bannersec">
                  <img id="home_banner0" />
                </div>
              </Slider>
            </div>
            {/* slider */}
          </div>
          {/* banner_sec */}

          <div className="main_categories_sec section_margin">
            <div className="categorydet_sec">
              <ul>
                <li>
                  <Button onClick={() => passParams("listings", "")}>
                    <div className="apphome_imgsec">
                      <img
                        src={require("../../images/icons8-home.svg")}
                        alt=""
                      />
                    </div>
                    <p>{this.state.lang.home_listings}</p>
                  </Button>
                </li>
                <li>
                  {/* <a href={gesture + yijuhost + "/condo"}> */}
                  {/* <Button onClick={() => passParams("condos", "")}> */}
                  <Button
                    onClick={() =>
                      this.props.history.push("/condoslist?" + this.state.tk)
                    }
                  >
                    <div className="apphome_imgsec">
                      <img src={require("../../images/condo.svg")} alt="" />
                    </div>
                    <p>{this.state.lang.home_newcondos}</p>
                  </Button>
                </li>
                <li>
                  <Button
                    onClick={() =>
                      this.props.history.push("/agentlist?" + this.state.tk)
                    }
                  >
                    <div className="apphome_imgsec">
                      <img src={require("../../images/agent.svg")} alt="" />
                    </div>
                    <p>{this.state.lang.home_agents}</p>
                  </Button>
                </li>
                {/* <li><a href="#"><Image src={require('./../images/assignment.png').default} alt=""/> <p>Assignment</p></a></li> */}
                <li>
                  <Button onClick={() => passParams("pricedrop", "")}>
                    <div className="apphome_imgsec">
                      <img
                        src={require("../../images/price_drop.svg")}
                        alt=""
                      />
                    </div>
                    <p>{this.state.lang.home_pricedrop}</p>
                  </Button>
                </li>
                {/* <li><a href="#"><Image src={require('./../images/openhouse.png').default} alt=""/> <p>Open House</p></a></li> */}
                {/* <li><a href="#"><Image src={require('./../images/arshow.png').default} alt=""/> <p>AR Showing</p></a></li> */}
                {/* <li><a href="#"><Image src={require('./../images/growhouse.png').default} alt=""/> <p>Grow House</p></a></li> */}
                <li>
                  <Button onClick={() => passParams("soldhistory", "")}>
                    <div className="apphome_imgsec">
                      <img src={require("../../images/home_sold.svg")} alt="" />
                    </div>
                    <p>{this.state.lang.home_soldhistory}</p>
                  </Button>
                </li>
              </ul>

              {/* <ul>
                <li>
                  <Button onClick={() => passParams("rent", "")}>
                    <div className="apphome_imgsec">
                      <img
                        src={require("../../images/icons8-rent.svg")}
                        alt=""
                      />
                    </div>
                    <p>{this.state.lang.home_rent}</p>
                  </Button>
                </li>
                <li>
                  <Button
                    onClick={() =>
                      this.props.history.push("/developerlist?" + this.state.tk)
                    }
                  >
                    <div className="apphome_imgsec">
                      <img src={require("../../images/developer.svg")} alt="" />
                    </div>
                    <p>{this.state.lang.home_developers}</p>
                  </Button>
                </li>
                <li>
                  <Button
                    onClick={() =>
                      this.props.history.push(
                        "/calculator?" + this.state.tk + "=mortcal"
                      )
                    }
                  >
                    <div className="apphome_imgsec">
                      <img
                        src={require("../../images/calculator.svg")}
                        alt=""
                      />
                    </div>
                    <p>{this.state.lang.home_mortgage}</p>
                  </Button>
                </li>
                <li>
                  <Button
                    onClick={() =>
                      this.props.history.push(
                        "/calculator?" + this.state.tk + "=lttcal"
                      )
                    }
                  >
                    <div className="apphome_imgsec">
                      <img
                        src={require("../../images/land_transfer.svg")}
                        alt=""
                      />
                    </div>
                    <p>{this.state.lang.home_landtax}</p>
                  </Button>
                </li>
                <li>
                  <Button
                    onClick={() =>
                      this.props.history.push(
                        "/calculator?" + this.state.tk + "=insurcal"
                      )
                    }
                  >
                    <div className="apphome_imgsec">
                      <img
                        src={require("../../images/mortgage_insurance.svg")}
                        alt=""
                      />
                    </div>
                    <p>{this.state.lang.home_insurance}</p>
                  </Button>
                </li>
              </ul> */}

              {/* <ul>
                <li>
                  <a
                    href={
                      gesture +
                      yijuhost +
                      "/aprg/sellers/evaluation.aspx?sid=188888"
                    }
                  >
                    <img
                      src={require("../../images/proevlaution.png")}
                      alt=""
                    />
                    <p>Evaluation</p>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      gesture + yijuhost + "/aprg/list/mortcal.aspx?sid=188888"
                    }
                  >
                    <img
                      src={require("../../images/calculator.png")}
                      alt=""
                    />
                    <p>Calculators</p>
                  </a>
                </li>
                <li>
                  <a href={gesture + yijuhost + "/agent"}>
                    <img
                      src={require("../../images/agent.png")}
                      alt=""
                    />
                    <p>Agents</p>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      gesture + yijuhost + "/aprg/schoolinfo.aspx?sid=188888"
                    }
                  >
                    <img
                      src={require("../../images/school.png")}
                      alt=""
                    />
                    <p>School</p>
                  </a>
                </li> */}
              {/* <li><a href="#"><Image src={require('./../images/subway.png')} alt=""/> <p>Search By Subway</p></a></li> */}
              {/* <li>
                  <a href={gesture + yijuhost + "/commercial"}>
                    <img
                      src={require("../../images/commerical.png")}
                      alt=""
                    />
                    <p>Commerical</p>
                  </a>
                </li>
              </ul> */}
            </div>
            {/* categorydet_sec */}
          </div>
          {/* main_categories_sec */}

          <div className="marketrend_sec section_margin">
            <Button onClick={() => passParams("marketreport")}>
              <h1>{this.state.lang.home_report}</h1>
              <p>{this.state.lang.home_reportdes}</p>
              <Image src={require("../../images/toronto.png")} alt="" />
            </Button>
          </div>
          {/* marketrend_sec */}

          <div className="news_sec section_margin">
            <div className="main_headingsec">
              <h1>{this.state.lang.home_news}</h1>
              <p>{this.state.lang.home_newsdes}</p>
            </div>
            {/* main_headingsec */}
            <div className="news_wrappersec">
              <div className="slider variable-width">
                <Slider {...setting1}>
                  {this.state.news.map(function (item) {
                    return (
                      <div key={item.ArticleId}>
                        <a href={item.Link}>
                          <div className="newsdetail_sec">
                            <div className="newsimg_sec">
                              <Image src={item.Image} alt="" />
                            </div>
                            {/* newsimg_sec */}
                            <div className="news_contsec">
                              <h2>{item.Title}</h2>
                              {/* <p>{item.Brief}</p> */}
                            </div>
                            {/* news_contsec */}
                          </div>
                        </a>
                        {/* newsdetail_sec */}
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {/* slider */}
            </div>
            {/* innernews_sec */}
          </div>
          {/* news_sec */}

          <div className="update_secs section_margin">
            <div className="innerupdate_sec">
              <a
                href={
                  gesture +
                  document.location.host +
                  "/newslist?" +
                  this.state.tk +
                  "=0"
                }
              >
                <img src={require("../../images/real_estate.png")} alt="" />
                <h2>{this.state.lang.home_realestatenews}</h2>
              </a>
            </div>
            {/* innerupdate_sec */}
            <div className="innerupdate_sec">
              <a
                href={
                  gesture +
                  document.location.host +
                  "/newslist?" +
                  this.state.tk +
                  "=1"
                }
              >
                <img src={require("../../images/canada_news.png")} alt="" />
                <h2>{this.state.lang.home_canadanews}</h2>
              </a>
            </div>
            {/* innerupdate_sec */}
            <div className="innerupdate_sec">
              <a
                href={
                  gesture +
                  document.location.host +
                  "/newslist?" +
                  this.state.tk +
                  "=2"
                }
              >
                <Image src={require("../../images/columns.png")} alt="" />
                <h2>{this.state.lang.home_columns}</h2>
              </a>
            </div>
            {/* innerupdate_sec */}
          </div>
          {/* update_secs */}

          <div className="listings_sec section_margin">
            <div className="main_headingsec">
              <h1>{this.state.lang.home_featured}</h1>
              <p>{this.state.lang.home_featureddes}</p>
              {/* <Button
                onClick={this.handleBtnClick.bind(this, this.state.paraHelp[4])}
              > */}
              <a href={this.state.paraHelp[4]}>
                {this.state.lang.seeall}
                <i className="fa fa-angle-double-right"></i>
              </a>
              {/* </Button> */}
            </div>
            {/* main_headingsec */}

            <div className="listing_wrappersec">
              <div className="slider variable-width">
                <Slider {...setting1}>
                  {this.state.featureList.map(function (item) {
                    return (
                      <div key={item.I}>
                        <div hidden={!item.V}>
                          <div className="inner_listingsec">
                            <div className="loginrequired_sec">
                              <p>{item.lgrq1}</p>
                              <button
                                type="button"
                                className="common_mainbtn btn btn-primary"
                              >
                                {item.lgrq0}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div hidden={item.V}>
                          <a href={item.link}>
                            <div className="inner_listingsec">
                              <div className="listing_imgsec">
                                <Image src={item.pic} alt="" />
                              </div>
                              {/* listing_imgsec */}
                              <div className="listing_detsec">
                                <h2>${item.a}</h2>
                                <span className="flex-container">
                                  <i className="fa fa-map-marker-alt"></i>
                                  <span className="eclipse-2">{item.c}</span>
                                </span>
                                <p className="eclipse-2 listing_city">
                                  {
                                    item.c.split(",")[
                                      item.c.split(",").length - 1
                                    ]
                                  }
                                </p>
                                <ul>
                                  <li>
                                    <i className="fa fa-bed"></i>
                                    <p>{item.b1.split(":")[1]}</p>
                                  </li>
                                  <li>
                                    <i className="fa fa-bath"></i>
                                    <p>{item.a1.split(":")[1]}</p>
                                  </li>
                                  <li>
                                    <i className="fa fa-car"></i>
                                    <p>{item.i2.split(":")[1]}</p>
                                  </li>
                                </ul>
                                <div className="property_status">
                                  <button>{item.e}</button>
                                </div>
                                {/* forsale */}
                              </div>
                              {/* listing_detsec */}
                            </div>
                            {/* inner_listingsec */}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {/* slider */}
            </div>
            {/* listing_wrappersec */}
          </div>
          {/* listings_sec */}

          <div className="listings_sec section_margin">
            <div className="main_headingsec">
              <h1>{this.state.lang.home_newcondos}</h1>
              <p>{this.state.lang.home_newcondosdes}</p>
              {/* <Button
                onClick={this.handleBtnClick.bind(this, this.state.paraHelp[6])}
              > */}
              <a href={this.state.paraHelp[6]}>
                {this.state.lang.seeall}
                <i className="fa fa-angle-double-right"></i>
              </a>
              {/* </Button> */}
            </div>
            {/* main_headingsec */}
            <div className="listing_wrappersec">
              <div className="slider variable-width">
                <Slider {...setting1}>
                  {this.state.newCondos.map(function (item) {
                    return (
                      <div key={item.ProId}>
                        <a href={item.Link}>
                          <div className="inner_listingsec">
                            <div className="listing_imgsec">
                              <Image src={item.Thumbnail} alt=""></Image>
                            </div>
                            <div className="listing_detsec">
                              <h2 style={{ minHeight: "37px" }}>
                                {item.ProName}
                              </h2>
                              <span>
                                {/* <i className="fa fa-map-marker-alt"></i> */}
                                {item.LocationView}
                                <div className="developer_compsec">
                                  {item.PriceStartView}
                                </div>
                                <div className="developer_compsec">
                                  {item.OccuYearView}
                                </div>
                              </span>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {/* slider */}
            </div>
            {/* listing_wrappersec */}
          </div>
          {/* listings_sec */}

          <div className="listings_sec section_margin">
            <div className="main_headingsec">
              <h1>{this.state.lang.home_rental}</h1>
              <p>{this.state.lang.home_rentaldes}</p>
              {/* <Button
                onClick={this.handleBtnClick.bind(this, this.state.paraHelp[5])}
              > */}
              <a href={this.state.paraHelp[5]}>
                {this.state.lang.seeall}
                <i className="fa fa-angle-double-right"></i>
              </a>
              {/* </Button> */}
            </div>
            {/* main_headingsec */}
            <div className="listing_wrappersec">
              <div className="slider variable-width">
                <Slider {...setting1}>
                  {this.state.rentalList.map(function (item) {
                    return (
                      <div key={item.I}>
                        <div hidden={!item.V}>
                          <div className="inner_listingsec">
                            <div className="loginrequired_sec">
                              <p>{item.lgrq1}</p>
                              <button
                                type="button"
                                className="common_mainbtn btn btn-primary"
                              >
                                {item.lgrq0}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div hidden={item.V}>
                          <a href={item.link}>
                            <div className="inner_listingsec">
                              <div className="listing_imgsec">
                                <Image src={item.pic} alt="" />
                              </div>
                              {/* listing_imgsec */}
                              <div className="listing_detsec">
                                <h2>${item.a}</h2>
                                <span className="flex-container">
                                  <i className="fa fa-map-marker-alt"></i>
                                  <span className="eclipse-2">{item.c}</span>
                                </span>
                                <p className="eclipse-2 listing_city">
                                  {
                                    item.c.split(",")[
                                      item.c.split(",").length - 1
                                    ]
                                  }
                                </p>
                                <ul>
                                  <li>
                                    <i className="fa fa-bed"></i>
                                    <p>{item.b1.split(":")[1]}</p>
                                  </li>
                                  <li>
                                    <i className="fa fa-bath"></i>
                                    <p>{item.a1.split(":")[1]}</p>
                                  </li>
                                  <li>
                                    <i className="fa fa-car"></i>
                                    <p>{item.i2.split(":")[1]}</p>
                                  </li>
                                </ul>
                                <div className="property_status">
                                  <button>{item.e}</button>
                                </div>
                                {/* forsale */}
                              </div>
                              {/* listing_detsec */}
                            </div>
                            {/* inner_listingsec */}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {/* slider */}
            </div>
            {/* main_headingsec */}
          </div>

          <div className="outer_mainagentdet_sec"></div>
        </div>
        {/* wrapper_margin */}

        <div id="footer"></div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadData() {
    try {
      var url = document.location.toString();
      let paras = url.split("?").splice(1, 1)[0].split("#")[0].split("=");
      if (paras.length < 1) {
        return;
      }
      let tk = paras[0];
      this.setState({ tk: tk });

      // const createAxios = axios.create({
      //   baseURL: "api.yjezimoc.com",
      //   heasers: {
      //     "Content-Type": "application/json",
      //   },
      // });
      // console.log("START");
      // createAxios
      //   .post("/token/level", "\"{'tk':'" + tk + "'}\"")
      //   .then((res) => {
      //     console.log(res);
      //   });
      // console.log("END");

      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      // token level
      let param_tklvl = "\"{'tk':'" + tk + "'}\"";
      axios.post("/token/level", param_tklvl).then((res) => {
        // set cover fill
        if (res.data > 2) {
          this.setState({ lg: res.data });
        } else {
          this.setState({ lg: 0 });
        }

        // set banner
        document.getElementById("home_banner0").src = require("../../images/" +
          this.state.lang.res_home_banner0);

        // fill SeeAll links
        this.completeLinks(this.state.tk, res.data);

        // news
        let param_news = "\"{'tp':'0;20;1','tk':'" + tk + "'}\"";
        axios.post("/article/yijunews", param_news).then((res) => {
          this.setState({ news: this.fillNews(res.data) });
        });

        // featured listing
        let param_feature =
          "\"{'mt':'" +
          this.state.paraHelp[1] +
          "','tk':'" +
          tk +
          "','st':'" +
          def_site +
          "'}\"";
        axios.post("/search/list", param_feature).then((res) => {
          this.setState({ featureList: this.fillList(res.data) });
        });

        // new condos
        let param_condo = this.state.paraHelp[3];
        axios.post("/condos/list", param_condo).then((res) => {
          this.setState({ newCondos: this.fillCondo(res.data) });
        });

        // rental listing
        let param_rental =
          "\"{'mt':'" +
          this.state.paraHelp[2] +
          "','tk':'" +
          tk +
          "','st':'" +
          def_site +
          "'}\"";
        axios.post("/search/list", param_rental).then((res) => {
          this.setState({ rentalList: this.fillList(res.data) });
        });
      });
    } catch {}
  }

  fillList = (data) => {
    let str_0 = gesture + document.location.host + "/listing?";
    let str_1 = "=";
    let str_2 = "&ngw=";
    data.forEach((element) => {
      // fill detail link
      let ngw_0 = "940682259,";
      let ngw_1 = ",";
      let dt = "2021-01-08 12:25:08";
      let ngw_2 = ",";
      let ngw_str = ngw_0 + element.I + ngw_1 + dt + ngw_2 + element.O;
      var ngw = window.btoa(ngw_str);
      ngw = replaceAllFixed(ngw, "=", "~");
      element["link"] =
        str_0 + this.state.tk + str_1 + ngw + str_1 + this.state.lg;
      // fill picture link
      element["pic"] =
        gesture + pic_host + pic_link + this.state.tk + str_2 + ngw;
      // fix parking
      if (element["c1"] == null) {
        element["c1"] = 0;
      }
      // fix address
      element["c"] = replaceAllFixed(element["c"], ",", " ");
      element["c"] = replaceAllFixed(element["c"], "|", ",");
      // fix price showing
      element["a"] = priceSwitch(element["a"]);
      // fix visible tag
      if (this.state.lg > 2) {
        element.V = false;
      } else {
        if (element.V === false) {
          element.V = false;
        } else {
          element.V = true;
          // login require cover
          element["lgrq1"] = this.state.lang.loginrequire1;
          element["lgrq0"] = this.state.lang.loginrequire0;
        }
      }
      // add Language Tag
      if (element["b1"] === null) element["b1"] = "N/A";
      element["b1"] = this.state.lang.card_listing_bed + element["b1"];
      if (element["c1"] !== null && element["c1"] !== 0) {
        element["b1"] += "+" + element["c1"];
      }
      if (element["a1"] === null) element["a1"] = "N/A";
      element["a1"] = this.state.lang.card_listing_bath + element["a1"];
      if (element["i2"] === null) element["i2"] = "N/A";
      element["i2"] = this.state.lang.card_listing_parking + element["i2"];
      if (element["e"] === "Sale" || element["e"] === "S") {
        element["e"] = this.state.lang.card_listing_sale;
      } else {
        element["e"] = this.state.lang.card_listing_lease;
      }
    });
    return data;
  };

  fillCondo = (data) => {
    let str_0 = gesture + document.location.host + "/condosdetail?";
    let str_1 = "=";
    data.forEach((element) => {
      // build and fill link
      element["Link"] = str_0 + this.state.tk + str_1 + element.ProId;
      let ppm = element.Thumbnail.split(",");
      element["Thumbnail"] =
        gesture + pic_host + pic_condos0 + ppm[0] + pic_condos1 + ppm[1];
      element["LocationView"] =
        this.state.lang.card_newcondo_location + element.Address;
      if (element.PriceStart === null) {
        element["PriceStartView"] =
          this.state.lang.card_newcondo_pricestart + "N/A";
      } else {
        element["PriceStartView"] =
          this.state.lang.card_newcondo_pricestart +
          priceSwitch(element.PriceStart);
      }
      if (element.OccuYear === null) {
        element["OccuYearView"] =
          this.state.lang.card_newcondo_occuyear + "N/A";
      } else {
        element["OccuYearView"] =
          this.state.lang.card_newcondo_occuyear + element.OccuYear;
      }
    });
    return data;
  };

  fillNews = (data) => {
    let str_0 = gesture + document.location.host + "/newsdetail?";
    let str_1 = "=";
    data.forEach((element) => {
      // build and fill link
      element["Link"] = str_0 + this.state.tk + str_1 + element.ArticleId;
    });
    return data;
  };

  // handleBtnClick(path) {
  //   this.props.history.push(path);
  // }

  completeLinks(tk, lg) {
    let url0 = gesture + document.location.host;
    let url1 = "=";
    // [0] : BACKUP: Token
    this.state.paraHelp.push(tk);
    // [1] : MATCH: Featured Listings
    //this.state.paraHelp.push( "a;25;1;dF;u4,E,j,v7,A,x3;!,=,=,=,>,~;COM,S,1,Toronto,1000000,1|2|3");
    this.state.paraHelp.push(
      "a;25;1;dF;u4,E,j,v7,A;!,=,=,=,>;COM,S,1,Toronto,1000000"
    );
    // [2] : MATCH -> Rental Listings
    this.state.paraHelp.push("a;25;1;dF;u4,E,j,v7;!,=,=,=;COM,L,1,Toronto");
    // [3] : MATCH -> New Condos
    this.state.paraHelp.push(
      "\"{'tk':'" + tk + "','st':'" + def_site + "','ct':'1132','pg':'10;1'}\""
    );
    // [4] : URL -> Featured Listings
    this.state.paraHelp.push(
      url0 +
        "/listings?" +
        tk +
        url1 +
        replaceAllFixed(window.btoa(this.state.paraHelp[1]), "=", "") +
        "=" +
        lg
    );
    // [5] : URL -> Rental Listings
    this.state.paraHelp.push(
      url0 +
        "/listings?" +
        tk +
        url1 +
        replaceAllFixed(window.btoa(this.state.paraHelp[2]), "==", "") +
        "=" +
        lg
    );
    // [6] : URL -> New Condos
    this.state.paraHelp.push(url0 + "/condoslist?" + tk);
  }
}
