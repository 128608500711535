import React, { Component } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { datahost } from "../../config/Const.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";

export class Calculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      cal: null,
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadDetail();
  }

  render() {
    return (
      <div className="wrapper">
        <div className="wrapper_margin">
          <div>
            <Button id="pro-btn-mf" onClick={() => this.BackClick()}>
              <i className="fa fa-angle-left"></i>
              {this.state.lang.back}
            </Button>
            <br />
            <br />
          </div>

          <iframe
            id="iframe_calculator"
            src=""
            style={{
              width: "100%",
              height: document.body.clientWidth * 1.59,
            }}
            frameBorder="no"
            border="0"
            marginWidth="0"
            marginHeight="0"
            scrolling="no"
            allowtransparency="yes"
            position="relative"
            overflow="auto"
          />

          <div id="footer">
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadDetail() {
    try {
      var url = document.location.toString();
      let paras = url.split("?").splice(1, 1)[0].split("=");
      if (paras.length < 2) {
        return;
      }
      let tk = paras[0];
      let cal = paras[1];
      this.setState({ tk: tk, cal: cal }); // save token

      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let tkck = "\"{'tk':'" + tk + "'}\"";
      axios.post("/token/check", tkck).then((res) => {
        if (res.data === false) {
          return;
        }
        let cal = document.getElementById("iframe_calculator");
        let para = this.state.cal;
        if (para === "lttcal") {
          cal.src =
            "https://yiju.ca/eziapp/lttcal.aspx?ngw=yiju&sid=" +
            this.state.lang.site;
        } else if (para === "insurcal") {
          cal.src =
            "https://yiju.ca/eziapp/insurcal.aspx?ngw=yiju&sid=" +
            this.state.lang.site;
        } else {
          // if (para === "mortcal")
          cal.src =
            "https://yiju.ca/eziapp/mortcal.aspx?ngw=yiju&sid=" +
            this.state.lang.site;
        }
      });
    } catch {}
  }

  BackClick() {
    window.history.go(-1);
  }
}
