import React, { Component } from "react";
import axios from "axios";
import { Base64 } from "js-base64";
import { SetChartLineGrown, SetChartBarGrown } from "../function/Chart.js";
import { datahost, shkyhost } from "../config/Const.js";

import enj from "../language/en.json";
import zhj from "../language/zh.json";
import colors from "../data/colors.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../style/AppMarket.css";
import { extend } from "jquery";
import { isEnumDeclaration } from "typescript";

export class DemoCommunity extends Component {
  static displayName = DemoCommunity.name;

  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      reports: [], // data array
      distrib: null, // price distribution data
      loaded: false,
      tk: null,
      bk: null, // block {city, ,community}
      ls: null, // lease / sale
      tp: null, // listing type {detached, semi-, town, condo, other, All}
      st: null, // start month
      ed: null, // end month
      cities: null, // for echart parameters
      communities: null, // for echart parameters
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadPage();
  }

  render() {
    return (
      <div>
        <div
          className="ChartMonthly"
          style={{
            marginLeft: "0.5rem",
            width: document.body.clientWidth - 16,
          }}
        >
          <hr />
          <div
            id="chart_area0"
            style={{ height: document.body.clientWidth * 1 }}
          ></div>
          <hr />
          <div
            id="chart_area1"
            style={{ height: document.body.clientWidth * 1 }}
          ></div>
        </div>
        <div id="footer"></div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadPage() {
    try {
      var url = document.location.toString();
      let paras = url.split("?").splice(1, 1)[0].split("#")[0].split("=");

      if (paras.length < 5) {
        return;
      }

      var tk = paras[0];
      var itp = paras[1];
      var icd = paras[2];
      var ils = paras[3];
      var ist = paras[4];
      var ied = paras[5];
      // var lines = [
      //   "Toronto",
      //   "Mississauga",
      //   "Brampton",
      //   "Markham",
      //   "Aurora",
      //   "Ajax",
      //   "Oshawa",
      //   "Newmarket",
      //   "Richmond Hill",
      //   "Vaughan",
      //   "Milton",
      //   "Burlington",
      //   "Oakville",
      // ];

      var lines = [
        "Mississauga Valleys",
        "Erin Mills",
        "Lorne Park",
        "Erindale",
        "Hurontario",
        "Meadowvale",
        "Lakeview",
        "Malton",
        "City Centre",
        "Applewood",
        "Rathwood",
      ];

      var tp = itp; //"6";
      var cd = icd; //"SoldAveragePrice";
      var ls = ils; //"S";
      var st = ist; //"2017-01-01";
      var ed = ied; //"2021-11-01";

      this.setState({
        tk: tk,
        bks: lines,
        ls: ls,
        tp: tp,
        st: st,
        ed: ed,
      });

      this.refreshChart(tk, lines, colors, tp, cd, ls, st, ed);
    } catch {}
  }

  refreshChart(tk, lines, colors, tp, cd, ls, st, ed) {
    var blocks = "";
    lines.forEach((element) => {
      // blocks += element + ",;";
      blocks += "," + element + ";";
    });
    blocks = blocks.substr(0, blocks.length - 1);
    // make a post request
    axios.defaults.baseURL = datahost;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    let param =
      "\"{'tk':'" +
      tk +
      "','id':'" +
      blocks +
      "','tp':'" +
      tp +
      "','cd':'" +
      cd +
      "','ls':'" +
      ls +
      "','st':'" +
      st +
      "','ed':'" +
      ed +
      "'}\"";
    axios.post("/detail/areareport", param).then((res) => {
      var mydt = [];
      var hd = ["Value", "Area", "Month"];
      mydt.push(hd);
      // build data table
      res.data.forEach((element) => {
        var ele = [
          element.Value,
          // element.Block.substring(0, element.Block.length - 1),
          element.Block.substring(1, element.Block.length),
          parseInt(element.Month.substring(0, 6)),
        ];
        mydt.push(ele);
      });
      // set month columns
      const months = [];
      for (let i = 0; i < mydt.length; ++i) {
        if (months.length === 0 || months[months.length - 1] !== mydt[i][2]) {
          months.push(mydt[i][2]);
        }
      }

      // BAR GRAPH
      SetChartBarGrown(cd, "2", lines, mydt, colors, months, "chart_area0");

      // LINE GRAPH
      SetChartLineGrown(cd, "2", lines, mydt, colors, "chart_area1");
    });
  }
}
