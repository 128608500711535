import React from "react";
import { Button, Image } from "react-bootstrap";
import axios from "axios";

import { datahost } from "../../config/Const.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";
import "../../../style/DeveloperList.css";

export class DeveloperList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
    };
  }

  componentDidMount() {
    this.initLanguage();
  }

  render() {
    return (
      <div className="wrapper_margin">
        <Button
          id="pro-btn-mf"
          className="new_pagelink_btn"
          onClick={() => this.BackClick()}
        >
          <i className="fa fa-angle-left"></i>
          {this.state.lang.back}
        </Button>
        <br />
        <br />

        <div>
          <div>
            <div id="agent_list">
              <ul>
                <li>
                  <div className="main_developersec">
                    <div className="inner_developersec">
                      <Image
                        src={require("../../../images/home_sold.svg")}
                        alt=""
                      />
                      <h1>Lorem Ipsum text</h1>
                      <p>
                        Lorem Ipsum is a dummy text used for demo content.
                        please check the following link.
                        <button class="readmore_btn">Read More</button>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div id="footer">
          <br />
          <br />
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  Opendescription(pos) {
    document.getElementById("brif_secion_" + pos).style.display = "none";
    document.getElementById("full_secion_" + pos).style.display = "block";
    document.getElementById("readmore_btn_" + pos).style.display = "none";
  }

  Closedescription(pos) {
    document.getElementById("brif_secion_" + pos).style.display = "block";
    document.getElementById("full_secion_" + pos).style.display = "none";
    document.getElementById("readmore_btn_" + pos).style.display =
      "inline-block";
  }

  BackClick() {
    window.history.go(-1);
  }
}
