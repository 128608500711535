import React, { Component } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Base64 } from "js-base64";
import { datahost, shkyhost, gesture, pic_host } from "../../config/Const.js";
import { passParams } from "../../config/Connector.js";
import { replaceAllFixed } from "../../function/Basic.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/CondoNews.css";

export class NewsDetailShNt extends Component {
  state = {
    lang: enj,
    url: null,
    sh: null,
    data: null,
    mini: null,
    usr: null,
  };

  componentDidMount() {
    this.initLanguage();
    this.loadPage();
  }

  render() {
    return (
      <div id="newsDetail">
        <div>
          <Button
            id="pro-btn-mf"
            className="pull-right"
            onClick={() => this.shareMedia()}
          >
            <i className="fa fa-share"></i>
            {this.state.lang.share}
          </Button>
          <br />
        </div>
        <br />
        <strong>
          <div id="newsTitle" className="news-title"></div>
        </strong>
        <br />
        <br />
        <div id="newsContent"></div>
        <br />
        <br />
        <div id="footer">
          <br />
          <br />
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadPage() {
    try {
      var url = document.location.toString();
      if (url.indexOf("=")) {
        url = url.split("=")[0];
      }
      this.setState({ url: url });
      var sh = url.split("?").splice(1, 1);
      this.setState({ sh: sh });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'sh':'" + sh + "'}\"";
      axios.post("/share/article", param).then((res) => {
        this.setState({
          data: res.data.data,
          mini: res.data.mini,
          usr: res.data.usr,
        });
        // Set article content
        document.getElementById("newsTitle").innerText = res.data.data.title;
        var content = res.data.data.content;
        if (content !== undefined) {
          content = replaceAllFixed(
            content,
            "/aprg",
            gesture + pic_host + "/aprg"
          );
          content = replaceAllFixed(
            content,
            "/eadf26jst",
            gesture + pic_host + "/eadf26jst"
          );
          document.getElementById("newsContent").innerHTML = content;
        }
      });
    } catch {}
  }

  shareMedia() {
    let url = this.state.url;
    var md = this.state.data;
    // get image thumbnail by add 'tbn_' pre
    md.image = md.image.replace("/articleimgs/", "/articleimgs/tbn_");
    let str = url + ";" + md.title + ";" + md.brief + ";" + md.image;
    let params = Base64.encode(str);
    passParams("copySharing", params);
  }
}
