import React, { Component } from "react";
import { Image } from "react-bootstrap";
import axios from "axios";
import wx from "weixin-js-sdk";
import { datahost, shkyhost } from "../../config/Const.js";
import { rmHtmlTag } from "../../function/Basic.js";
import { createContact } from "../../function/Share.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/Article.css";

export class S_Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      sh: null,
      dtl: null,
    };
  }

  componentDidMount() {
    this.loadArticle();
  }

  render() {
    return (
      <div>
        <div>
          {/* <p className="nTitle" id="nTitle"></p> */}
          <div className="shared_title" id="nTitle"></div>
        </div>
        <br />
        <br />
        <div className="shared_content" id="nContent"></div>
        <br />
        <br />
        <div className="agent_detsec section_margin" id="shared_contact">
          <div className="mainagent_leftsec">
            <div className="outer_agentdet_sec">
              <div className="main_agentpic_sec">
                <Image id="contact_hd" />
              </div>
              <div className="main_agentdetsec">
                <h2 id="contact_name"></h2>
                <h3 id="contact_title"></h3>
                <p id="contact_company"></p>
              </div>
            </div>
            <div className="agent_description_mainsec">
              <h5>{this.state.lang.share_introduction}</h5>
              <p id="contact_introduction"></p>
            </div>
            <div className="agentfull_detsec">
              <ul>
                <li>
                  <a id="contact_message">
                    <i className="fa fa-comments"></i>
                    <p>{this.state.lang.share_message}</p>
                  </a>
                </li>
                <li>
                  <a id="contact_phone">
                    <i className="fa fa-phone-alt"></i>
                    <p>{this.state.lang.share_phone}</p>
                  </a>
                </li>
                <li>
                  <a id="contact_email">
                    <i className="fa fa-envelope"></i>
                    <p>{this.state.lang.share_email}</p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="mainqrcode_sec">
              <h2>[ {this.state.lang.share_scanqr} ]</h2>
              <img id="contact_qr" width="30%" thumbnail="true" />
            </div>
          </div>
        </div>

        <div hidden={true}>
          <p>
            <i id="wx_title"></i>
            <i id="wx_desc"></i>
            <i id="wx_imgurl"></i>
            <i id="wx_link"></i>
          </p>
        </div>
        <div id="footer"></div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadArticle() {
    try {
      var url = document.location.toString();
      document.getElementById("wx_link").innerText = url;
      // if (url[url.length - 1] === "=") {
      //   url = url.slice(0, -1);
      // }
      if (url.indexOf("=")) {
        url = url.split("=")[0];
      }
      let sh = url.split("?").splice(1, 1)[0];
      this.setState({ sh: sh });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'sh':'" + sh + "'}\"";
      axios.post("/share/media", param).then((res) => {
        this.setState({ dtl: res.data });
        // Set contact user
        if (this.state.dtl.usr !== undefined) {
          createContact(this.state.dtl.usr);
          document.getElementById("shared_contact").hidden = false;
        } else {
          document.getElementById("shared_contact").hidden = true;
        }
        // Set inner HTML
        let nc = document.getElementById("nContent");
        nc.innerHTML = res.data.data.nContent;
        // Set title and wx-tags
        let title = res.data.data.nTitle;
        document.title = title;
        document.getElementById("nTitle").innerText = title;
        document.getElementById("wx_title").innerText =
          this.state.lang.dtl_share_title + title;
        var brief = rmHtmlTag(res.data.data.nContent);
        if (brief.length > 50) {
          brief = brief.substr(0, 50);
        }
        document.getElementById("wx_desc").innerText = brief;
        document.getElementById("wx_imgurl").innerText =
          res.data.data.firstImage;
        // WxShare Configuration
        axios.defaults.baseURL = shkyhost;
        axios
          .get(
            "/wx/wxconfig?url=" +
              encodeURI(document.getElementById("wx_link").innerText)
          )
          .then((resWC) => {
            wx.config({
              appId: resWC.data.appid,
              timestamp: resWC.data.timestamp,
              nonceStr: resWC.data.nonceStr,
              signature: resWC.data.signature,
              jsApiList: [
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ],
            });

            wx.ready(() => {
              wx.updateAppMessageShareData({
                title: document.getElementById("wx_title").innerText,
                desc: document.getElementById("wx_desc").innerText,
                link: document.getElementById("wx_link").innerText,
                imgUrl: document.getElementById("wx_imgurl").innerText,
                success: () => {},
              });

              wx.updateTimelineShareData({
                title: document.getElementById("wx_title").innerText,
                link: document.getElementById("wx_link").innerText,
                imgUrl: document.getElementById("wx_imgurl").innerText,
                success: () => {},
              });
            });
          });
      });
    } catch {}
  }
}
