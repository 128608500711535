import React, { Component } from "react";
import { Image } from "react-bootstrap";
import Slider from "react-slick";
import axios from "axios";
import wx from "weixin-js-sdk";
import {
  gesture,
  datahost,
  shkyhost,
  pic_host,
  pic_condos0,
  pic_condos1,
} from "../../config/Const.js";
import { replaceAllFixed, priceSwitch } from "../../function/Basic.js";
import { createContact } from "../../function/Share.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/CondoNews.css";

export class CondosDetailSh extends Component {
  state = {
    lang: enj,
    tk: null,
    condo: null,
    usr: null,
    images: [],
  };

  componentDidMount() {
    this.initLanguage();
    this.loadPage();
  }

  render() {
    let setting = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div id="newsDetail">
        <div className="detail_banner_sec">
          <div className="slider single-item">
            <Slider {...setting}>
              {this.state.images.map(function (item) {
                return (
                  <div key={item.picId}>
                    <div className="inner_bannersec">
                      <img src={item.Link} alt="" />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>

        <div className="common_infosec summary_tabsec">
          <ul>
            <li>
              <p>{this.state.lang["ncdo_proname"]}</p>
              <strong>
                <p id="pro-proname"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_prostatus"]}</p>
              <strong>
                <p id="pro-prostatus"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_startprice"]}</p>
              <strong>
                <p id="pro-startprice"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_developer"]}</p>
              <strong>
                <p id="pro-developer"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_type"]}</p>
              <strong>
                <p id="pro-type"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_unitsum"]}</p>
              <strong>
                <p id="pro-unitsum"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_buildingsize"]}</p>
              <strong>
                <p id="pro-buildingsize"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_occuyear"]}</p>
              <strong>
                <p id="pro-occuyear"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_intersection"]}</p>
              <strong>
                <p id="pro-intersection"></p>
              </strong>
            </li>
            <li>
              <p>{this.state.lang["ncdo_address"]}</p>
              <strong>
                <p id="pro-address"></p>
              </strong>
            </li>
          </ul>
        </div>

        <div id="condosContent"></div>

        <div className="agent_detsec section_margin" id="shared_contact">
          <div className="mainagent_leftsec">
            <div className="outer_agentdet_sec">
              <div className="main_agentpic_sec">
                <Image id="contact_hd" />
              </div>
              <div className="main_agentdetsec">
                <h2 id="contact_name"></h2>
                <h3 id="contact_title"></h3>
                <p id="contact_company"></p>
              </div>
            </div>
            <div className="agent_description_mainsec">
              <h5>{this.state.lang.share_introduction}</h5>
              <p id="contact_introduction"></p>
            </div>
            <div className="agentfull_detsec">
              <ul>
                <li>
                  <a id="contact_message">
                    <i className="fa fa-comments"></i>
                    <p>{this.state.lang.share_message}</p>
                  </a>
                </li>
                <li>
                  <a id="contact_phone">
                    <i className="fa fa-phone-alt"></i>
                    <p>{this.state.lang.share_phone}</p>
                  </a>
                </li>
                <li>
                  <a id="contact_email">
                    <i className="fa fa-envelope"></i>
                    <p>{this.state.lang.share_email}</p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="mainqrcode_sec">
              <h2>[ {this.state.lang.share_scanqr} ]</h2>
              <img id="contact_qr" width="30%" thumbnail="true" />
            </div>
          </div>
        </div>

        <div hidden={true}>
          <p>
            <i id="wx_title"></i>
            <i id="wx_desc"></i>
            <i id="wx_imgurl"></i>
            <i id="wx_link"></i>
          </p>
        </div>

        <div className="bottom_sliding_sec">
          <div className="yiju_logosec pull-left">
            <img src={require("../../../images/yiju_logo.png")} alt="" />
            <p>
              {this.state.lang.yiju}
              {/* <i className="fa fa-angle-double-right"></i> */}
            </p>
          </div>
          <div className="detailapp_btn pull-right">
            <button
              type="button"
              className="common_mainbtn btn btn-primary"
              onClick={() => {
                this.JumpToGuide();
              }}
            >
              {this.state.lang.dtl_detailsinapp}
            </button>
          </div>
        </div>

        <div id="footer">
          <br />
          <br />
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadPage() {
    try {
      var url = document.location.toString();
      document.getElementById("wx_link").innerText = url;
      // be compatible with Facebook
      if (url.indexOf("&")) {
        url = url.split("&")[0];
      }
      // be compatible with WeChat
      if (url.indexOf("=")) {
        url = url.split("=")[0];
      }
      var sh = url.split("?").splice(1, 1);
      this.setState({ sh: sh });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'sh':'" + sh + "'}\"";
      axios.post("/share/condos", param).then((res) => {
        this.setState({ condo: res.data.data, usr: res.data.usr });
        // Set contact user
        if (this.state.usr !== undefined) {
          createContact(this.state.usr);
          document.getElementById("shared_contact").hidden = false;
        } else {
          document.getElementById("shared_contact").hidden = true;
        }
        // fill image address in content
        this.setState({ images: this.fillCondosImages(this.state.condo.pics) });
        // fill image address in content
        var content = this.state.condo.proFeatures;
        if (content != undefined) {
          content = replaceAllFixed(
            content,
            "/aprg",
            gesture + pic_host + "/aprg"
          );
          content = replaceAllFixed(
            content,
            "/eadf26jst",
            gesture + pic_host + "/eadf26jst"
          );
          document.getElementById("condosContent").innerHTML = content;
        }
        // fill form
        document.getElementById("pro-proname").innerText =
          this.state.condo.proName;
        document.getElementById("pro-prostatus").innerText =
          this.state.condo.projectStatus;
        document.getElementById("pro-startprice").innerText = priceSwitch(
          this.state.condo.priceStart
        );
        document.getElementById("pro-developer").innerText =
          this.state.condo.builderName;
        document.getElementById("pro-type").innerText =
          this.state.condo.condoType;
        document.getElementById("pro-unitsum").innerText =
          this.state.condo.numberOfUnits;
        document.getElementById("pro-buildingsize").innerText =
          this.state.condo.buildingSize;
        document.getElementById("pro-occuyear").innerText =
          this.state.condo.occuYear;
        document.getElementById("pro-intersection").innerText =
          this.state.condo.mainIntersection;
        document.getElementById("pro-address").innerText =
          this.state.condo.proLocation +
          ", " +
          this.state.condo.city +
          ", " +
          this.state.condo.province;

        // Set title and wx-tags
        document.title = this.state.condo.proName;
        document.getElementById("wx_title").innerText =
          this.state.lang.dtl_share_title + this.state.condo.proName;
        document.getElementById("wx_desc").innerText =
          this.state.condo.proBrief;
        document.getElementById("wx_imgurl").innerText =
          this.state.condo.pics[0].Link;
        // WxShare Configuration
        axios.defaults.baseURL = shkyhost;
        axios
          .get(
            "/wx/wxconfig?url=" +
              encodeURI(document.getElementById("wx_link").innerText)
          )
          .then((resWC) => {
            wx.config({
              appId: resWC.data.appid,
              timestamp: resWC.data.timestamp,
              nonceStr: resWC.data.nonceStr,
              signature: resWC.data.signature,
              jsApiList: [
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ],
            });

            wx.ready(() => {
              wx.updateAppMessageShareData({
                title: document.getElementById("wx_title").innerText,
                desc: document.getElementById("wx_desc").innerText,
                link: document.getElementById("wx_link").innerText,
                imgUrl: document.getElementById("wx_imgurl").innerText,
                success: () => {},
              });

              wx.updateTimelineShareData({
                title: document.getElementById("wx_title").innerText,
                link: document.getElementById("wx_link").innerText,
                imgUrl: document.getElementById("wx_imgurl").innerText,
                success: () => {},
              });
            });
          });
      });
    } catch {}
  }

  fillCondosImages = (data) => {
    var imgs = [];
    data.forEach((element) => {
      let ppm = element.fileName.split(",");
      element["Link"] =
        gesture + pic_host + pic_condos0 + ppm[0] + pic_condos1 + ppm[1];
      imgs.push(element);
    });
    return imgs;
  };

  JumpToGuide() {
    let path = "/yiju/gdcondos?" + this.state.sh;
    this.props.history.push(path);
  }
}
