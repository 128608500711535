import React, { Component } from "react";
import { getBrowserSys } from "../function/Share";

import enj from "../language/en.json";
import zhj from "../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../style/AppGuide.css";

export class AppGuide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      sh: null,
      dtl: null,
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadArticle();
  }

  render() {
    return (
      <div>
        <div className="download_mainsppsec">
          <div className="inner_downloadsec">
            <div className="yiju_applogosec">
              <img src={require("../../images/yiju_whitebg.png")} alt="" />
              <h1>{this.state.lang.yiju}</h1>
            </div>
            <div className="down_contsec">
              <p>{this.state.lang.guide_title}</p>
              <i className="fa fa-arrow-down"></i>

              <div className="applemainbtn_sec" id="btn_ios">
                <div
                  className="commonbtn_sec"
                  onClick={() => {
                    this.gotoAppleStore();
                  }}
                >
                  <img
                    src={require("../../images/apple_icon.png")}
                    alt=""
                    className="applestore_btn"
                  />
                  <h1>
                    <small>{this.state.lang.guide_download}</small>
                    {this.state.lang.guide_appstore}
                  </h1>
                </div>
              </div>

              <div className="androidmainbtn_sec" id="btn_android">
                <div
                  className="commonbtn_sec"
                  onClick={() => {
                    this.gotoGooglePlay();
                  }}
                >
                  <img
                    src={require("../../images/android_icon.png")}
                    alt=""
                    className="androidstore_btn"
                  />
                  <h1>
                    <small>{this.state.lang.guide_download}</small>
                    {this.state.lang.guide_googleplay}
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="click_heresec"
              onClick={() => {
                this.OpenBlockClick();
              }}
            >
              <p>{this.state.lang.guide_content}</p>
              <div className="click_handsec">
                <h1>{this.state.lang.guide_clickhere}</h1>
                <img src={require("../../images/click_hand.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="openbrowser_sec" id="cover_block">
          <div className="close_openbrosec pull-left">
            <span
              onClick={() => {
                this.CloseBlockClick();
              }}
            >
              &#10006;
            </span>
          </div>
          <div className="arrow_browsersec pull-right">
            <span>&#10138;</span>
            <p>{this.state.lang.dtl_openwithbrowser}</p>
          </div>
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
      let test = document.getElementById("btn_ios");
      test.className = "androidmainbtn_sec";
    }
  }

  async loadArticle() {
    try {
      var url = document.location.toString();
      let paras = url.split("?")[1];
      this.setState({ sh: paras });
      //   document.getElementById("context").innerText = paras;
      let systp = getBrowserSys();
      if (systp === 1) {
        document.getElementById("btn_ios").style.display = "block";
        document.getElementById("btn_android").style.display = "none";
        // document.getElementById("cover_block").style.display = "block";
      } else if (systp === 2) {
        document.getElementById("btn_ios").style.display = "none";
        document.getElementById("btn_android").style.display = "block";
      } else {
        document.getElementById("btn_ios").style.display = "block";
        document.getElementById("btn_android").style.display = "block";
      }
    } catch {}
  }

  gotoAppleStore() {
    window.location.href = "https://apps.apple.com/app/id1483610872";
  }

  gotoGooglePlay() {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.eziagent.yiju";
  }

  OpenBlockClick() {
    window.scrollTo(0, 0);
    document.getElementById("cover_block").style.display = "block";
  }

  CloseBlockClick() {
    document.getElementById("cover_block").style.display = "none";
  }
}
