import { google_trans_api } from "../config/Const";

export async function Translator(text) {
  try {
    const textArr = text.split(" ");
    console.log(textArr.length);
    var url = "https://translation.googleapis.com/language/translate/v2";
    url += "?key=" + google_trans_api;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        source: "en",
        target: "zh",
        format: "text",
        q: text,
      }),
    });
    if (!response.ok) {
      //call fetch after 1 second
      console.log("waiting for response");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      Translator(text);
    } else {
      const data = await response.json();
      let res = "";
      for (let i = 0; i < data.data.translations.length; i++) {
        res += data.data.translations[i].translatedText;
      }
      return res;
    }
  } catch (e) {
    console.error(e);
    return e;
  }
}
