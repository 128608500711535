import React, { Component } from "react";
import { Button, Toast, ToastHeader, ToastBody, Image } from "react-bootstrap";
import axios from "axios";
import { datahost } from "../../config/Const.js";
import { passParams } from "../../config/Connector.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/Contact.css";

export class QrUpload extends Component {
  static displayName = QrUpload.name;

  state = {
    lang: enj,
    tk: null,
    hdimage: null,
    qrimage: null,
    title: null,
    compy: null,
    intro: null,
  };

  componentDidMount() {
    this.initLanguage();
    this.initToast();
    this.loadDetail();
  }

  render() {
    var { hdimage, qrimage } = this.state;
    var hdImagePreview = null;
    if (hdimage) {
      hdImagePreview = (
        <label htmlFor="avatarFor">
          <img style={{ width: "80px", height: "80px" }} src={hdimage} />
        </label>
      );
    }
    var qrImagePreview = null;
    if (qrimage) {
      qrImagePreview = (
        <label htmlFor="avatarFor">
          <img style={{ width: "80px", height: "80px" }} src={qrimage} />
        </label>
      );
    }

    return (
      <div className="wrapper">
        <div className="wrapper_margin">
          <div id="loginrequire" hidden={true}>
            <div className="loginrequire">
              <div className="hide_propertysec">
                <div className="inner_hidepropsec">
                  <i className="fas fa-user-lock"></i>
                  <p>{this.state.lang.loginrequire}</p>
                </div>
              </div>
              <div id="detail_history">
                <ul className="main_historysec"></ul>
              </div>
            </div>
          </div>
          <div
            className="detail_contactsec section_margin"
            id="contact"
            hidden={true}
          >
            <div className="main_headingsec">
              <h1>{this.state.lang.qrcd_pgtit}</h1>
            </div>
            <div className="detcontact_fieldsec">
              <input
                type="text"
                className="inputcont_field"
                name=""
                placeholder={this.state.lang.qrcd_firstname}
                id="inputFirstname"
                disabled="disabled"
              />
              <input
                type="text"
                className="inputcont_field"
                name=""
                placeholder={this.state.lang.qrcd_lastname}
                id="inputLastname"
                disabled="disabled"
              />
              <input
                type="text"
                className="inputcont_field"
                name=""
                placeholder={this.state.lang.qrcd_email}
                id="inputEmail"
                disabled="disabled"
              />
              <input
                type="text"
                className="inputcont_field"
                name=""
                placeholder={this.state.lang.qrcd_phone}
                id="inputPhone"
                disabled="disabled"
              />
              <div id="hdToast" className="p-3 bg-danger my-2 rounded">
                <Toast>
                  <ToastHeader>Can not read image.</ToastHeader>
                  <ToastBody>Image size must less than 2MB.</ToastBody>
                </Toast>
              </div>
              <span className="file_input">
                {this.state.lang.qrcd_personimg}
              </span>
              {/* <div id="hdImageBlock">{hdImagePreview}</div> */}
              <div>
                <Image
                  id="hdImageBlock"
                  style={{ width: "200px", height: "200px" }}
                  roundedCircle
                ></Image>
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => this.handleImageChangeHD(e)}
              />
              {/* <small className="file_smalldesc">
              This is the personal image to let people know you. Can put your
              LOGO also.
            </small> */}
              <input
                type="text"
                className="inputcont_field"
                name=""
                placeholder={this.state.lang.qrcd_title}
                id="inputTitle"
                maxLength="100"
                onChange={(e) => {
                  this.setState({
                    title: e.target.value,
                  });
                }}
              />
              <input
                type="text"
                className="inputcont_field"
                name=""
                placeholder={this.state.lang.qrcd_company}
                id="inputCompany"
                onChange={(e) => {
                  this.setState({
                    compy: e.target.value,
                  });
                }}
              />
              <div id="qrToast" className="p-3 bg-danger my-2 rounded">
                <Toast>
                  <ToastHeader>Can not read image.</ToastHeader>
                  <ToastBody>Image size must less than 2MB.</ToastBody>
                </Toast>
              </div>
              <span className="file_input">{this.state.lang.qrcd_qrcode}</span>
              <div>
                <Image
                  id="qrImageBlock"
                  style={{ width: "300px", height: "300px" }}
                  rounded
                ></Image>
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => this.handleImageChangeQR(e)}
              />
              {/* <small className="file_smalldesc">
              This is the QR namecard image, help people to know you when
              scanning this picture.
            </small> */}
              <textarea
                className="textareacont_field"
                placeholder={this.state.lang.qrcd_intro}
                className=""
                maxLength="500"
                id="inputIntroduction"
                onChange={(e) => {
                  this.setState({
                    intro: e.target.value,
                  });
                }}
              ></textarea>
              <Button
                className="btncont_field"
                onClick={() => this.uploadSubmit()}
              >
                <i className="fas fa-user-tie"></i>
                {this.state.lang.submit}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  handleImageChangeHD(e) {
    e.preventDefault();
    var reader = new FileReader();
    var file = e.target.files[0];
    if (file != undefined) {
      if (file.size < 1024 * 1024 * 2) {
        reader.onloadend = () => {
          document.getElementById("hdImageBlock").src = reader.result;
          this.setState({
            hdfile: file,
            hdimage: reader.result,
          });
        };
        reader.readAsDataURL(file);
        document.getElementById("hdToast").style.display = "none";
      } else {
        document.getElementById("hdToast").style.display = "block";
      }
    }
  }

  handleImageChangeQR(e) {
    e.preventDefault();
    var reader = new FileReader();
    var file = e.target.files[0];
    if (file != undefined) {
      if (file.size < 1024 * 1024 * 2) {
        reader.onloadend = () => {
          document.getElementById("qrImageBlock").src = reader.result;
          this.setState({
            qrfile: file,
            qrimage: reader.result,
          });
        };
        reader.readAsDataURL(file);
        document.getElementById("qrToast").style.display = "none";
      } else {
        document.getElementById("qrToast").style.display = "block";
      }
    }
  }

  initToast() {
    document.getElementById("hdToast").style.display = "none";
    document.getElementById("qrToast").style.display = "none";
  }

  async loadDetail() {
    try {
      let url = document.location.toString();
      let tk = url.split("?")[1];
      this.setState({ tk: tk });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      // check token level
      let param_tklvl = "\"{'tk':'" + tk + "'}\"";
      axios.post("/token/level", param_tklvl).then((res) => {
        if (res.data > 2) {
          // init view
          let param = "\"{'tk':'" + tk + "'}\"";
          axios.post("/user/namecard", param).then((res) => {
            if (res.data.Firstname === undefined) {
              document.getElementById("inputFirstname").value = "";
            } else {
              document.getElementById("inputFirstname").value =
                res.data.Firstname;
            }
            if (res.data.Lastname === undefined) {
              document.getElementById("inputLastname").value = "";
            } else {
              document.getElementById("inputLastname").value =
                res.data.Lastname;
            }
            if (res.data.Email === undefined) {
              document.getElementById("inputEmail").value = "";
            } else {
              document.getElementById("inputEmail").value = res.data.Email;
            }
            if (res.data.Phone === undefined) {
              document.getElementById("inputPhone").value = "";
            } else {
              document.getElementById("inputPhone").value = res.data.Phone;
            }
            if (res.data.Title === undefined) {
              document.getElementById("inputTitle").value = "";
            } else {
              document.getElementById("inputTitle").value = res.data.Title;
            }

            if (res.data.Company === undefined) {
              document.getElementById("inputCompany").value = "";
            } else {
              document.getElementById("inputCompany").value = res.data.Company;
            }

            if (res.data.Introduction === undefined) {
              document.getElementById("inputIntroduction").value = "";
            } else {
              document.getElementById("inputIntroduction").value =
                res.data.Introduction;
            }

            document.getElementById("hdImageBlock").src = res.data.Headimage;
            document.getElementById("qrImageBlock").src = res.data.Qrimage;
            // document.getElementById("hdImageBlock").innerHTML =
            //   '<img src="' + res.data.Headimage + '"/>';
            // document.getElementById("qrImageBlock").innerHTML =
            //   '<img src="' + res.data.Qrimage + '"/>';
            this.setState({
              title: res.data.Title,
              compy: res.data.Company,
              intro: res.data.Introduction,
            });
          });
          document.getElementById("contact").hidden = false;
          document.getElementById("loginrequire").hidden = true;
        } else {
          document.getElementById("contact").hidden = true;
          document.getElementById("loginrequire").hidden = false;
        }
      });
    } catch {}
  }

  uploadSubmit() {
    try {
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      var title = "";
      if (this.state.title !== null && this.state.title !== undefined) {
        title = this.state.title;
      }
      var company = "";
      if (this.state.compy !== null && this.state.compy !== undefined) {
        company = this.state.compy;
      }
      var introduction = "";
      if (this.state.intro !== null && this.state.compy !== undefined) {
        introduction = this.state.intro;
      }
      var headimage = "";
      if (this.state.hdimage !== null && this.state.hdimage !== undefined) {
        headimage = this.state.hdimage;
      }
      var qrcodeimage = "";
      if (this.state.qrimage !== null && this.state.qrimage !== undefined) {
        qrcodeimage = this.state.qrimage;
      }
      let param =
        "\"{'tk':'" +
        this.state.tk +
        "','tit':'" +
        title +
        "','com':'" +
        company +
        "','intro':'" +
        introduction +
        "','hd':'" +
        headimage +
        "','qr':'" +
        qrcodeimage +
        "',}\"";
      axios.post("/user/updtcard", param).then((res) => {
        if (res.data === "T") {
          passParams("quit", "");
        } else {
        }
      });
    } catch {}
  }
}
