import React from "react";
import { Image } from "react-bootstrap";
import axios from "axios";
import wx from "weixin-js-sdk";

import {
  datahost,
  shkyhost,
  gesture,
  pic_host,
  pic_sharing,
} from "../../config/Const.js";
import { priceSwitch, replaceAllFixed } from "../../function/Basic.js";
import { createContact } from "../../function/Share.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

export class S_L_Listings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      sh: null,
      listings: [],
      imgshtk: null,
      usr: null,
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadList();
  }

  render() {
    return (
      <div className="wrapper_margin">
        <div className="detcontact_fieldsec condodetcont_sec">
          <div className="inputsel_iconsec">
            {/* <div id="title"></div> */}
            <span id="title"></span>
          </div>
          <div className="inputsel_iconsec">
            {/* <input id="brief"></div> */}
            <span id="brief"></span>
          </div>
        </div>

        <div>
          <div>
            <div id="listing_list">
              <ul></ul>
            </div>
          </div>
        </div>

        <div className="agent_detsec section_margin" id="shared_contact">
          <div className="mainagent_leftsec">
            <div className="outer_agentdet_sec">
              <div className="main_agentpic_sec">
                <Image id="contact_hd" />
              </div>
              <div className="main_agentdetsec">
                <h2 id="contact_name"></h2>
                <h3 id="contact_title"></h3>
                <p id="contact_company"></p>
              </div>
            </div>
            <div className="agent_description_mainsec">
              <h5>{this.state.lang.share_introduction}</h5>
              <p id="contact_introduction"></p>
            </div>
            <div className="agentfull_detsec">
              <ul>
                <li>
                  <a id="contact_message">
                    <i className="fa fa-comments"></i>
                    <p>{this.state.lang.share_message}</p>
                  </a>
                </li>
                <li>
                  <a id="contact_phone">
                    <i className="fa fa-phone-alt"></i>
                    <p>{this.state.lang.share_phone}</p>
                  </a>
                </li>
                <li>
                  <a id="contact_email">
                    <i className="fa fa-envelope"></i>
                    <p>{this.state.lang.share_email}</p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="mainqrcode_sec">
              <h2>[ {this.state.lang.share_scanqr} ]</h2>
              <img id="contact_qr" width="30%" thumbnail="true" />
            </div>
          </div>
        </div>

        <div className="bottom_sliding_sec">
          <div className="yiju_logosec pull-left">
            <img src={require("../../../images/yiju_logo.png")} alt="" />
            <p>
              {this.state.lang.yiju}
              {/* <i className="fa fa-angle-double-right"></i> */}
            </p>
          </div>
          <div className="detailapp_btn pull-right">
            <button
              type="button"
              className="common_mainbtn btn btn-primary"
              onClick={() => {
                this.JumpToGuide();
              }}
            >
              {this.state.lang.dtl_detailsinapp}
            </button>
          </div>
        </div>

        <div hidden={true}>
          <p>
            <i id="wx_title"></i>
            <i id="wx_desc"></i>
            <i id="wx_imgurl"></i>
            <i id="wx_link"></i>
          </p>
        </div>

        <div id="footer">
          <br />
          <br />
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadList() {
    try {
      var url = document.location.toString();
      document.getElementById("wx_link").innerText = url;
      if (url.indexOf("=")) {
        url = url.split("=")[0];
      }
      let sh = url.split("?").splice(1, 1)[0];
      this.setState({ sh: sh });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param_sh = "\"{'sh':'" + sh + "'}\"";
      axios.post("/share/lists", param_sh).then((res) => {
        // save state
        this.setState({
          usr: res.data.usr,
          imgshtk: res.data.virtual,
        });
        this.setState({ listings: this.fillList(res.data.mini) });
        // set title and wx config
        document.title = res.data.data.nTitle;
        document.getElementById("title").innerText = res.data.data.nTitle;
        document.getElementById("brief").innerText = res.data.data.nBrief;
        document.getElementById("wx_title").innerText =
          this.state.lang.dtl_share_title + res.data.data.nTitle;
        document.getElementById("wx_desc").innerText = res.data.data.nBrief;
        document.getElementById("wx_imgurl").innerText =
          res.data.data.firstImage;

        // Listings
        this.createListingList(this.state.listings);

        // Namecard
        if (this.state.usr !== undefined) {
          createContact(this.state.usr);
          document.getElementById("shared_contact").hidden = false;
        } else {
          document.getElementById("shared_contact").hidden = true;
        }

        // WxShare Configuration
        axios.defaults.baseURL = shkyhost;
        axios
          .get(
            "/wx/wxconfig?url=" +
              encodeURI(document.getElementById("wx_link").innerText)
          )
          .then((resWC) => {
            wx.config({
              appId: resWC.data.appid,
              timestamp: resWC.data.timestamp,
              nonceStr: resWC.data.nonceStr,
              signature: resWC.data.signature,
              jsApiList: [
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ],
            });

            wx.ready(() => {
              wx.updateAppMessageShareData({
                title: document.getElementById("wx_title").innerText,
                desc: document.getElementById("wx_desc").innerText,
                link: document.getElementById("wx_link").innerText,
                imgUrl: document.getElementById("wx_imgurl").innerText,
                success: () => {},
              });

              wx.updateTimelineShareData({
                title: document.getElementById("wx_title").innerText,
                link: document.getElementById("wx_link").innerText,
                imgUrl: document.getElementById("wx_imgurl").innerText,
                success: () => {},
              });
            });
          });
      });
    } catch {}
  }

  fillList = (data) => {
    // let str_0 = hostname + "/appdet?tk=";
    // let str_1 = "&ngw=";
    let str_0 = gesture + document.location.host + "/yiju/shlidtl?";
    let str_1 = "=";
    let str_2 = "&ngw=";
    data.forEach((element) => {
      // fill detail link
      let ngw_0 = "940682259,";
      let ngw_1 = ",";
      let dt = "2021-01-08 12:25:08";
      let ngw_2 = ",";
      let ngw_str = ngw_0 + element.i + ngw_1 + dt + ngw_2 + element.O;
      var ngw = window.btoa(ngw_str);
      ngw = ngw.replace("=", "~");
      element["link"] =
        str_0 + this.state.sh + str_1 + ngw + str_1 + this.state.imgshtk;
      // fill picture link
      var ngwStr = "3574765509," + element.i + ",2028-01-08 15:46:58,0";
      ngwStr = replaceAllFixed(window.btoa(ngwStr), "=", "~");
      element["pic"] =
        gesture + pic_host + pic_sharing + this.state.imgshtk + str_2 + ngwStr;
      // fix beds, baths, parking.
      if (element["c1"] !== null) {
        element["b1"] += "+" + element["c1"];
      }
      // fix address
      element["c"] = replaceAllFixed(element["c"], ",", " ");
      element["c"] = replaceAllFixed(element["c"], "|", ",");
      // fix price showing
      if (element["h"] === "A" || element["g"] === null) {
        element["ag"] = priceSwitch(element["a"]);
      } else {
        element["ag"] = priceSwitch(element["g"]);
      }
      // sold/leased
      if (element["e"] === "Sale" || element["e"] === "S") {
        if (element["h"] === "A" || element["g"] === null) {
          element["e"] = this.state.lang.card_listing_sale;
        } else {
          element["e"] = this.state.lang.card_listing_sold;
          element["soldtag"] = require("../../../images/sold_stamp.png");
        }
      } else {
        if (element["h"] === "A" || element["g"] === null) {
          element["e"] = this.state.lang.card_listing_lease;
        } else {
          element["e"] = this.state.lang.card_listing_leased;
          element["soldtag"] = require("../../../images/leased_stamp.png");
        }
      }
    });
    return data;
  };

  createListingList(arr) {
    var st = document.getElementById("listing_list");
    if (arr !== null && arr !== []) {
      for (var i = 0; i < arr.length; i++) {
        st.children[0].innerHTML += this.buildListingElement(arr[i]);
      }
    }
  }

  buildListingElement(ele) {
    var result = "";
    result += "<div key=";
    result += ele.I;
    result += "><div><a href=";
    result += ele.link;
    result +=
      '><div class="inner_listingsec"><div class="listing_imgsec"><Image src=';
    result += ele.pic;
    result += ' alt="" /></div><div class="listing_detsec"><h2>$ ';
    result += ele.ag;
    result += " </h2>";
    if (ele.soldtag != null) {
      result +=
        '<span class="sold_tag"><Image src="' +
        ele.soldtag +
        '"></Image></span>';
    }
    result += '<span><i class="fa fa-map-marker-alt"></i>';
    result += ele.c;
    result +=
      '</span><ul><li><i class="fa fa-bed"></i><p>' +
      this.state.lang.dtl_share_beds;
    if (ele.b1 === null) result += "N/A";
    else result += ele.b1;
    result +=
      '</p></li><li><i class="fa fa-bath"></i><p>' +
      this.state.lang.dtl_share_baths;
    if (ele.a1 === null) result += "N/A";
    else result += ele.a1;
    result +=
      '</p></li><li><i class="fa fa-car"></i><p>' +
      this.state.lang.dtl_share_parkings;
    if (ele.i2 === null) result += "N/A";
    else result += ele.i2;
    result += '</p></li></ul><div class="property_status"><button>';
    result += ele.e;
    result += "</button></div></div></div></a></div></div>";
    return result;
  }

  JumpToGuide() {
    let path = "/yiju/gd";
    this.props.history.push(path);
  }
}
