import React, { Component } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import {
  gesture,
  datahost,
  def_site,
  pic_host,
  pic_condos0,
  pic_condos1,
} from "../../config/Const.js";
import { priceSwitch } from "../../function/Basic.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/CondoNews.css";

export class CondosSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      mt: null,
      curpg: 2,
      pgsize: 10,
      condosList: [],
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadPage();
  }

  render() {
    return (
      <div class="wrapper">
        <div class="wrapper_margin">
          <div>
            <Button id="pro-btn-mf" onClick={() => this.BackClick()}>
              <i className="fa fa-angle-left"></i>
              {this.state.lang.back}
            </Button>
            <br />
          </div>

          <div className="listings_sec section_margin">
            <div className="main_headingsec">
              <h1>{this.state.lang.ncdo_resulttit}</h1>
              <p>{this.state.lang.ncdo_resultdes}</p>
            </div>
            {/* main_headingsec */}

            <div className="listing_wrappersec outer_condodetsec">
              <div id="condos0_list">
                <ul></ul>
              </div>
              <div id="loadmore">
                <Button
                  id="pro-btn-mf"
                  style={{ width: "100%" }}
                  onClick={() => this.LoadMoreClick()}
                >
                  {this.state.lang.loadmore}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <br />
          <br />
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadPage() {
    try {
      var url = document.location.toString();
      let paras = url.split("?")[1].split("=");
      let tk = paras[0];
      let mt = window.atob(paras[1]);
      this.setState({ tk: tk, mt: mt });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";

      let param =
        "\"{'tk':'" +
        tk +
        "','st':'" +
        def_site +
        "','mt':'" +
        mt +
        "','pg':'" +
        this.state.pgsize +
        ";1'}\"";
      axios.post("/condos/search", param).then((res) => {
        this.setState({ condosList: this.fillCondos(res.data) });
        this.createCondosList(this.state.condosList);
        if (res.data.length < this.state.pgsize) {
          document.getElementById("loadmore").hidden = true;
        }
      });
    } catch {}
  }

  fillCondos = (data) => {
    let str_0 = gesture + document.location.host + "/condosdetail?";
    let str_1 = "=";
    data.forEach((element) => {
      // build and fill link
      element["Link"] = str_0 + this.state.tk + str_1 + element.ProId;
      let ppm = element.Thumbnail.split(",");
      element["Thumbnail"] =
        gesture + pic_host + pic_condos0 + ppm[0] + pic_condos1 + ppm[1];
    });
    return data;
  };

  createCondosList(arr) {
    var st = document.getElementById("condos0_list");
    if (arr !== null && arr !== []) {
      for (var i = 0; i < arr.length; i++) {
        st.children[0].innerHTML += this.buildCondosElement(arr[i]);
      }
    }
  }

  buildCondosElement(ele) {
    var result = "";
    result += "<li><a href=";
    result += ele.Link;
    result += "><div key=";
    result += ele.ProId;
    result +=
      '><div class="inner_listingsec"><div class="listing_imgsec"><img src="';
    result += ele.Thumbnail;
    result += '" alt="" /></div><div class="listing_detsec"><h2>';
    result += ele.ProName;
    result += '</h2><span><i class="fa fa-map-marker-alt"></i><strong>';
    result += this.state.lang.ncdo_location;
    result += ": </strong>";
    result += ele.Address;
    result += '</span><div class="developer_compsec"><strong>';
    result += this.state.lang.ncdo_startprice;
    result += ": </strong>";
    if (ele.PriceStart === null) {
      result += "N/A";
    } else {
      result += "$" + priceSwitch(ele.PriceStart);
    }
    result += '</div><div class="developer_compnamesec"><strong>';
    result += this.state.lang.ncdo_developer;
    result += ": </strong>";
    if (ele.BuilderName === null) {
      result += "N/A";
    } else {
      result += ele.BuilderName;
    }
    result += '</div><div class="occupancy_datesec"><strong>';
    result += this.state.lang.ncdo_occuyear;
    result += ": </strong>";
    if (ele.OccuYear === null) {
      result += "N/A";
    } else {
      result += ele.OccuYear;
    }
    result += "</div></div></div></div></a></li>";
    return result;
  }

  LoadMoreClick() {
    try {
      this.setState({ curpg: parseInt(this.state.curpg, 10) + 1 });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param =
        "\"{'tk':'" +
        this.state.tk +
        "','st':'" +
        def_site +
        "','mt':'" +
        this.state.mt +
        "','pg':'" +
        this.state.pgsize +
        ";" +
        this.state.curpg +
        "'}\"";
      axios.post("/condos/search", param).then((res) => {
        let addedList = this.fillCondos(res.data);
        this.createCondosList(addedList);
        for (var i = 0; i < addedList.length; i++) {
          this.state.condosList.push(addedList[i]);
        }
        if (res.data.length < this.state.pgsize) {
          document.getElementById("loadmore").hidden = true;
        }
      });
    } catch {}
  }

  BackClick() {
    window.history.go(-1);
  }
}
