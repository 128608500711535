import React, { Component } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Base64 } from "js-base64";
import {
  SetupBothDistribution,
  SetChartListing,
  SetChartPrice,
  SetChartMarket,
  SetChartPartition,
} from "../../function/Chart.js";
import { datahost, shkyhost } from "../../config/Const.js";
import { passParams } from "../../config/Connector.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/AppMarket.css";

export class Pre_S_Report_Base extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      rpid: null,
      repo: null,
      ls: null,
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadPage();
  }

  findKeyValue(obj, key, value) {
    for (var i = 0; i < obj.length; i++) {
      if (obj[i][key] === value) {
        return obj[i];
      }
    }
  }

  render() {
    return (
      <div className="wrapper">
        <div className="wrapper_margin">
          <Button
            id="pro-btn-mf"
            className="pull-left"
            onClick={() => this.BackClick()}
          >
            <i className="fa fa-angle-left"></i>
            {this.state.lang.back}
          </Button>
          <Button
            id="pro-btn-mf"
            className="pull-right"
            onClick={() => this.shareReport()}
          >
            <i className="fa fa-share"></i>
            {this.state.lang.share}
          </Button>
          <br />
          <br />
          <div className="shared_title" id="nTitle"></div>
          <div className="shared_content" id="nContent"></div>
          <br />
          <div
            id="chart_monthly"
            className="ChartMonthly"
            style={{
              marginLeft: "0.5rem",
              width: document.body.clientWidth - 16,
            }}
          >
            <div
              id="chart_listing"
              style={{ height: document.body.clientWidth }}
            ></div>
            <div
              id="chart_price"
              style={{ height: document.body.clientWidth }}
            ></div>
            <div
              id="chart_market"
              style={{ height: document.body.clientWidth }}
            ></div>
            <div
              id="chart_partition"
              style={{ width: "100%", height: document.body.clientWidth }}
            ></div>
            <div id="pie_range">
              <br />
            </div>
            {/* <div
            id="chart_soldprice"
            style={{
              width: "100%",
              height: (document.body.clientWidth * 2) / 3,
            }}
          ></div>
          <div
            id="chart_asksprice"
            style={{
              width: "100%",
              height: (document.body.clientWidth * 2) / 3,
            }}
          ></div> */}
          </div>

          <div id="footer">
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }

  async loadPage() {
    try {
      let url = document.location.toString();
      let paras = url.split("?")[1];
      let tk = paras.split("=")[0];
      let reportId = paras.split("=")[1];
      this.setState({ tk: tk, rpid: reportId });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'tk':'" + tk + "','id':'" + reportId + "'}\"";
      axios.post("/article/yijurepo", param).then((res) => {
        this.setState({ repo: res.data, ls: "S" });
        if (res.data.IsSold === false) {
          this.setState({ ls: "L" });
        }
        // Set inner HTML
        let ntitle = document.getElementById("nTitle");
        ntitle.innerText = this.state.repo.NTitle;
        let nbrief = document.getElementById("nContent");
        nbrief.innerText = this.state.repo.NBrief;
        // create charts
        this.CreateLineCharts();
        this.CreatePieCharts();
      });
    } catch {}
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  GetListValue(listStr) {
    return listStr.split(",");
  }

  CreateLineCharts() {
    // generate date range
    var month = [];
    var dt = new Date(this.state.repo.ReportDate);
    for (var i = 11; i >= 0; i--) {
      var dys = 14 * i;
      var tr = new Date();
      tr.setDate(dt.getDate() - dys);
      month.push(
        tr.getFullYear() + "-" + (tr.getMonth() + 1) + "-" + tr.getDate()
      );
    }
    // var month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    var soldL = this.GetListValue(this.state.repo.Sold);
    var newL = this.GetListValue(this.state.repo.New);
    var activeL = this.GetListValue(this.state.repo.Active);
    var soldDOM = this.GetListValue(this.state.repo.SoldAverageDom);
    var soldMP = this.GetListValue(this.state.repo.SoldMedianPrice);
    var soldAP = this.GetListValue(this.state.repo.SoldAveragePrice);
    var newMP = this.GetListValue(this.state.repo.NewMedianPrice);
    var newAP = this.GetListValue(this.state.repo.NewAveragePrice);
    var soldMax = this.GetListValue(this.state.repo.SoldMaxPrice);
    var soldMin = this.GetListValue(this.state.repo.SoldMinPrice);
    var newMax = this.GetListValue(this.state.repo.NewMaxPrice);
    var newMin = this.GetListValue(this.state.repo.NewMinPrice);
    var absorb = [];
    for (var i = 0; i < month.length; i++) {
      if (newL[i] === null || newL[i] === 0) {
        absorb.push(52);
      } else {
        absorb.push(((soldL[i] / newL[i]) * 100).toString().substring(0, 5));
      }
      // }
    }

    // Color and XAxis(Year)
    // var colors = ['#d14a61', '#5793f3', '#f8f2b8', '#54b936', '#EB68CC', '#E8BBF2', '#FEFF82', '#DF61AA', '#FA7292', '#9AE9BC', '#675bba', '#36A1D9', '#32C5E8', '#f8f2b8', '#bc7768', '#36A1D9', '#32C5E8', '#65E1E3', '#FF6567', '#A093F2', '#8675E7', '#9BBCFF'];
    var years = month;

    // Listing LINE&BAR
    var listingTitle = [
      this.state.lang["chart1_0"],
      years[0] + "~" + years[years.length - 1],
    ];
    var chart1_1 = this.state.lang["chart1_1"];
    if (this.state.ls === "L") {
      chart1_1 = this.state.lang["chart1_1l"];
    }
    var listingLegendSeries = [
      chart1_1,
      this.state.lang["chart1_2"],
      this.state.lang["chart1_3"],
    ];
    var listingYAxis = [
      this.state.lang["chart1_4"],
      this.state.lang["chart1_5"],
    ];
    var listingData = [soldL, newL, soldDOM];
    var listingColor = ["#FFB26B", "#b06a13", "#fcc41b"];
    SetChartListing(
      listingTitle,
      listingLegendSeries,
      years,
      listingYAxis,
      listingData,
      listingColor,
      "chart_listing"
    );

    // Price LINE
    var priceTitle = [
      this.state.lang["chart2_0"],
      years[0] + "~" + years[years.length - 1],
    ];
    var chart2_1 = this.state.lang["chart2_1"];
    if (this.state.ls === "L") {
      chart2_1 = this.state.lang["chart2_1l"];
    }
    var chart2_2 = this.state.lang["chart2_2"];
    if (this.state.ls === "L") {
      chart2_2 = this.state.lang["chart2_2l"];
    }
    var priceLegendSeries = [
      chart2_1,
      chart2_2,
      this.state.lang["chart2_3"],
      this.state.lang["chart2_4"],
    ];
    var priceData = [soldMP, soldAP, newMP, newAP];
    var priceColor = ["#FFB26B", "#b06a13", "#fcc41b", "#fc601b"];
    SetChartPrice(
      priceTitle,
      priceLegendSeries,
      years,
      priceData,
      priceColor,
      "chart_price"
    );

    // Market LINE
    var chart3_0 = this.state.lang["chart3_0"];
    if (this.state.ls === "L") {
      chart3_0 = this.state.lang["chart3_0l"];
    }
    var marketTitle = [chart3_0, years[0] + "~" + years[years.length - 1]];
    var marketData = absorb;
    var marketColor = ["#FFB26B", "#b06a13", "#fcc41b"];
    SetChartMarket(marketTitle, years, marketData, marketColor, "chart_market");
  }

  CreatePieCharts() {
    var pieFillData = this.GetListValue(this.state.repo.SoldType);

    // Partition PIE
    var pieColors = [
      "#FFB26B",
      "#b06a13",
      "#fcc41b",
      "#fc601b",
      "#b04313",
      "#ffe6c6",
    ];
    var chart4_0 = this.state.lang["chart4_0"];
    if (this.state.ls === "L") {
      chart4_0 = this.state.lang["chart4_0l"];
    }
    var pieTitle = [chart4_0, ""];
    var pieLegend = [
      this.state.lang["detached"],
      this.state.lang["semi"],
      this.state.lang["townhouse"],
      this.state.lang["condo"],
      this.state.lang["others"],
    ];
    var pieData = [
      { name: pieLegend[0], value: pieFillData[0] },
      { name: pieLegend[1], value: pieFillData[1] },
      { name: pieLegend[2], value: pieFillData[2] },
      { name: pieLegend[3], value: pieFillData[3] },
      { name: pieLegend[4], value: pieFillData[4] },
    ];
    SetChartPartition(
      pieTitle,
      pieLegend,
      pieData,
      pieColors,
      "chart_partition"
    );
  }

  // PartByDistribution() {
  //   var rg = this.state.ed.substring(0, this.state.ed.length - 3);
  //   var chart5_0 = this.state.lang["chart5_0"];
  //   if (this.state.ls === "L") {
  //     chart5_0 = this.state.lang["chart5_0l"];
  //   }
  //   var titles = [chart5_0, rg, this.state.lang["chart6_0"], rg];
  //   var distriColors = ["#d14a61", "#f8f2b8"];
  //   SetupBothDistribution(
  //     titles,
  //     this.state.distrib,
  //     distriColors,
  //     "chart_soldprice",
  //     "chart_asksprice"
  //   );
  // }

  shareReport() {
    try {
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param =
        "\"{'tk':'" +
        this.state.tk +
        "','id':'" +
        this.state.rpid +
        "','hd':'1'}\"";
      axios.post("/share/token", param).then((res) => {
        let url = shkyhost + "/shreport?" + res.data;
        let str =
          url +
          ";" +
          this.state.repo.NTitle +
          ";" +
          this.state.repo.NBrief +
          ";" +
          "https://api.yjezimoc.com/shared/default.png";
        let params = Base64.encode(str);
        passParams("sharemedia", params);
      });
    } catch {}
  }

  BackClick() {
    window.history.go(-1);
  }
}
