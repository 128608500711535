import React, { Component } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Base64 } from "js-base64";
import { datahost, shkyhost } from "../../config/Const.js";
import { passParams } from "../../config/Connector.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/Article.css";

export class Pre_S_Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      sh: null,
      media: null,
    };
  }

  componentDidMount() {
    this.loadArticle();
  }

  render() {
    return (
      <div className="wrapper">
        <div className="wrapper_margin">
          <Button
            id="pro-btn-mf"
            className="pull-left"
            onClick={() => this.BackClick()}
          >
            <i className="fa fa-angle-left"></i>
            {this.state.lang.back}
          </Button>
          <Button
            id="pro-btn-mf"
            className="pull-right"
            onClick={() => this.shareMedia()}
          >
            <i className="fa fa-share"></i>
            {this.state.lang.share}
          </Button>
          <br />
          <br />
          <div className="shared_title" id="nTitle"></div>
          <br />
          <br />
          <div className="shared_content" id="nContent"></div>
        </div>
        <div id="footer"></div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadArticle() {
    try {
      var url = document.location.toString();
      if (url[url.length - 1] === "=") {
        // remove the last '=' character
        url = url.slice(0, -1);
      }
      let sh = url.split("?").splice(1, 1)[0];
      this.setState({ sh: sh });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'sh':'" + sh + "'}\"";
      axios.post("/share/media", param).then((res) => {
        this.setState({ media: res.data });
        let nt = document.getElementById("nTitle");
        nt.innerText = res.data.data.nTitle;
        let nc = document.getElementById("nContent");
        nc.innerHTML = res.data.data.nContent;
      });
    } catch {}
  }

  shareMedia() {
    let md = this.state.media.data;
    let url = shkyhost + "/shmedia?" + this.state.sh;
    let str = url + ";" + md.nTitle + ";" + md.nBrief + ";" + md.firstImage;
    let params = Base64.encode(str);
    passParams("sharemedia", params);
  }

  BackClick() {
    window.history.go(-1);
  }
}
