import React, { Component } from "react";
import { Button, Alert, Image } from "react-bootstrap";
import axios from "axios";
import CryptoJS from "crypto-js";
import { datahost } from "../../config/Const.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/Contact.css";

export class Regist extends Component {
  state = {
    lang: enj,
    tk: null,
    em: null,
    sign: null,
    shpwd: false,
    agent: null,
  };

  toggleShowError = () => this.setState({ showA: !this.state.showA });

  componentDidMount() {
    this.initLanguage();
    this.loadDetail();
  }

  render() {
    return (
      <div className="wrapper">
        <Alert variant="danger" id="error_toast" hidden={true}>
          <strong>
            <p id="error_tit"></p>
          </strong>
          <p id="error_msg"></p>
        </Alert>
        <div className="wrapper_margin">
          <div className="detail_contactsec section_margin" id="contact">
            <div className="main_headingsec">
              <h1>{this.state.lang.regist_title}</h1>
            </div>
            <div className="detcontact_fieldsec">
              <input
                type="text"
                className="inputcont_field"
                placeholder={this.state.lang.qrcd_email}
                required
                id="inputEmail"
                disabled="disabled"
              />
              <input
                type="text"
                className="inputcont_field"
                placeholder={this.state.lang.qrcd_firstname}
                required
                id="inputFirstname"
                maxLength="50"
              />
              <input
                type="text"
                className="inputcont_field"
                placeholder={this.state.lang.qrcd_lastname}
                required
                id="inputLastname"
                maxLength="50"
              />
              <select id="inputPhonePre" className="inputcont_field">
                <option value="+1">Canada (+1)</option>
                <option value="+86">China (+86)</option>
              </select>
              <input
                type="number"
                className="inputcont_field"
                placeholder={this.state.lang.qrcd_phone}
                required
                id="inputPhone"
                maxLength="15"
              />
              <input
                type="password"
                className="inputcont_field"
                placeholder={this.state.lang.qrcd_password}
                type="password"
                required
                id="inputPassword"
                maxLength="50"
              />
              <span
                className="show_passeye"
                id="regist_showpwd"
                onClick={() => this.toggleShowPwd()}
              >
                <i class="fas fa-eye"></i>
              </span>
              <div className="realtor_checkbox">
                <input type="checkbox" id="inputIsRealtor"></input>
                <span>{this.state.lang.regist_isrealtor}</span>
              </div>
              <br />

              {/* Link an agent */}
              {this.state.agent != null ?
                <div className="allouter_agentdet_sec">
                  <div className="left_agentdetsec">
                    <div className="main_agentpic_sec">
                      <Image src={this.state.agent.Headimage} roundedCircle></Image>
                    </div>
                  </div>
                  <div className="right_agentdetsec">
                    <div className="main_agentdetsec">
                      <h2>{this.state.agent.Firstname} {this.state.agent.Lastname}</h2>
                      <h3>{this.state.agent.Title}</h3>
                      <p>
                        <strong>{this.state.agent.Company}</strong>
                      </p>
                    </div>
                    <div className="agent_description_mainsec">
                      <p>
                        {this.state.agent.Introduction}
                      </p>
                    </div>
                  </div>
                </div>
                :
                null}

              <Button
                id="regist_submit"
                className="btncont_field"
                onClick={() => this.uploadSubmit()}
              >
                <i className="fas fa-user-tie"></i>
                {this.state.lang.submit}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadDetail() {
    try {
      let url = document.location.toString();
      let paras = url.split("?")[1].split("=");
      let tk = paras[0];
      let em = paras[1];
      var sign = "";
      if (paras.length == 3) {
        sign = paras[2];
      }
      this.setState({ tk: tk, em: em, sign: sign });
      document.getElementById("inputEmail").value = em;

      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let paraTK = "\"{'tk':'" + tk + "'}\"";

      //Load one agent, TODO: may need to update the logic
      axios.post("/agent/getone", paraTK).then((res) => {
        this.setState({ agent: res.data });
      });

    } catch { }
  }

  toggleShowPwd() {
    let inpwd = document.getElementById("inputPassword");
    if (this.state.shpwd === false) {
      inpwd.type = "text";
      this.setState({ shpwd: true });
    } else {
      inpwd.type = "password";
      this.setState({ shpwd: false });
    }
  }

  inputValidate() {
    let toast = document.getElementById("error_toast");
    let tit = document.getElementById("error_tit");
    let msg = document.getElementById("error_msg");
    let pwd = document.getElementById("inputPassword").value;
    let ph = document.getElementById("inputPhone").value;
    let pre = document.getElementById("inputPhonePre").value;
    let ln = document.getElementById("inputLastname").value;
    let fn = document.getElementById("inputFirstname").value;
    if (ln.length < 1 || fn.length < 1) {
      toast.hidden = false;
      tit.innerHTML = this.state.lang.regist_error_title;
      msg.innerHTML = this.state.lang.regist_error_name;
      return false;
    }
    if (pre === "+1") {
      if (ph.length !== 10) {
        toast.hidden = false;
        tit.innerHTML = this.state.lang.regist_error_title;
        msg.innerHTML = this.state.lang.regist_error_phone;
        return false;
      }
    } else {
      if (ph.length !== 11) {
        toast.hidden = false;
        tit.innerHTML = this.state.lang.regist_error_title;
        msg.innerHTML = this.state.lang.regist_error_phone;
        return false;
      }
    }
    if (pwd.length < 6 || pwd.length > 20) {
      toast.hidden = false;
      tit.innerHTML = this.state.lang.regist_error_title;
      msg.innerHTML = this.state.lang.regist_error_pwd;
      return false;
    }
    toast.hidden = true;
    return true;
  }

  uploadSubmit() {
    let vali = this.inputValidate();
    if (vali === false) {
      return;
    }
    let pwd = document.getElementById("inputPassword").value;
    let ph = document.getElementById("inputPhone").value;
    let pre = document.getElementById("inputPhonePre").value;
    let ln = document.getElementById("inputLastname").value;
    let fn = document.getElementById("inputFirstname").value;
    var ir = "0";
    if (document.getElementById("inputIsRealtor").checked === true) {
      ir = "1";
    }
    try {
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param =
        "\"{'tk':'" +
        this.state.tk +
        "','em':'" +
        this.state.em +
        "','fn':'" +
        fn +
        "','ln':'" +
        ln +
        "','pre':'" +
        pre +
        "','ph':'" +
        ph +
        "','pwd':'" +
        CryptoJS.MD5(pwd).toString() +
        "','ir':'" +
        ir +
        "','cd':'" +
        this.state.sign +
        "','ag':'" +
        this.state.agent.email +
        "'}\"";
      axios.post("/regist/app", param).then((res) => {
        if (res.data === "T") {
          this.props.history.push(
            "validate?" + this.state.tk + "=" + this.state.em + "=" + ph
          );
        } else {
          let toast = document.getElementById("error_toast");
          let tit = document.getElementById("error_tit");
          let msg = document.getElementById("error_msg");
          toast.hidden = false;
          tit.innerHTML = this.state.lang.regist_error_title;
          msg.innerHTML = this.state.lang.regist_error_failed;
        }
      });
      let pdi = document.getElementById("inputPassword");
      pdi.type = "password";
      let hdn = document.getElementById("regist_showpwd");
      hdn.disabled = true;
      hdn.hidden = true;
      let btn = document.getElementById("regist_submit");
      btn.disabled = true;
      btn.hidden = true;
    } catch { }
  }
}
