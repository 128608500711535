import React, { Component } from "react";
import { Button, Tabs, Tab } from "react-bootstrap";
import ReactSelect from "react-select";
import axios from "axios";
import {
  gesture,
  datahost,
  def_site,
  pic_host,
  pic_condos0,
  pic_condos1,
} from "../../config/Const.js";
import { JsonToKeyValue, priceSwitch } from "../../function/Basic.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";
import occu from "../../data/occupancy.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/CondoNews.css";

export class CondosList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      cur0pg: 2,
      cur1pg: 2,
      pgsize: 10,
      condos0List: [],
      condos1List: [],
      shoccu: 0, // help Select Value
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadDetail();
  }

  render() {
    return (
      <div class="wrapper">
        <div class="wrapper_margin">
          <div>
            <Button id="pro-btn-mf" onClick={() => this.BackClick()}>
              <i className="fa fa-angle-left"></i>
              {this.state.lang.back}
            </Button>
            <br />
            <br />
          </div>
          <div class="detail_contactsec section_margin">
            <div class="main_headingsec">
              <h1>{this.state.lang.ncdo_title}</h1>
            </div>
            {/* detail_headingsec */}
            <div class="detcontact_fieldsec condodetcont_sec">
              <div class="inputsel_iconsec">
                <i class="fas fa-building"></i>
                <input
                  id="name"
                  type="text"
                  class="inputcont_field"
                  name=""
                  placeholder={this.state.lang.ncdo_project}
                />
              </div>
              <div class="inputsel_iconsec">
                <i class="fas fa-city"></i>
                <input
                  id="city"
                  type="text"
                  class="inputcont_field"
                  name=""
                  placeholder={this.state.lang.ncdo_city}
                />
              </div>
              <div class="inputsel_iconsec">
                <i class="fas fa-house-user"></i>
                <input
                  id="builder"
                  type="text"
                  class="inputcont_field"
                  name=""
                  placeholder={this.state.lang.ncdo_builder}
                />
              </div>
              <div class="inputsel_iconsec">
                <i class="far fa-calendar-check"></i>
                <ReactSelect
                  id="occupancy"
                  class="inputcont_field"
                  style={{ width: "100%" }}
                  placeholder={this.state.lang.ncdo_occuyear}
                  options={JsonToKeyValue(occu, "k", "v")}
                  defaultValue={JsonToKeyValue(occu, "k")[0]}
                  onChange={this.handleOccupancyChange}
                  isSearchable={false}
                ></ReactSelect>
              </div>

              <Button onClick={() => this.CondoSearch()}>
                {this.state.lang.ncdo_search}
              </Button>
            </div>
            {/* detcontact_fieldsec */}

            <br />
            <br />

            <Tabs
              className="tab"
              defaultActiveKey="newcondos1132"
              id="detail-tab-newcondos-title"
            >
              <Tab
                eventKey="newcondos1132"
                className="tabcontent"
                title={this.state.lang.ncdo_category1}
              >
                <div className="listings_sec section_margin">
                  <div className="main_headingsec">
                    {/* <h1>{this.state.lang.ncdo_category1}</h1> */}
                    <p>{this.state.lang.ncdo_cate1des}</p>
                  </div>
                  {/* main_headingsec */}

                  <div className="listing_wrappersec outer_condodetsec">
                    <div id="condos0_list">
                      <ul></ul>
                    </div>
                    <div id="loadmore0">
                      <Button
                        id="pro-btn-mf"
                        style={{ width: "100%" }}
                        onClick={() => this.LoadMore0Click()}
                      >
                        {this.state.lang.loadmore}
                      </Button>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="newcondos1423"
                className="tabcontent"
                title={this.state.lang.ncdo_category2}
              >
                <div className="listings_sec section_margin">
                  <div className="main_headingsec">
                    {/* <h1>{this.state.lang.ncdo_category2}</h1> */}
                    <p>{this.state.lang.ncdo_cate2des}</p>
                  </div>
                  {/* main_headingsec */}

                  <div className="listing_wrappersec outer_condodetsec">
                    <div id="condos1_list">
                      <ul></ul>
                    </div>
                    <div id="loadmore1">
                      <Button
                        id="pro-btn-mf"
                        style={{ width: "100%" }}
                        onClick={() => this.LoadMore1Click()}
                      >
                        {this.state.lang.loadmore}
                      </Button>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
          {/* detail_contactsec */}

          <br />
          {/* <div className="listings_sec section_margin">
            <div className="main_headingsec">
              <h1>Condos Listings</h1>
              <p>Latest Condo Listings of the Builders</p>
            </div>
            <div className="listing_wrappersec outer_condodetsec">
              <div id="condos_list">
                <ul></ul>
              </div>
              <Button
                id="pro-btn-mf"
                style={{ width: "100%" }}
                onClick={() => this.LoadMoreClick()}
              >
                LoadMore
              </Button>
            </div>
          </div> */}
          {/* listings_sec */}
        </div>
        {/* wrapper_margin */}

        <div id="footer">
          <br />
          <br />
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadDetail() {
    try {
      var url = document.location.toString();
      let tk = url.split("?").splice(1, 1)[0];
      this.setState({ tk: tk });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";

      let param1 =
        "\"{'tk':'" +
        tk +
        "','st':'" +
        def_site +
        "','ct':'1132','pg':'" +
        this.state.pgsize +
        ";1'}\"";
      axios.post("/condos/list", param1).then((res) => {
        this.setState({ condos0List: this.fillCondos(res.data) });
        this.createCondos0List(this.state.condos0List);
        if (res.data.length < this.state.pgsize) {
          document.getElementById("loadmore0").hidden = true;
        }
      });

      let param2 =
        "\"{'tk':'" +
        tk +
        "','st':'" +
        def_site +
        "','ct':'1425','pg':'" +
        this.state.pgsize +
        ";1'}\"";
      axios.post("/condos/list", param2).then((res) => {
        this.setState({ condos1List: this.fillCondos(res.data) });
        this.createCondos1List(this.state.condos1List);
        if (res.data.length < this.state.pgsize) {
          document.getElementById("loadmore1").hidden = true;
        }
      });
    } catch {}
  }

  fillCondos = (data) => {
    let str_0 = gesture + document.location.host + "/condosdetail?";
    let str_1 = "=";
    data.forEach((element) => {
      // build and fill link
      element["Link"] = str_0 + this.state.tk + str_1 + element.ProId;
      let ppm = element.Thumbnail.split(",");
      element["Thumbnail"] =
        gesture + pic_host + pic_condos0 + ppm[0] + pic_condos1 + ppm[1];
    });
    return data;
  };

  createCondos0List(arr) {
    var st = document.getElementById("condos0_list");
    if (arr !== null && arr !== []) {
      for (var i = 0; i < arr.length; i++) {
        st.children[0].innerHTML += this.buildCondosElement(arr[i]);
      }
    }
  }

  createCondos1List(arr) {
    var st = document.getElementById("condos1_list");
    if (arr !== null && arr !== []) {
      for (var i = 0; i < arr.length; i++) {
        st.children[0].innerHTML += this.buildCondosElement(arr[i]);
      }
    }
  }

  buildCondosElement(ele) {
    var result = "";
    result += "<li><a href=";
    result += ele.Link;
    result += "><div key=";
    result += ele.ProId;
    result +=
      '><div class="inner_listingsec"><div class="listing_imgsec"><img src="';
    result += ele.Thumbnail;
    result += '" alt="" /></div><div class="listing_detsec"><h2>';
    result += ele.ProName;
    result += '</h2><span><i class="fa fa-map-marker-alt"></i><strong>';
    result += this.state.lang.ncdo_location;
    result += ": </strong>";
    result += ele.Address;
    result += '</span><div class="developer_compsec"><strong>';
    result += this.state.lang.ncdo_startprice;
    result += ": </strong>";
    if (ele.PriceStart === null) {
      result += "N/A";
    } else {
      result += "$" + priceSwitch(ele.PriceStart);
    }
    result += '</div><div class="developer_compnamesec"><strong>';
    result += this.state.lang.ncdo_developer;
    result += ": </strong>";
    if (ele.BuilderName === null) {
      result += "N/A";
    } else {
      result += ele.BuilderName;
    }
    result += '</div><div class="occupancy_datesec"><strong>';
    result += this.state.lang.ncdo_occuyear;
    result += ": </strong>";
    if (ele.OccuYear === null) {
      result += "N/A";
    } else {
      result += ele.OccuYear;
    }
    result += "</div></div></div></div></a></li>";
    return result;
  }

  LoadMore0Click() {
    try {
      this.setState({ cur0pg: parseInt(this.state.cur0pg, 10) + 1 });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param =
        "\"{'tk':'" +
        this.state.tk +
        "','st':'" +
        def_site +
        "','ct':'1132','pg':'" +
        this.state.pgsize +
        ";" +
        this.state.cur0pg +
        "'}\"";
      axios.post("/condos/list", param).then((res) => {
        let addedList = this.fillCondos(res.data);
        this.createCondos0List(addedList);
        for (var i = 0; i < addedList.length; i++) {
          this.state.condos0List.push(addedList[i]);
        }
        if (res.data.length < this.state.pgsize) {
          document.getElementById("loadmore0").hidden = true;
        }
      });
    } catch {}
  }

  LoadMore1Click() {
    try {
      this.setState({ cur1pg: parseInt(this.state.cur1pg, 10) + 1 });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param =
        "\"{'tk':'" +
        this.state.tk +
        "','st':'" +
        def_site +
        "','ct':'1425','pg':'" +
        this.state.pgsize +
        ";" +
        this.state.cur1pg +
        "'}\"";
      axios.post("/condos/list", param).then((res) => {
        let addedList = this.fillCondos(res.data);
        this.createCondos1List(addedList);
        for (var i = 0; i < addedList.length; i++) {
          this.state.condos1List.push(addedList[i]);
        }
        if (res.data.length < this.state.pgsize) {
          document.getElementById("loadmore1").hidden = true;
        }
      });
    } catch {}
  }

  handleOccupancyChange = (selectedOption) => {
    this.setState({
      shoccu: selectedOption.value,
    });
  };

  CondoSearch() {
    // let str_0 = gesture + document.location.host + "/condossearch?";
    let str_0 = "/condossearch?";
    let str_1 = "=";

    var param = "";
    let name = document.getElementById("name").value;
    let city = document.getElementById("city").value;
    let builder = document.getElementById("builder").value;
    let occu = this.state.shoccu;
    if (occu === 0) {
      param += "_;";
    } else {
      param += occu + ";";
    }
    if (name === "") {
      param += "_;";
    } else {
      param += name + ";";
    }
    if (city === "") {
      param += "_;";
    } else {
      param += city + ";";
    }
    if (builder === "") {
      param += "_";
    } else {
      param += builder;
    }
    if (param.length < 8) return;
    let path = str_0 + this.state.tk + str_1 + window.btoa(param);
    this.props.history.push(path);
  }

  BackClick() {
    window.history.go(-1);
  }
}
