import React from "react";
import { Button } from "react-bootstrap";
import { gesture, datahost } from "../../config/Const.js";
import axios from "axios";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "braft-editor/dist/index.css";
import "../../../style/CondoNews.css";

export class NewsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      cate: null,
      curpg: 2,
      pgsize: 5,
      newsList: [],
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadList();
  }

  render() {
    return (
      <div class="wrapper_margin">
        <Button
          id="pro-btn-mf"
          className="new_pagelink_btn"
          onClick={() => this.BackClick()}
        >
          <i className="fa fa-angle-left"></i>
          {this.state.lang.back}
        </Button>
        <div>
          <div>
            <div id="news_list">
              <ul></ul>
            </div>
          </div>
        </div>
        <Button
          id="pro-btn-mf"
          style={{ width: "100%" }}
          onClick={() => this.LoadMoreClick()}
        >
          {this.state.lang.loadmore}
        </Button>
        <div id="footer">
          <br />
          <br />
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadList() {
    try {
      var url = document.location.toString();
      let paras = url.split("?")[1].split("=");
      let tk = paras[0];
      let cate = paras[1];
      this.setState({ tk: tk, cate: cate });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param =
        "\"{'tk':'" +
        tk +
        "','tp':'" +
        cate +
        ";" +
        this.state.pgsize +
        ";1'}\"";
      axios.post("/article/yijunews", param).then((res) => {
        this.setState({ newsList: this.fillNews(res.data) });
        this.createNewsList(this.state.newsList);
      });
    } catch {}
  }

  fillNews = (data) => {
    let str_0 = gesture + document.location.host + "/newsdetail?";
    let str_1 = "=";
    data.forEach((element) => {
      // build and fill link
      element["Link"] = str_0 + this.state.tk + str_1 + element.ArticleId;
    });
    return data;
  };

  createNewsList(arr) {
    var st = document.getElementById("news_list");
    if (arr !== null && arr !== []) {
      for (var i = 0; i < arr.length; i++) {
        st.children[0].innerHTML += this.buildNewsElement(arr[i]);
      }
    }
  }

  buildNewsElement(ele) {
    var result = "";
    result += "<li><div key=";
    result += ele.ArticleId;
    result += "><a href=";
    result += ele.Link;
    result += "><div><div><img src=";
    result += ele.Image;
    result +=
      ' alt="" class="newart_imgsec" /></div><div class="home_articlesec"><h2>';
    result += ele.Title;
    result += "</h2><p>";
    result += ele.Brief;
    result += "</p></div></div></a></div></li>";
    return result;
  }

  LoadMoreClick() {
    try {
      this.setState({ curpg: parseInt(this.state.curpg, 10) + 1 });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param =
        "\"{'tk':'" +
        this.state.tk +
        "','tp':'" +
        this.state.cate +
        ";" +
        this.state.pgsize +
        ";" +
        this.state.curpg +
        "'}\"";
      axios.post("/article/yijunews", param).then((res) => {
        let addedList = this.fillNews(res.data);
        this.createNewsList(addedList);
        for (var i = 0; i < addedList.length; i++) {
          this.state.newsList.push(addedList[i]);
        }
      });
    } catch {}
  }

  BackClick() {
    window.history.go(-1);
  }
}
