import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { D_AppDet } from "./components/view/listing/D_AppDet";
import { D_Listing } from "./components/view/listing/D_Listing";
import { L_Listings } from "./components/view/listing/L_Listings";
import { D_AppDtl } from "./components/view/listing/D_AppDtl";
import { D_ShDtl } from "./components/view/listing/D_ShDtl";

import { ReportChart } from "./components/view/frame/ReportChart";
import { ReportChartSharing } from "./components/view/frame/ReportChartSharing";
import { CensusData2016 } from "./components/view/frame/CensusData2016";
import { CensusData2016Sharing } from "./components/view/frame/CensusData2016Sharing";
import { WalkScore } from "./components/view/frame/WalkScore";
import { WalkScoreSharing } from "./components/view/frame/WalkScoreSharing";
import { FindSchool } from "./components/view/frame/FindSchool";

import { AppMarket } from "./components/view/AppMarket";
import { AppHome } from "./components/view/AppHome";
import { TermOfUse } from "./components/view/about/TermOfUse";
import { PrivacyPolicy } from "./components/view/about/PrivacyPolicy";
import { AboutUs } from "./components/view/about/AboutUs";
import { AgentList } from "./components/view/more/AgentList";
import { DeveloperList } from "./components/view/more/DeveloperList";
import { Calculator } from "./components/view/more/Calculator";

import { QrUpload } from "./components/view/member/QrUpload";
import { Regist } from "./components/view/member/Regist";
// import { Regist } from "./components/view/member/RegistAppleReview";
// import { RegistSkip } from "./components/view/member/RegistAppleSkip";
import { Validate } from "./components/view/member/Validate";
import { Activate } from "./components/view/member/Activate";
import { ChangePwd } from "./components/view/member/ChangePwd";

import { NewsList } from "./components/view/newscondos/NewsList";
import { NewsDetail } from "./components/view/newscondos/NewsDetail";
import { NewsDetailSh } from "./components/view/newscondos/NewsDetailSh";
import { NewsDetailShNt } from "./components/view/newscondos/NewsDetailShNt";
import { CondosList } from "./components/view/newscondos/CondosList";
import { CondosSearch } from "./components/view/newscondos/CondosSearch";
import { CondosDetail } from "./components/view/newscondos/CondosDetail";
import { CondosDetailSh } from "./components/view/newscondos/CondosDetailSh";
import { CondosDetailShNt } from "./components/view/newscondos/CondosDetailShNt";

import { S_L_Article } from "./components/view/article/S_L_Article";
import { S_N_Article } from "./components/view/article/S_N_Article";
import { S_C_Article } from "./components/view/article/S_C_Article";
import { Pre_S_Article_Base } from "./components/view/article/Pre_S_Article_Base";
import { Pre_S_Article } from "./components/view/article/Pre_S_Article";
import { S_Article } from "./components/view/article/S_Article";

import { Pre_S_L_Listings } from "./components/view/listing/Pre_S_L_Listings";
import { Pre_S_D_Listing } from "./components/view/listing/Pre_S_D_Listing";
import { S_L_Listings } from "./components/view/listing/S_L_Listings";
import { S_D_Listing } from "./components/view/listing/S_D_Listing";

import { Pre_S_Report } from "./components/view/report/Pre_S_Report";
import { Pre_S_Report_Base } from "./components/view/report/Pre_S_Report_Base";
import { S_L_Report } from "./components/view/report/S_L_Report";
import { S_Report } from "./components/view/report/S_Report";
import { S_Market } from "./components/view/report/S_Market";

import { AppGuide } from "./components/view/AppGuide";

import { PanoramaView } from "./components/pending/PanoramaView";
import { AppVirtualTour } from "./components/pending/AppVirtualTour";
import { DemoCommunity } from "./components/pending/DemoCommunity";
import { DemoCity } from "./components/pending/DemoCity";
import { AppMarketGrown } from "./components/pending/AppMarketGrown";

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" App />
          <Route path="/appdet" component={D_AppDet} />
          <Route path="/listings" component={L_Listings} />
          <Route path="/listing" component={D_Listing} />
          <Route path="/yiju/gd" component={AppGuide} />
          <Route path="/yiju/dtl" component={D_AppDtl} />
          <Route path="/yiju/shdtl" component={D_ShDtl} />
          <Route path="/yiju/gddtl" component={AppGuide} />
          <Route path="/preshlistings" component={Pre_S_L_Listings} />
          <Route path="/preshlistingdtl" component={Pre_S_D_Listing} />
          <Route path="/shlistings" component={S_L_Listings} />
          <Route path="/yiju/shlidtl" component={S_D_Listing} />
          <Route path="/yiju/gdlidtl" component={AppGuide} />
          <Route path="/preshreport" component={Pre_S_Report} />
          <Route path="/shreport" component={S_Report} />

          <Route path="/reportchart" component={ReportChart} />
          <Route path="/shreportchart" component={ReportChartSharing} />
          {/* <Route path="/census" component={CensusData2016} /> */}
          <Route path="/census2016" component={CensusData2016} />
          <Route path="/shcensus2016" component={CensusData2016Sharing} />
          {/* <Route path="/census2021" component={CensusData2021} />
          <Route path="/shcensus2021" component={CensusData2021Sharing} /> */}
          <Route path="/walkscore" component={WalkScore} />
          <Route path="/shwalkscore" component={WalkScoreSharing} />
          <Route path="/findschool" component={FindSchool} />

          <Route path="/shmarket" component={S_Market} />
          <Route path="/appmarket" component={AppMarket} />

          <Route path="/apphome" component={AppHome} />
          <Route path="/termofuse" component={TermOfUse} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/agentlist" component={AgentList} />
          <Route path="/developerlist" component={DeveloperList} />
          <Route path="/calculator" component={Calculator} />
          <Route path="/shguide" component={AppGuide} />

          <Route path="/newslist" component={NewsList} />
          <Route path="/newsdetail" component={NewsDetail} />
          <Route path="/yiju/shnewsnt" component={NewsDetailShNt} />
          <Route path="/yiju/shnews" component={NewsDetailSh} />
          <Route path="/yiju/gdnews" component={AppGuide} />
          <Route path="/condoslist" component={CondosList} />
          <Route path="/condossearch" component={CondosSearch} />
          <Route path="/condosdetail" component={CondosDetail} />
          <Route path="/yiju/shcondos" component={CondosDetailSh} />
          <Route path="/yiju/shcondosnt" component={CondosDetailShNt} />
          <Route path="/yiju/gdcondos" component={AppGuide} />

          <Route path="/qrupload" component={QrUpload} />
          <Route path="/regist" component={Regist} />
          {/* <Route path="/registskip" component={RegistSkip} /> */}
          <Route path="/validate" component={Validate} />
          <Route path="/activate" component={Activate} />
          <Route path="/chgpwd" component={ChangePwd} />

          <Route path="/sharedmedialist" component={S_L_Article} />
          <Route path="/postsharedmedia" component={S_N_Article} />
          <Route path="/copysharedmedia" component={S_C_Article} />
          <Route path="/preshmediabase" component={Pre_S_Article_Base} />
          <Route path="/preshmedia" component={Pre_S_Article} />
          <Route path="/shmedia" component={S_Article} />
          <Route path="/precustomrepo" component={Pre_S_Report} />
          <Route path="/precustomrepobase" component={Pre_S_Report_Base} />
          <Route path="/customrepolist" component={S_L_Report} />
          <Route path="/shcustomrepo" component={S_Report} />

          <Route path="/democity" component={DemoCity} />
          <Route path="/democommunity" component={DemoCommunity} />
          <Route path="/demogrown" component={AppMarketGrown} />

          <Route path="/appvirtualtour" component={AppVirtualTour} />
          <Route path="/panorama" component={PanoramaView} />
          {/* <Route path="/testlisting" component={TestListing} /> */}
        </Switch>
      </BrowserRouter>
    );
  }
}
