import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/About.css";

export class PrivacyPolicy extends Component {
  render() {
    return (
      <div>
        <p>
          <strong>
            <span>Privacy Policy</span>
          </strong>
        </p>
        <p>
          <span>
            At Yiju Inc., we appreciate your use of and contributions to our
            websites, our mobile and desktop applications, our other properties
            and/or our related services (collectively known as the "Services,"
            or, each individually, a "Service"). Yiju Inc. respects your privacy
            and is committed to protecting your personal information.
          </span>
        </p>
        <p>
          <span>
            We encourage you to read this privacy policy to understand the
            information we collect and how we use and disclose it. This policy
            applies to all of our Services that link to it.
          </span>
        </p>
        <p>
          <span>
            <strong>1. Information Collected by Yiju Inc.</strong>
          </span>
        </p>
        <p>
          <span>
            When you use the Services, we collect a variety of information from
            and about you, your devices, and your interaction with the Services.
            Some of this information identifies you directly or can be used to
            identify you when combined with other data.
          </span>
        </p>
        <ul>
          <li>
            <span>
              <strong>Information you provide.</strong>When using the Services,
              you may be asked to provide personal information about yourself,
              such as your name, contact information, payment information,
              details about your home or properties you are interested in,
              financial information. This may occur, for example, when you
              register on the Services, claim a home, share or save a property,
              correspond with a real estate professional (such as a real estate
              agent or broker, mortgage lender or loan officer, property
              manager, investor, homebuilder, or others) via the Services, or
              complete other forms or transactions, such as a request for loan
              information or a rental housing and background check application.
              You may also provide information about a third party through the
              Services, for example, if you share a real estate listing with a
              recipient via email. We may combine this information with other
              information we collect from your interaction with the Services or
              from other companies.
            </span>
          </li>
        </ul>
        <p>
          <span>
            Some information you provide through the Services is collected and
            processed by third parties on our behalf. For example, when you
            order products or services through the Services, we may need to
            collect your credit or debit card information. This information is
            collected and processed by third-party payment processors. In the
            event that a credit report is required to use a Service, you may be
            asked to provide your Social Insurance Number ("SIN"). When SINs are
            required, we use technology to pass that information directly to the
            third-party providers who need the information to process the credit
            or background check report. If you are a real estate professional,
            you may be able to connect your third-party email account (such as
            Gmail) to your Yiju Inc. account. If you enable that connection,
            Yiju Inc. will access your messages, contacts, and settings to
            provide the requested Services to you.
          </span>
        </p>
        <ul>
          <li>
            <span>
              <strong>
                Cookies, web beacons, and other tracking technologies.
              </strong>
              We and our partners use various technologies to collect
              information automatically when you access and use the Services,
              including cookies, web beacons and other similar technologies.
              Cookies are bits of electronic information that can be transferred
              to your computer or other electronic device to uniquely identify
              your browser. When you use the Services, we and our partners may
              place one or more cookies on your computer or other electronic
              device or use other technologies that provide similar
              functionality. We and our partners may use cookies to connect your
              activity on the Services with other information we store about you
              in your account profile or your prior interactions on the Services
              to, for example, store your preferences. The use of cookies helps
              us improve the quality of the Services to you, by identifying
              information which is most interesting to you, tracking trends,
              measuring the effectiveness of advertising, or storing information
              you may want to retrieve on a regular basis, such as your favorite
              homes. At any time, you may adjust settings on your browser to
              refuse cookies according to the instructions related to your
              browser. However, if you choose to disable cookies, many of the
              free features of the Services will not operate properly.
            </span>
          </li>
        </ul>
        <p>
          <span>
            The pages on the Services may also include web beacons or pixels,
            which are electronic files to count users who have visited that
            page, to track activity over time and across different websites, to
            determine users’ interactions with emails we send, to identify
            certain cookies on the computer or other electronic device accessing
            that page, or to collect other related information, and this
            information may be associated with your unique browser, device
            identifier, or Internet Protocol address. We may, for example,
            implement a pixel on the pages of the Services where you view a
            certain advertisement so that we can track whether you visit a
            website associated with that advertisement at a later time.
          </span>
        </p>
        <ul>
          <li>
            <span>
              <strong>
                Third-party cookies, web beacons, and other tracking
                technologies.
              </strong>
              We work with service providers and advertising networks to track
              and manage cookie information and your activities while you're
              using the Services and your online activities over time and across
              different websites and devices. For example, third parties may use
              cookies to deliver advertisements to you based on your visit to
              the Services. For additional information, please refer to the
              section below entitled, "Interest-Based Advertising." You may
              opt-out of cookie tracking and analysis from any of these service
              providers and advertising networks by&nbsp;Opting Out of
              Collection of Information by Third Parties, which is explained
              further below.
            </span>
          </li>
          <li>
            <span>
              <strong>Mobile device and mobile browser information.</strong>You
              may adjust settings on your mobile device and mobile browser
              regarding cookies and sharing of certain information, such as your
              mobile device model or the language your mobile device uses, by
              adjusting the privacy and security settings on your mobile device.
              Please refer to the instructions provided by your mobile service
              provider or mobile device manufacturer.
            </span>
          </li>
          <li>
            <span>
              <strong>Location Data.</strong>If you enable location services on
              your mobile device, Yiju Inc. may collect the location of your
              device, which we use to provide you with location-based
              information and advertising. If you wish to deactivate this
              feature, you can disable location services on your mobile device.
            </span>
          </li>
          <li>
            <span>
              <strong>Usage logs.</strong>We collect information about your use
              of our Services, including the type of browser you use, access
              times, pages viewed, your IP address and the page you visited
              before navigating to our Services. We also collect information
              about the computer or mobile device you use to access our
              Services, such as the hardware model, operating system and
              version, unique device identifiers, mobile network information,
              and browsing behavior.
            </span>
          </li>
          <li>
            <span>
              <strong>Calls and text messages.</strong>Calls between you and
              Yiju Inc., or between you and third parties made through Yiju Inc.
              (such as real estate agents you contact about a property through a
              form or link on our website, or using contact information found on
              our website), may be recorded or monitored for quality assurance
              and customer service purposes. You will be notified at the
              beginning of a call if it may be recorded or monitored. Yiju Inc.
              uses a third-party service provider to track phone calls and text
              messages between you and real estate professionals so that we and
              the real estate professional can access certain details about the
              contact. As part of this process, Yiju Inc. and its service
              provider will receive in real time and store data about your call
              or text message, including the date and time of the call or text
              message, your phone number, and the content of the text message.
            </span>
          </li>
          <li>
            <span>
              <strong>Public content.</strong>You may provide information
              publicly through the Services, such as when you leave a review for
              a real estate professional, or when you contribute to discussion
              forums.
            </span>
          </li>
          <li>
            <span>
              <strong>Social networks.</strong>If you use the social networking
              connection functions offered through the Services, we may access
              all of your social network profile information that you have made
              available to be shared and to use it in accordance with this
              privacy policy. Please refer to the instructions governing your
              social network account to manage the information that is shared
              through your account.
            </span>
          </li>
        </ul>
        <p>
          <span>
            <strong>Collection of information by third-party services.</strong>
            &nbsp;Some Services require collection of your information directly
            by third parties. By using such Services, you grant Yiju Inc. and
            third parties the right, power, and authority to act on your behalf
            to access and transmit your personal and financial information from
            the relevant financial institution. Your personal and financial
            information will be transferred, stored, and processed by third
            parties in accordance with their privacy policies.
          </span>
        </p>
        <p>
          <span>
            Yiju Inc. will not knowingly collect personally identifiable
            information from any person under the age of 13.
          </span>
        </p>
        <p>
          <span>
            <strong>2. How Yiju Inc. </strong>
            <strong>Uses Your Information</strong>
          </span>
        </p>
        <p>
          <span>
            Yiju Inc. generally uses the information collected about you to
            provide and improve the Services, including to:
          </span>
        </p>
        <ul>
          <li>
            <span>
              provide and deliver the Services, process transactions and send
              related information such as confirmations and invoices;
            </span>
          </li>
          <li>
            <span>
              send you technical notices, updates, security alerts and support
              and administrative messages;
            </span>
          </li>
          <li>
            <span>
              respond to your comments, questions and requests and provide
              customer service;
            </span>
          </li>
          <li>
            <span>
              communicate with you about products, services, offers, promotions,
              rewards and events offered by Yiju Inc. and others, and provide
              news and information we think will be of interest to you;
            </span>
          </li>
          <li>
            <span>
              monitor and analyze trends, usage and activities in connection
              with our Services;
            </span>
          </li>
          <li>
            <span>
              revise, modify, and update existing Services and develop new
              Services;
            </span>
          </li>
          <li>
            <span>
              detect, investigate and prevent fraudulent transactions and other
              illegal activities and protect the rights and property of Yiju
              Inc. and others;
            </span>
          </li>
          <li>
            <span>
              personalize the Services and present you with advertising, content
              or features that we believe will be of interest or useful to you;
            </span>
          </li>
          <li>
            <span>
              facilitate contests, sweepstakes and promotions and process and
              deliver entries and rewards;
            </span>
          </li>
          <li>
            <span>
              link or combine with information we get from others to help
              understand your needs and provide you with better service; and
            </span>
          </li>
          <li>
            <span>
              carry out any other purpose described to you at the time the
              information was collected.
            </span>
          </li>
        </ul>
        <p>
          <span>
            <strong>Email Management.</strong>&nbsp;You may receive email from
            Yiju Inc. for a variety of reasons - for example, if you took an
            action through the Services, you signed up for a regular report, or
            you posted your home for sale and a buyer sent you a message. If you
            have an account with Yiju Inc., you can select your preferences
            through your account settings. Also, you can manage your receipt of
            some types of communication by following the instructions included
            in the email we send you. Please note that, even if you unsubscribe
            from certain email correspondences, we may still need to email you
            with important transactional or administrative information.
          </span>
        </p>
        <p>
          <span>
            <strong>3. When Yiju Inc.</strong>
            <strong>Shares and Discloses Your Information</strong>
          </span>
        </p>
        <p>
          <span>
            Your privacy is important and we are committed to protecting your
            information that identifies you personally. We will only share
            personal information that you provide outside the public areas of
            the Services in the following circumstances:
          </span>
        </p>
        <ul>
          <li>
            <span>
              <strong>With your consent.</strong>When you consent or direct Yiju
              Inc. to share the personal information. This happens when you
              submit your information through many of our Services. For example,
              if you choose to contact a real estate agent, mortgage lender,
              investor, builder, property manager, or other real estate
              professional through the Services, your name, phone number, email
              address, and message content will appear to the recipient of the
              message. Similarly, if you apply for rental housing through the
              Services, your application information will be sent to prospective
              landlords.
            </span>
          </li>
          <li>
            <span>
              <strong>Service providers to Yiju Inc. .</strong>When Yiju Inc.
              hires a service provider to help operate the Services or our
              business, Yiju Inc. may give access to personal information only
              as appropriate to perform the service for Yiju Inc., and subject
              to the terms of this privacy policy. Yiju Inc. always remains
              responsible for the privacy of your personal information that is
              shared with service providers.
            </span>
          </li>
          <li>
            <span>
              <strong>Partners with whom we conduct business.</strong>When Yiju
              Inc. partners with other businesses to offer products and
              services, we may share information with those business partners
              only as needed to provide those products and services and only
              subject to the terms of this privacy policy.
            </span>
          </li>
          <li>
            <span>
              <strong>Legal obligation or protection from harm.</strong>When
              Yiju Inc. has a good faith belief that access, use, preservation
              or disclosure of information is reasonably necessary to (a)
              satisfy any requirement of law, regulation, legal process, or
              enforceable governmental request, (b) enforce or investigate a
              potential violation of the Terms of Use, (c) detect, prevent, or
              otherwise respond to fraud, security or technical concerns, (d)
              support auditing and compliance functions, or (e) protect the
              rights, property, or safety of Yiju Inc., its users, or the public
              against harm.
            </span>
          </li>
          <li>
            <span>
              <strong>Business transfers.</strong>If and when Yiju Inc. is
              involved in a merger, acquisition, or any form of transfer or sale
              of some or all of its business, whether as a going concern or as
              part of bankruptcy, liquidation, or similar proceeding, we may
              share your personal information in connection with that
              transaction.
            </span>
          </li>
        </ul>
        <p>
          <span>
            Yiju Inc. may also share aggregated or de-identified information
            that cannot reasonably be used to identify you.
          </span>
        </p>
        <p>
          <span>
            <strong>4. Interest-Based Advertising</strong>
          </span>
        </p>
        <p>
          <span>
            We participate in interest-based advertising. This means that when
            you use the Services, we or third party service providers or
            advertisers may use cookies (that they collect or that we provide to
            them) or other similar technologies to collect information about
            your use of the Services (including mobile applications) or your
            online activities over time and across different websites and
            devices. Collected information may include the content you view, the
            date and time that you view this content, and the website that
            referred you to the Services, and this information may be associated
            with your unique browser, device identifier, or Internet Protocol
            (IP) address. These practices help tailor advertisements that are
            relevant and useful to you. These tailored advertisements may appear
            on the Services or on other websites, applications or properties.
          </span>
        </p>
        <p>
          <span>
            In addition, if you have provided your email address to us, we may
            use a scrambled, unreadable form (a hash) of your email address to
            deliver tailored advertisements to you on the Services or on other
            websites, including via Facebook’s Custom Audience Feature or other
            similar tools. We do not access or collect any of the contents of
            your email messages in providing interest-based advertising. Our use
            of information received from Gmail APIs will adhere to Google’s
            Limited Use Requirements.
          </span>
        </p>
        <p>
          <span>
            You may opt-out of cookie tracking and analysis as described in the
            section below entitled 'Opting Out of Collection of Information by
            Third Parties'. The Services do not otherwise respond to ‘do not
            track’ signals.
          </span>
        </p>
        <p>
          <span>
            <strong>
              5. Opting Out of Collection of Information by Third Parties
            </strong>
          </span>
        </p>
        <p>
          <span>
            Yiju Inc.’s third-party ad servers, ad network providers,
            third-party advertisers may provide you with advertisements that you
            may see on the Services or on other affiliated websites. To improve
            the relevancy and help measure the effectiveness of such
            advertisements, the Yiju Inc. Ad Providers may use cookies, web
            beacons, clear gifs or similar technologies. These are used to
            record users' activity, such as the pages visited, and to learn what
            types of information are of most interest to the users.
          </span>
        </p>
        <p>
          <span>
            <strong>6. Third-Party Links and Websites</strong>
          </span>
        </p>
        <p>
          <span>
            Throughout the Services, we may link to the websites of other
            companies and/or individuals. Further, certain functionalities on
            the Services may involve the distribution of your listing
            information to third-party websites. These third-party websites may
            collect information about users on those websites, and Yiju Inc.’s
            privacy policy does not extend to these external websites and third
            parties. Please refer directly to these third parties and websites
            regarding their privacy policies.
          </span>
        </p>
        <p>
          <span>
            <strong>7. Security and Retention of Information</strong>
          </span>
        </p>
        <p>
          <span>
            Yiju Inc. takes reasonable steps to protect the information users
            share with us from unauthorized use, access, and disclosure, both
            during transmission and at rest. However, no transmission of
            information via the Internet nor electronic storage solution can be
            entirely secure, so please be aware that we cannot guarantee
            absolute security.
          </span>
        </p>
        <p>
          <span>
            You can access, update, and delete personal information you provide
            to Yiju Inc. &nbsp;in your account profile by logging into your
            account on the relevant Yiju Inc. Service. We may maintain a copy of
            the original version of your information in our records.
          </span>
        </p>
        <p>
          <span>
            We will retain your information for as long as necessary to fulfill
            the purposes outlined in this Privacy Policy unless a longer
            retention period is required or permitted by law.
          </span>
        </p>
        <p>
          <span>
            <strong>8. Transfer of Information to the Canada</strong>
          </span>
        </p>
        <p>
          <span>
            Yiju Inc. is based in the Canada and you acknowledge that the
            information we collect is governed by Canadian law. By accessing or
            using the Services or otherwise providing information to us, you
            consent to the processing, transfer, and storage of information in
            and to the Canada, where you may not have the same rights and
            protections as you do under local law.
          </span>
        </p>
        <p>
          <span>
            <strong>
              9. Government and Private Party Information Requests
            </strong>
          </span>
        </p>
        <p>
          <span>
            Yiju Inc. occasionally receives various forms of legal process from
            government agencies and private parties requesting information about
            Yiju Inc. users. Before we produce information, we will attempt to
            notify affected users and give them an opportunity to object unless
            notification is prohibited by law or if we believe that notification
            would create a risk of harm or be otherwise counterproductive.
          </span>
        </p>
        <p>
          <span>
            <strong>10. Contacting Yiju Inc.</strong>
          </span>
        </p>
        <p>
          <span>
            If you have any questions about this privacy policy, or the privacy
            practices of Yiju Inc., please email us at&nbsp;contact@yiju.ca, or
            you can write to us at:
          </span>
        </p>
        <p>
          <span>Yiju Inc.</span>
        </p>
        <p>
          <span>
            5100 Orbitor Drive, Suite 306 Mississauga, Ontario Canada L4W 4Z4
          </span>
        </p>
        <p>
          <span>
            <strong>11. Changes to this Privacy Policy</strong>
          </span>
        </p>
        <p>
          <span>
            Please note that this policy may be amended from time to time. You
            should check back for the most current version before relying on any
            of the provisions in this privacy policy. We will provide notice of
            material changes to the policy, either by posting a notice on our
            websites, by sending an email, or some other reasonable method.
          </span>
        </p>
        <p>
          <span></span>
        </p>

        <div id="footer"></div>
      </div>
    );
  }
}
