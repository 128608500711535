export function ReadValue(key) {
  if (key === null || key === undefined || key === "null") {
    return "";
  } else {
    return key;
  }
}

export function CheckValue(key) {
  if (key === "Y") {
    // return '✔';
    return "Yes";
  } else {
    // return '✗';
    return "No";
  }
}

export function JsonToKeyValue(obj, key, val) {
  var re = [];
  for (var i = 0; i < obj.length; i++) {
    var kvobj = { value: obj[i][val], label: obj[i][key] };
    re.push(kvobj);
  }
  return re;
}

export function ShowHideDIV(div) {
  var st = document.getElementById(div);
  if (st !== null) {
    if (st.hidden === true) {
      st.hidden = false;
    } else {
      st.hidden = true;
    }
  }
}

export function priceSwitch(x) {
  if (/^\d+$/.test(x)) {
    var leftNum = x.toString();
    var result;
    var resultArray = new Array();
    if (leftNum.length > 3) {
      var i = true;
      while (i) {
        resultArray.push(leftNum.slice(-3));
        leftNum = leftNum.slice(0, leftNum.length - 3);
        if (leftNum.length < 4) {
          i = false;
        }
      }
      var sortArray = new Array();
      for (var j = resultArray.length - 1; j >= 0; j--) {
        sortArray.push(resultArray[j]);
      }
      result = leftNum + "," + sortArray.join(",");
    } else {
      result = x.toString();
    }
    return result;
  }
  return x;
}

// export function replaceAllFixed(str, substr, newstr) {
//   var p = -1;
//   var s = 0;
//   while ((p = str.indexOf(substr, s)) > -1) {
//     s = p + newstr.length;
//     str = str.replace(substr, newstr);
//   }
//   return str;
// }

export function replaceAllFixed(str, substr, newstr) {
  substr = substr.replace(/[.\\[\]{}()|^$?*+]/g, "\\$&");
  var re = new RegExp(substr, "g");
  return str.replace(re, newstr);
}

// String.prototype.replaceAll = function (s1, s2) {
//   return this.replace(new RegExp(s1, "gm"), s2);
// };

export function rmHtmlTag(str) {
  return str.replace(/<[^>]+>/g, "");
}

// export
// function findKey (obj,value, compare = (a, b) => a === b) {  return Object.keys(obj).find(k => compare(obj[k], value))
// }

// export function testtest(obj, key, value) {
//   for (var i = 0; i < obj.length; i++) {
//     if (obj[i][key] === value) {
//       return obj[i];
//     }
//   }
// }

export function dateConvert(date) {
  const tmp = new Date(date);
  const monthArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${monthArr[tmp.getMonth()]} ${tmp.getDate()}, ${tmp.getFullYear()}`;
}

export function moneyConvert(money) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(money);
}

export function domCalculate(d1, d2) {
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  const Difference_In_Time = date1.getTime() - date2.getTime();
  let dom = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
  if (dom < 0) {
    dom = 0;
  }
  return dom;
}
