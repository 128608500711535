export const gesture = "https://";
export const datahost = "https://api.yjezimoc.com";
export const shkyhost = "https://wechat.canadayiju.com";

// export const gesture = "http://";
// export const datahost = "https://intapi2.1ju.ca";
// export const shkyhost = "https://intview2.1ju.ca";

export const def_site = "188888";
export const pic_host = "yiju.ca";
export const pic_link = "/eziapp/appimg.aspx?sid=188888&t=";
export const pic_sharing = "/eziapp/appimg.aspx?sid=188888&st=";
export const pic_condos0 = "/aprg/newproject/photo.aspx?sid=188888&pid=";
export const pic_condos1 = "&ptoid=";
export const findschoolhost = "www.yijuca.com";
export const google_trans_api = "AIzaSyBU9o77ki36IETtIfA6jDT4CrpXh8uZ64E";
export const google_trans_pro = "translationbasic";
export const google_trans_email = "eziagent@gmail.com";

export const VOW = 1;
export const DDF = 5;
