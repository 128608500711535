import React, { Component } from "react";
import { ProgressBar, Image } from "react-bootstrap";
import axios from "axios";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import wx from "weixin-js-sdk";
import { datahost, shkyhost } from "../../config/Const.js";
import { replaceAllFixed } from "../../function/Basic.js";
import {
  SetupPriceChanges,
  SetupBothDistribution,
  SetChartListing,
  SetChartPrice,
  SetChartMarket,
  SetChartPartition,
} from "../../function/Chart.js";
import { createContact } from "../../function/Share.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/Chart.css";

export class S_Market extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      reports: [],
      distrib: null,
      loaded: false,
      sh: null,
      bk: null,
      bks: null,
      ls: null, // leased / sold
      st: null, // start date
      ed: null, // end date
      tp: null, // nodetype
      usr: null,
    };

    this.radioSold = this.radioSold.bind(this);
    this.radioLeased = this.radioLeased.bind(this);
  }

  componentDidMount() {
    this.initLanguage();
    this.loadPage();
  }

  render() {
    return (
      <div>
        <div id="reportchart">
          <RadioGroup
            style={{ marginLeft: "30%" }}
            row
            aria-label="position"
            name="position"
            defaultValue="S"
          >
            <FormControlLabel
              style={{ width: "35%", float: "left" }}
              value="S"
              control={<Radio color="primary" />}
              label={this.state.lang["radio_sold"]}
              onClick={this.radioSold}
            />
            <FormControlLabel
              style={{ width: "35%", float: "left" }}
              value="L"
              control={<Radio color="primary" />}
              label={this.state.lang["radio_leased"]}
              onClick={this.radioLeased}
            />
          </RadioGroup>
        </div>
        <br />

        <div
          id="app_title"
          style={{
            marginLeft: "0.5rem",
            width: document.body.clientWidth - 16,
          }}
        >
          <div id="title_block">{this.state.bks}</div>
          <br />
          <div id="title_median">
            <div>{this.state.lang["chart_tit_average"]}</div>
            <div id="tit_median"></div>
          </div>
          <br />
          <div id="title_stats">
            <div>{this.state.lang["chart_tit_stats"]}</div>
            <div style={{ width: "65%", float: "left" }}>
              {this.state.lang["chart_tit0"]}
            </div>
            <div
              id="tit_stats0"
              style={{ width: "35%", float: "left", textAlign: "end" }}
            ></div>
            <br />
            <div style={{ width: "65%", float: "left" }}>
              {this.state.lang["chart_tit1"]}
            </div>
            <div
              id="tit_stats1"
              style={{ width: "35%", float: "left", textAlign: "end" }}
            ></div>
            <br />
            <div style={{ width: "65%", float: "left" }}>
              {this.state.lang["chart_tit2"]}
            </div>
            <div
              id="tit_stats2"
              style={{ width: "35%", float: "left", textAlign: "end" }}
            ></div>
            <br />
            <div style={{ width: "65%", float: "left" }}>
              {this.state.lang["chart_tit3"]}
            </div>
            <div
              id="tit_stats3"
              style={{ width: "35%", float: "left", textAlign: "end" }}
            ></div>
            <br />
            <div style={{ width: "65%", float: "left" }}>
              {this.state.lang["chart_tit4"]}
            </div>
            <div
              id="tit_stats4"
              style={{ width: "35%", float: "left", textAlign: "end" }}
            ></div>
            <br />
            <div style={{ width: "65%", float: "left" }}>
              {this.state.lang["chart_tit5"]}
            </div>
            <div
              id="tit_stats5"
              style={{ width: "35%", float: "left", textAlign: "end" }}
            ></div>
            <br />
            <div style={{ width: "65%", float: "left" }}>
              {this.state.lang["chart_tit6"]}
            </div>
            <div
              id="tit_stats6"
              style={{ width: "35%", float: "left", textAlign: "end" }}
            ></div>
            <br />
            <div id="tit_stats7" style={{ float: "left", textAlign: "end" }}>
              ▼
            </div>
            <br />
            <div style={{ width: "100%" }}>
              <ProgressBar>
                <ProgressBar striped variant="info" now="40" />
                <ProgressBar striped variant="warning" now="20" />
                <ProgressBar striped variant="danger" now="40" />
              </ProgressBar>
            </div>
            <div style={{ width: "50%", float: "left" }}>
              {this.state.lang["chart_tit7_0"]}
            </div>
            <div style={{ width: "50%", float: "left", textAlign: "end" }}>
              {this.state.lang["chart_tit7_1"]}
            </div>
            <br />
          </div>
        </div>
        <br />
        <div
          id="app_chart"
          style={{
            marginLeft: "0.5rem",
            width: document.body.clientWidth - 16,
          }}
        >
          <div
            id="chart_listing"
            style={{ height: document.body.clientWidth }}
          ></div>
          <div
            id="chart_price"
            style={{ height: document.body.clientWidth }}
          ></div>
          <div
            id="chart_market"
            style={{ height: document.body.clientWidth }}
          ></div>
          <div
            id="chart_partition"
            style={{ width: "100%", height: document.body.clientWidth }}
          ></div>
          <div id="pie_range">
            <p
              id="input_st"
              type="text"
              style={{ width: "40%", float: "left", textAlign: "center" }}
            ></p>
            <div style={{ width: "19%", float: "left", textAlign: "center" }}>
              ~
            </div>
            <p
              id="input_ed"
              type="text"
              style={{ width: "40%", float: "left", textAlign: "center" }}
            ></p>
            <br />
            <br />
            <br />
          </div>
          <div
            id="chart_soldprice"
            style={{
              width: "100%",
              height: (document.body.clientWidth * 2) / 3,
            }}
          ></div>
          <div
            id="chart_asksprice"
            style={{
              width: "100%",
              height: (document.body.clientWidth * 2) / 3,
            }}
          ></div>
        </div>
        <br />
        <div className="agent_detsec section_margin" id="shared_contact">
          <div className="mainagent_leftsec">
            <div className="outer_agentdet_sec">
              <div className="main_agentpic_sec">
                <Image id="contact_hd" />
              </div>
              <div className="main_agentdetsec">
                <h2 id="contact_name"></h2>
                <h3 id="contact_title"></h3>
                <p id="contact_company"></p>
              </div>
            </div>
            <div className="agent_description_mainsec">
              <h5>{this.state.lang.share_introduction}</h5>
              <p id="contact_introduction"></p>
            </div>
            <div className="agentfull_detsec">
              <ul>
                <li>
                  <a id="contact_message">
                    <i className="fa fa-comments"></i>
                    <p>{this.state.lang.share_message}</p>
                  </a>
                </li>
                <li>
                  <a id="contact_phone">
                    <i className="fa fa-phone-alt"></i>
                    <p>{this.state.lang.share_phone}</p>
                  </a>
                </li>
                <li>
                  <a id="contact_email">
                    <i className="fa fa-envelope"></i>
                    <p>{this.state.lang.share_email}</p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="mainqrcode_sec">
              <h2>[ {this.state.lang.share_scanqr} ]</h2>
              <img id="contact_qr" width="30%" thumbnail="true" />
            </div>
          </div>
        </div>

        <div className="bottom_sliding_sec">
          <div className="yiju_logosec pull-left">
            <img src={require("../../../images/yiju_logo.png")} alt="" />
            <p>
              {this.state.lang.yiju}
              {/* <i className="fa fa-angle-double-right"></i> */}
            </p>
          </div>
          <div className="detailapp_btn pull-right">
            <button
              type="button"
              className="common_mainbtn btn btn-primary"
              onClick={() => {
                this.JumpToGuide();
              }}
            >
              {this.state.lang.dtl_detailsinapp}
            </button>
          </div>
        </div>

        <div hidden={true}>
          <p>
            <i id="wx_title"></i>
            <i id="wx_desc"></i>
            <i id="wx_imgurl"></i>
            <i id="wx_link"></i>
          </p>
        </div>

        <div id="footer">
          <br />
          <br />
        </div>
      </div>
    );
  }

  async loadPage() {
    try {
      var url = document.location.toString();
      document.getElementById("wx_link").innerText = url;
      let paras = url.split("?").splice(1, 1)[0].split("#")[0].split("=");
      if (paras.length < 2) {
        return;
      }
      let sh = paras[0];
      let tmpparas1 = replaceAllFixed(paras[1], "~", "=");
      let ngw = window.atob(tmpparas1).split(";");
      let tmp = ngw[3].split("-");
      let st = tmp[0] - ngw[4] + "-" + tmp[1] + "-" + tmp[2];
      // Refresh Chart
      this.refreshChart(sh, ngw[1], ngw[2], st, ngw[3]);
      // Set Title
      var bkrm = ngw[1].replace(",", "");
      if (ngw[5] === "1") {
        bkrm = bkrm + ", " + this.state.lang.detached;
      } else if (ngw[5] === "2") {
        bkrm = bkrm + ", " + this.state.lang.semi;
      } else if (ngw[5] === "3") {
        bkrm = bkrm + ", " + this.state.lang.townhouse;
      } else if (ngw[5] === "4") {
        bkrm = bkrm + ", " + this.state.lang.condo;
      } else if (ngw[5] === "5") {
        bkrm = bkrm + ", " + this.state.lang.others;
      } else {
        // DO NOTHING
      }
      // Save State
      this.setState({
        sh: sh,
        bk: ngw[1],
        bks: bkrm,
        ls: ngw[2],
        st: st,
        ed: ngw[3],
        tp: ngw[5],
      });
      // prepare title and brief for WX
      let title = this.state.lang.rpt_title_def;
      var brief =
        this.state.ed.substring(0, this.state.ed.length - 3) + " " + bkrm;
      document.getElementById("wx_title").innerText = title;
      document.getElementById("wx_desc").innerText = brief;
      document.getElementById("wx_imgurl").innerText =
        "https://api.yjezimoc.com/shared/default.png";
    } catch {}
  }

  refreshChart(sh, bk, ls, st, ed) {
    try {
      bk = replaceAllFixed(bk, "'", "`");

      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";

      let param_dis =
        "\"{'sh':'" +
        sh +
        "','id':'" +
        bk +
        "','ls':'" +
        ls +
        "','dt':'" +
        ed +
        "'}\"";
      axios.post("/share/pricedistribution", param_dis).then((res) => {
        this.setState({
          distrib: res.data,
        });
        this.PartByDistribution();
      });

      let param_dtl =
        "\"{'sh':'" +
        sh +
        "','id':'" +
        bk +
        "','ls':'" +
        ls +
        "','st':'" +
        st +
        "','ed':'" +
        ed +
        "'}\"";
      axios.post("/share/monthlyreport", param_dtl).then((res) => {
        this.setState({
          reports: res.data,
        });
        this.initDefaultSection();
      });

      let param = "\"{'sh':'" + sh + "'}\"";
      axios.post("/share/media", param).then((res) => {
        this.setState({ usr: res.data.usr });
        // Set contact user
        if (this.state.usr !== undefined) {
          createContact(this.state.usr);
          document.getElementById("shared_contact").hidden = false;
        } else {
          document.getElementById("shared_contact").hidden = true;
        }
      });

      // WxShare Configuration
      axios.defaults.baseURL = shkyhost;
      axios
        .get(
          "/wx/wxconfig?url=" +
            encodeURI(document.getElementById("wx_link").innerText)
        )
        .then((resWC) => {
          wx.config({
            appId: resWC.data.appid,
            timestamp: resWC.data.timestamp,
            nonceStr: resWC.data.nonceStr,
            signature: resWC.data.signature,
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
          });

          wx.ready(() => {
            wx.updateAppMessageShareData({
              title: document.getElementById("wx_title").innerText,
              desc: document.getElementById("wx_desc").innerText,
              link: document.getElementById("wx_link").innerText,
              imgUrl: document.getElementById("wx_imgurl").innerText,
              success: () => {},
            });

            wx.updateTimelineShareData({
              title: document.getElementById("wx_title").innerText,
              link: document.getElementById("wx_link").innerText,
              imgUrl: document.getElementById("wx_imgurl").innerText,
              success: () => {},
            });
          });
        });
    } catch {}
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  initDefaultSection() {
    this.PartByNodeType(this.state.reports, this.state.tp);
    document.getElementById("input_st").innerText = this.state.st.substring(
      0,
      this.state.st.length - 3
    );
    document.getElementById("input_ed").innerText = this.state.ed.substring(
      0,
      this.state.ed.length - 3
    );
    this.PartByDateRange(this.state.reports, this.state.st, this.state.ed);
  }

  radioSold() {
    this.refreshChart(
      this.state.sh,
      this.state.bk,
      "S",
      this.state.st,
      this.state.ed
    );
    this.setState({
      ls: "S",
    });
  }

  radioLeased() {
    this.refreshChart(
      this.state.sh,
      this.state.bk,
      "L",
      this.state.st,
      this.state.ed
    );
    this.setState({
      ls: "L",
    });
  }

  PartByNodeType(list, type) {
    var month = [];
    var soldL = [];
    var newL = [];
    var activeL = [];
    var soldDOM = [];
    var soldMP = [];
    var soldAP = [];
    var newMP = [];
    var newAP = [];
    var soldMax = [];
    var soldMin = [];
    var newMax = [];
    var newMin = [];
    var absorb = [];
    for (var i = 0; i < list.length; i++) {
      // === will check type. == only check value.
      if (list[i].NodeType == type) {
        month.push(list[i].Month.substring(0, 7));
        soldL.push(list[i].Sold);
        newL.push(list[i].New);
        activeL.push(list[i].Active);
        soldDOM.push(list[i].SoldAverageDom);
        soldMP.push(list[i].SoldMedianPrice);
        soldAP.push(list[i].SoldAveragePrice);
        newMP.push(list[i].NewMedianPrice);
        newAP.push(list[i].NewAveragePrice);
        soldMax.push(list[i].SoldMaxPrice);
        soldMin.push(list[i].SoldMinPrice);
        newMax.push(list[i].NewMaxPrice);
        newMin.push(list[i].NewMinPrice);
        if (list[i].New === null || list[i].New === 0) {
          absorb.push(52);
        } else {
          absorb.push(
            ((list[i].Sold / list[i].New) * 100).toString().substring(0, 5)
          );
        }
      }
    }

    document.getElementById("tit_stats0").innerHTML = month[month.length - 1];
    document.getElementById("tit_stats1").innerHTML = newL[newL.length - 1];
    document.getElementById("tit_stats2").innerHTML = soldL[soldL.length - 1];
    document.getElementById("tit_stats3").innerHTML = soldMP[soldMP.length - 1];
    document.getElementById("tit_stats4").innerHTML =
      soldDOM[soldDOM.length - 1];
    document.getElementById("tit_stats5").innerHTML =
      soldMax[soldMax.length - 1];
    document.getElementById("tit_stats6").innerHTML =
      soldMin[soldMin.length - 1];
    // document.getElementById("tit_stats7").innerHTML = absorb[absorb.length - 1];
    // document.getElementById("tit_stats7").children[0].innerHTML = this.MarketTempBar(23.3);
    // let abs = absorb[absorb.length-1];
    document.getElementById("tit_stats7").style.width =
      absorb[absorb.length - 1] + "%";

    // Color and XAxis(Year)
    // var colors = ['#d14a61', '#5793f3', '#f8f2b8', '#54b936', '#EB68CC', '#E8BBF2', '#FEFF82', '#DF61AA', '#FA7292', '#9AE9BC', '#675bba', '#36A1D9', '#32C5E8', '#f8f2b8', '#bc7768', '#36A1D9', '#32C5E8', '#65E1E3', '#FF6567', '#A093F2', '#8675E7', '#9BBCFF'];
    var years = month;

    // Listing LINE&BAR
    var listingTitle = [
      this.state.lang["chart1_0"],
      years[0] + "~" + years[years.length - 1],
    ];
    var chart1_1 = this.state.lang["chart1_1"];
    if (this.state.ls === "L") {
      chart1_1 = this.state.lang["chart1_1l"];
    }
    var listingLegendSeries = [
      chart1_1,
      this.state.lang["chart1_2"],
      this.state.lang["chart1_3"],
    ];
    var listingYAxis = [
      this.state.lang["chart1_4"],
      this.state.lang["chart1_5"],
    ];
    var listingData = [soldL, newL, soldDOM];
    var listingColor = ["#FFB26B", "#b06a13", "#fcc41b"];
    SetChartListing(
      listingTitle,
      listingLegendSeries,
      years,
      listingYAxis,
      listingData,
      listingColor,
      "chart_listing"
    );

    // Price LINE
    var priceTitle = [
      this.state.lang["chart2_0"],
      years[0] + "~" + years[years.length - 1],
    ];
    var chart2_1 = this.state.lang["chart2_1"];
    if (this.state.ls === "L") {
      chart2_1 = this.state.lang["chart2_1l"];
    }
    var chart2_2 = this.state.lang["chart2_2"];
    if (this.state.ls === "L") {
      chart2_2 = this.state.lang["chart2_2l"];
    }
    var priceLegendSeries = [
      chart2_1,
      chart2_2,
      this.state.lang["chart2_3"],
      this.state.lang["chart2_4"],
    ];
    var priceData = [soldMP, soldAP, newMP, newAP];
    var priceColor = ["#FFB26B", "#b06a13", "#fcc41b", "#fc601b"];
    SetChartPrice(
      priceTitle,
      priceLegendSeries,
      years,
      priceData,
      priceColor,
      "chart_price"
    );

    // Market LINE
    var chart3_0 = this.state.lang["chart3_0"];
    if (this.state.ls === "L") {
      chart3_0 = this.state.lang["chart3_0l"];
    }
    var marketTitle = [chart3_0, years[0] + "~" + years[years.length - 1]];
    var marketData = absorb;
    var marketColor = ["#FFB26B", "#b06a13", "#fcc41b"];
    SetChartMarket(marketTitle, years, marketData, marketColor, "chart_market");

    // Setup Price Changes
    SetupPriceChanges(soldAP);
  }

  PartByDateRange(list, mstart, mend) {
    var tms = mstart.split("-");
    var start = new Date(tms[0], tms[1] - 1, 1);
    var tme = mend.split("-");
    var end = new Date(tme[0], tme[1] - 1, 1);
    var range = [];
    for (var i = 0; i < list.length; i++) {
      var tmonth = list[i].Month.split("T")[0].split("-");
      var curMonth = new Date(tmonth[0], tmonth[1] - 1, 1);
      if (curMonth >= start && curMonth <= end) {
        range.push(list[i]);
      }
    }
    var fillData = [];
    for (var k = 1; k < 6; k++) {
      var typeSum = 0;
      for (var j = 0; j < range.length; j++) {
        if (range[j].NodeType === k) {
          typeSum += range[j].Sold;
        }
      }
      fillData.push(typeSum);
    }
    // Partition PIE
    var pieColors = [
      "#FFB26B",
      "#b06a13",
      "#fcc41b",
      "#fc601b",
      "#b04313",
      "#ffe6c6",
    ];
    var chart4_0 = this.state.lang["chart4_0"];
    if (this.state.ls === "L") {
      chart4_0 = this.state.lang["chart4_0l"];
    }
    var pieTitle = [chart4_0, mstart + "~" + mend];
    var pieLegend = [
      this.state.lang["detached"],
      this.state.lang["semi"],
      this.state.lang["townhouse"],
      this.state.lang["condo"],
      this.state.lang["others"],
    ];
    var pieFillData = fillData;
    var pieData = [
      { name: pieLegend[0], value: pieFillData[0] },
      { name: pieLegend[1], value: pieFillData[1] },
      { name: pieLegend[2], value: pieFillData[2] },
      { name: pieLegend[3], value: pieFillData[3] },
      { name: pieLegend[4], value: pieFillData[4] },
    ];
    SetChartPartition(
      pieTitle,
      pieLegend,
      pieData,
      pieColors,
      "chart_partition"
    );
  }

  PartByDistribution() {
    var rg = this.state.ed.substring(0, this.state.ed.length - 3);
    var chart5_0 = this.state.lang["chart5_0"];
    if (this.state.ls === "L") {
      chart5_0 = this.state.lang["chart5_0l"];
    }
    var titles = [chart5_0, rg, this.state.lang["chart6_0"], rg];
    var distriColors = ["#FFB26B", "#b06a13"];
    SetupBothDistribution(
      titles,
      this.state.distrib,
      distriColors,
      "chart_soldprice",
      "chart_asksprice"
    );
  }
}
