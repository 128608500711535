import React, { Component } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Base64 } from "js-base64";
import { SetChartLineGrown, SetChartBarGrown } from "../function/Chart.js";
import { datahost, shkyhost } from "../config/Const.js";
import enj from "../language/en.json";
import zhj from "../language/zh.json";
import colors from "../data/colors.json";
import "bootstrap/dist/css/bootstrap.min.css";

export class AppMarketGrown extends Component {
  static displayName = AppMarketGrown.name;

  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadPage();
  }

  render() {
    return (
      <div className="wrapper">
        <div className="wrapper_margin">
          <div>
            <Button id="pro-btn-mf" onClick={() => this.BackClick()}>
              <i className="fa fa-angle-left"></i>
              {this.state.lang.back}
            </Button>
            <br />
            <br />
          </div>
          <div>
            <div
              className="ChartMonthly"
              style={{
                marginLeft: "0.5rem",
                width: document.body.clientWidth - 16,
              }}
            >
              <hr />
              <div
                id="chart_area0"
                style={{ height: document.body.clientWidth * 1 }}
              ></div>
              <hr />
              <div
                id="chart_area1"
                style={{ height: document.body.clientWidth * 1 }}
              ></div>
            </div>
            <div id="footer"></div>
          </div>
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadPage() {
    try {
      var url = document.location.toString();
      let param = url.split("?").splice(1, 1)[0].split("#")[0].split("=");

      if (param.length < 2) {
        return;
      }

      var tk = param[0];
      let paras = Base64.decode(param[1]).split(";");

      if (paras.length < 7) {
        return;
      }
      var areatype = paras[0];
      var areas = paras[1].split(",");
      var types = paras[2];
      var target = paras[3];
      var leasesale = paras[4];
      var start = paras[5];
      var end = paras[6];

      this.refreshChart(
        tk,
        areatype,
        areas,
        colors,
        types,
        target,
        leasesale,
        start,
        end
      );
    } catch {}
  }

  refreshChart(tk, areatype, lines, colors, type, target, ls, st, ed) {
    var blocks = "";
    lines.forEach((element) => {
      var tmp = element + ",;"; // city
      if (areatype != 0) {
        // community
        tmp = "," + element + ";";
      }
      blocks += tmp;
    });
    blocks = blocks.substr(0, blocks.length - 1);
    // make a post request
    axios.defaults.baseURL = datahost;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    let param =
      "\"{'tk':'" +
      tk +
      "','id':'" +
      blocks +
      "','tp':'" +
      type +
      "','cd':'" +
      target +
      "','ls':'" +
      ls +
      "','st':'" +
      st +
      "','ed':'" +
      ed +
      "'}\"";
    axios.post("/detail/areareport", param).then((res) => {
      var mydt = [];
      var hd = ["Value", "Area", "Month"];
      mydt.push(hd);
      // build data table
      res.data.forEach((element) => {
        var tmp = element.Block.substring(0, element.Block.length - 1); // city
        if (areatype != 0) {
          // community
          tmp = element.Block.substring(1, element.Block.length);
        }
        var ele = [element.Value, tmp, parseInt(element.Month.substring(0, 6))];
        mydt.push(ele);
      });

      // set month columns
      const months = [];
      for (let i = 0; i < mydt.length; ++i) {
        if (months.length === 0 || months[months.length - 1] !== mydt[i][2]) {
          months.push(mydt[i][2]);
        }
      }

      // set titles TARGET(cd)
      var title0 = "";
      var title1 = "TOP 10 ";
      if (target == "SoldAveragePrice") {
        title0 += "Average Sold Price";
        title1 += "Average Sold Price";
      } else if (target == "Sold") {
        title0 += "Sold Properties";
        title1 += "Sold Properties";
      } else if (target == "SoldAverageDom") {
        title0 += "Sold Properties Days on Market";
        title1 += "Sold Properties Days on Market";
      } else if (target == "NewAveragePrice") {
        title0 += "Average New Price";
        title1 += "Average New Price";
      } else if (target == "New") {
        title0 += "New Listings";
        title1 += "New Listings";
      }
      // set titles TYPE(tp)
      if (type == "6") {
        title0 += " for All";
        title1 += " for All";
      } else if (type == "1") {
        title0 += " for Detached";
        title1 += " for Detached";
      } else if (type == "2") {
        title0 += " for Semi-Detached";
        title1 += " for Semi-Detached";
      } else if (type == "3") {
        title0 += " for Townhouse";
        title1 += " for Townhouse";
      } else if (type == "4") {
        title0 += " for Condo";
        title1 += " for Condo";
      } else if (type == "5") {
        title0 += " for Others";
        title1 += " for Others";
      }

      // LINE GRAPH
      SetChartLineGrown(
        title0,
        lines,
        mydt,
        colors,
        months.length,
        "chart_area0"
      );
      // BAR GRAPH
      SetChartBarGrown(title1, lines, mydt, colors, months, "chart_area1");
    });
  }

  BackClick() {
    window.history.go(-1);
  }
}
