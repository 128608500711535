import React, { Component } from "react";
import axios from "axios";
import ReactSelect from "react-select";
import { datahost } from "../../config/Const.js";
import { JsonToKeyValue } from "../../function/Basic.js";
import { SetChartListing, SetChartCircle } from "../../function/Chart.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/Chart.css";

export class CensusData2016 extends Component {
  static displayName = CensusData2016.name;

  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      pc: null,
      data: null,
      allTopic: [],
      ddOpen: false,
      firstTrigger: { label: "", value: "" },
    };

    // this.pickTopic = this.pickTopic.bind(this);
    // this.dropdownToggle = this.dropdownToggle.bind(this);
    this.renderSelection = this.renderSelection.bind(this);
  }

  componentDidMount() {
    this.initLanguage();
    this.loadDetail();
  }

  renderSelection() {
    return JsonToKeyValue(this.state.allTopic, "t", "k").map((res, index) => {
      return (
        <div
          id={"hori_" + res.value}
          className="hori_items"
          key={res.value}
          onClick={() => this.handleAreaChange(res.label, res.value)}
        >
          {res.label}
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        <div id="censusdata"></div>

        <div id="topic" className="mb-2">
          {/* <ReactSelect
            id="select_topic"
            className="select_topic"
            style={{ width: "100%" }}
            placeholder="Choose Topic"
            options={JsonToKeyValue(this.state.allTopic, "t", "k")}
            onChange={this.handleAreaChange}
            defaultValue={JsonToKeyValue(this.state.allTopic, "t")[0]}
            isSearchable={false}
          ></ReactSelect> */}
          <div className="hori_select">{this.renderSelection()}</div>
        </div>

        <br />

        <div
          id="chart_census"
          style={{ width: "100%", height: document.body.clientWidth * 1.1 }}
        ></div>

        <br />
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadDetail() {
    try {
      var url = document.location.toString();
      let paras = url.split("?").splice(1, 1)[0].split("#")[0].split("=");
      if (paras.length < 2) {
        return;
      }
      this.setState({
        tk: paras[0],
        pc: paras[1],
      });
      this.initTopic(paras[0]);
      this.initCensusData(paras[0], paras[1]);

      // init description
      let census = document.getElementById("chart_census");
      census.innerHTML = this.state.lang.chart_ddl_selection;
    } catch {}
  }

  initTopic(tk) {
    try {
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      var idx_lang = "_en";
      if (this.state.lang === zhj) {
        idx_lang = "_zh";
      }
      let params = "\"{'tk':'" + tk + "','pc':'" + idx_lang + "'}\"";
      axios.post("/detail/census2016", params).then((res) => {
        let topics = JSON.parse(window.atob(res.data));
        this.setState({
          allTopic: topics,
        });
      });
    } catch {}
  }

  initCensusData(tk, pc) {
    try {
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let params = "\"{'tk':'" + tk + "','pc':'" + pc + "'}\"";
      axios.post("/detail/census2016", params).then((res) => {
        if (res.data !== null) {
          this.setState({
            data: res.data,
          });
        } else {
          this.nodataError();
        }
      });
    } catch {}
  }

  nodataError() {
    // console.log("No Data Error.");
  }

  handleAreaChange = (label, value) => {
    const elems = document.querySelectorAll(".hori_items");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
    });
    document.getElementById("hori_" + value).classList.add("active");
    var picked = [];
    this.state.allTopic.forEach((curTopic) => {
      if (curTopic.k == value) {
        curTopic.v.forEach((curData) => {
          this.state.data.DATA.forEach((row) => {
            if (row[8] === curData) {
              picked.push(row);
            }
          });
        });
      }
    });
    // fill data
    var filled = [];
    picked.forEach((ele) => {
      let tmpLeg = String(ele[10]).trim();
      filled.push({ name: tmpLeg, value: ele[13] });
    });
    var pieColors = [
      "#FFB26B",
      "#b06a13",
      "#fcc41b",
      "#fc601b",
      "#b04313",
      "#ffe6c6",
      "#ffcc8d",
      "#20614c",
      "#b09913",
      "#2e8b57",
      "#fc851b",
      "#B05303",
    ];
    SetChartCircle(label, filled, pieColors, "chart_census");

    var legs = ["1", "2", "3", "4"];
    var dts = [1, 2, 3, 4];
    var tms = [5, 6, 7, 8];

    // SetChartListing("title", legs, dts, pieColors, tms, "chart_census");
  };
}
