import React, { Component } from "react";
import html2canvas from "html2canvas";

export class Snippet extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.createImg = this.createImg.bind(this);
  }

  render() {
    return <button onClick={() => this.createImg()}>SNIPPET</button>;
  }

  createImg() {
    let changeCss = false;
    console.log("create image");
    let snippetDiv = document.getElementById(
      "detail-tab-profile-summary-tabpane-details"
    );
    //display the content if its hidden
    if (snippetDiv.style.display != "block") {
      snippetDiv.style.display = "block";
      snippetDiv.style.opacity = "1";
      changeCss = true;
    }
    html2canvas(
      document.getElementById("detail-tab-profile-summary-tabpane-details")
    ).then(function (canvas) {
      let dataURL = canvas.toDataURL("image/jpeg", 1.0);
      dataURL = dataURL.replace("data:image/jpeg;base64,", "");
      //download img
      var imageType = "png";
      var fileName = Date.now();
      var raw = window.atob(dataURL);
      var rawLength = raw.length;
      var uInt8Array = new Uint8Array(rawLength);
      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      var blob = new Blob([uInt8Array], { type: "image/" + imageType });
      var aLink = document.createElement("a");
      var evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true);
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      aLink.click();

      //hide the content if css has been changed
      if (changeCss) {
        snippetDiv.style.display = "none";
        snippetDiv.style.opacity = "0";
      }
    });
  }
}
