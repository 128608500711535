import React, { Component } from "react";
import { Button, Image, Tabs, Tab } from "react-bootstrap";
import Slider from "react-slick";
import axios from "axios";
import { Base64 } from "js-base64";

import {
  gesture,
  findschoolhost,
  datahost,
  pic_host,
  pic_link,
  shkyhost,
  VOW,
} from "../../config/Const.js";
import { passParams } from "../../config/Connector.js";
import { priceSwitch, replaceAllFixed } from "../../function/Basic.js";
import {
  initImageLinks,
  SetupVowPage,
  SetupDdfPage,
  // createLog,
  createHistory,
  createCommunityChart,
  createCensusData,
  createWalkScore,
  createSchoolReport,
  createMortgage,
  fixFrameHeight,
  addAccordionClick,
  MeterFeetClick,
} from "../../function/Listing.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/Listing.css";

export class Pre_S_D_Listing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      dtl: null,
      asl: null,
      walkscore_loaded: false,
      mortgage_loaded: false,
      school_loaded: false,
      community_loaded: false,
      census_loaded: false,
      images: [],
    };
  }

  componentDidMount() {
    this.initLanguage();
    this.loadDetail();
    addAccordionClick();
  }

  render() {
    let setting0 = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let setting1 = {
      dots: false,
      infinite: true,
      speed: 300,
      // lazyLoad: true,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
    };

    return (
      <div className="D_Listing">
        <div className="wrapper">
          <div className="wrapper_margin">
            <div className="back_page">
              <Button
                id="btn-back"
                onClick={() => {
                  this.BackClick();
                }}
              >
                <i className="fa fa-angle-left"></i>
              </Button>
            </div>

            {/* property_locationsec */}

            <div className="detail_banner_sec">
              <div className="slider single-item">
                <Slider {...setting0} id="testing">
                  {this.state.images.map(function (item) {
                    return (
                      <div key={item.ngw}>
                        <div className="inner_bannersec">
                          <Image src={item.link} alt="" />
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {/* slider */}
            </div>
            {/* banner_sec */}

            <div className="proprice_infosec section_margin">
              <div className="property_pricesec">
                <h1 id="price"></h1>
                <span className="sold_tag">
                  <Image id="soldtag"></Image>
                </span>
                <div id="oriprice" className="line-price"></div>
                <h2>
                  <span id="mls"></span>
                </h2>
                <p>
                  {/* {this.state.lang["dtl_types"]} */}
                  <span id="typestyle"></span>
                </p>
                {/* <div className="property_locationsec"> */}
                <p>
                  <i className="fa fa-map-marker-alt"></i>
                  <i id="address"></i>
                </p>
              </div>
              {/* property_pricesec */}
              <ul>
                <li>
                  <i className="fa fa-bed"></i> <span id="tit-bedrooms"></span>
                </li>
                <li>
                  <i className="fa fa-bath"></i>
                  <span id="tit-bathrooms"></span>
                </li>
                <li>
                  <i className="fa fa-car"></i> <span id="tit-parking"></span>
                </li>
              </ul>
            </div>
            {/* proprice_infosec */}

            <div className="summary_contsec section_margin">
              <Tabs
                className="tab"
                defaultActiveKey="summary"
                id="detail-tab-profile-summary"
              >
                <Tab
                  eventKey="summary"
                  className="tabcontent"
                  title={this.state.lang["dtl_summary"]}
                >
                  <div className="common_infosec summary_tabsec">
                    <ul>
                      <li>
                        <p>{this.state.lang["dtl_type"]}</p>
                        <strong>
                          <p id="pro-type"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_bedrooms"]}</p>
                        <strong>
                          <p id="pro-bedrooms"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_kitchen"]}</p>
                        <strong>
                          <p id="pro-kitchen"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_bathrooms"]}</p>
                        <strong>
                          <p id="pro-bathrooms"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_lotsize"]}</p>
                        <strong>
                          <p id="pro-lotsize"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sqft"]}</p>
                        <strong>
                          <p id="pro-sqft"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_yr-built"]}</p>
                        <strong>
                          <p id="pro-yr-built"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_taxes"]}</p>
                        <strong>
                          <p id="pro-taxes"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_maint"]}</p>
                        <strong>
                          <p id="pro-maint"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_parking"]}</p>
                        <strong>
                          <p id="pro-parking"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_listed"]}</p>
                        <strong>
                          <p id="pro-listeddate"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_update"]}</p>
                        <strong>
                          <p id="pro-updatedate"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_basement"]}</p>
                        <strong>
                          <p id="pro-basement"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_brokered"]}</p>
                        <strong>
                          <p id="pro-broker"></p>
                        </strong>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div>{this.state.lang["dtl_extras"]}</div>
                    <strong>
                      <p id="pro-extras"></p>
                    </strong>
                  </div>
                </Tab>
                <Tab
                  eventKey="details"
                  className="tabcontent"
                  title={this.state.lang["dtl_details"]}
                >
                  <div className="common_infosec detail_tabsec">
                    <ul>
                      <li>
                        <p>{this.state.lang["dtl_style"]}</p>
                        <strong>
                          <p id="pro-construction-style"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_frontingon"]}</p>
                        <strong>
                          <p id="pro-front-on"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_community"]}</p>
                        <strong>
                          <p id="pro-community"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_zip"]}</p>
                        <strong>
                          <p id="pro-zip"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_cross-st"]}</p>
                        <strong>
                          <p id="pro-cross-st"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_watersupply"]}</p>
                        <strong>
                          <p id="pro-water-supply"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_acsystem"]}</p>
                        <strong>
                          <p id="pro-ac-system"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_heatingfuel"]}</p>
                        <strong>
                          <p id="pro-heating-fuel"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_heatingtype"]}</p>
                        <strong>
                          <p id="pro-heating-type"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_fireplace"]}</p>
                        <strong>
                          <p id="pro-fire-place"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_centralvac"]}</p>
                        <strong>
                          <p id="pro-central-vac"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sewer"]}</p>
                        <strong>
                          <p id="pro-sewer"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_construction"]}</p>
                        <strong>
                          <p id="pro-construction"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_municipality"]}</p>
                        <strong>
                          <p id="pro-municipality"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_swimmingpool"]}</p>
                        <strong>
                          <p id="pro-swimming-pool"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_basementtype"]}</p>
                        <strong>
                          <p id="pro-basement-type"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_basementfeatures"]}</p>
                        <strong>
                          <p id="pro-basement-features"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sizetotal"]}</p>
                        <strong>
                          <p id="pro-size-total"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_amenities"]}</p>
                        <strong>
                          <p id="pro-amenities"></p>
                        </strong>
                      </li>
                    </ul>
                  </div>
                </Tab>
                <Tab
                  eventKey="rooms"
                  className="tabcontent"
                  title={this.state.lang["dtl_rooms"]}
                >
                  <Button
                    id="pro-btn-mf"
                    style={{ width: "98%" }}
                    onClick={() => {
                      MeterFeetClick();
                    }}
                  >
                    Change To Feet
                  </Button>
                  <div className="common_infosec room_tabsec">
                    <ul>
                      <li>
                        <div>
                          <div
                            id="pro-room0-0"
                            className="pro_roomleft"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room0-1"
                            className="pro_roomright"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p
                          id="pro-room0-2"
                          className="pro_room"
                          style={{ width: "100%" }}
                        ></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room1-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room1-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room1-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room2-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room2-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room2-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room3-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room3-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room3-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room4-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room4-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room4-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room5-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room5-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room5-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room6-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room6-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room6-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room7-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room7-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room7-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room8-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room8-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room8-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room9-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room9-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room9-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room10-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room10-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room10-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room11-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room11-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room11-2" style={{ width: "100%" }}></p>
                      </li>

                      <li>
                        <div>
                          <div
                            id="pro-closet0-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-closet0-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-closet1-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-closet1-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-closet2-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-closet2-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-closet3-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-closet3-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-closet4-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-closet4-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Tab>
                <Tab
                  eventKey="others"
                  className="tabcontent"
                  title={this.state.lang["dtl_others"]}
                >
                  <div className="common_infosec detail_tabsec">
                    <ul>
                      <li>
                        <p>{this.state.lang["dtl_ass-year"]}</p>
                        <strong>
                          <p id="pro-ass-year"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_condo-exp"]}</p>
                        <strong>
                          <p id="pro-condo-exp"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_ens-lndry"]}</p>
                        <strong>
                          <p id="pro-ens-lndry"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_farm-agri"]}</p>
                        <strong>
                          <p id="pro-farm-agri"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_gas"]}</p>
                        <strong>
                          <p id="pro-gas"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_heat-inc"]}</p>
                        <strong>
                          <p id="pro-heat-inc"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_hydro-inc"]}</p>
                        <strong>
                          <p id="pro-hydro-inc"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_irreg"]}</p>
                        <strong>
                          <p id="pro-irreg"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_locker"]}</p>
                        <strong>
                          <p id="pro-locker"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_locker-num"]}</p>
                        <strong>
                          <p id="pro-locker-num"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_occupancy"]}</p>
                        <strong>
                          <p id="pro-occupancy"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_park-chgs"]}</p>
                        <strong>
                          <p id="pro-park-chgs"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_park-desig"]}</p>
                        <strong>
                          <p id="pro-park-desig"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_park-fac"]}</p>
                        <strong>
                          <p id="pro-park-fac"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_park-spc1"]}</p>
                        <strong>
                          <p id="pro-park-spc1"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_park-spc2"]}</p>
                        <strong>
                          <p id="pro-park-spc2"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_patio-ter"]}</p>
                        <strong>
                          <p id="pro-patio-ter"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_pets"]}</p>
                        <strong>
                          <p id="pro-pets"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_pool"]}</p>
                        <strong>
                          <p id="pro-pool"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_prkg-inc"]}</p>
                        <strong>
                          <p id="pro-prkg-inc"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_secgrd-sys"]}</p>
                        <strong>
                          <p id="pro-secgrd-sys"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_unit-num"]}</p>
                        <strong>
                          <p id="pro-unit-num"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_water-inc"]}</p>
                        <strong>
                          <p id="pro-water-inc"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_kit-plus"]}</p>
                        <strong>
                          <p id="pro-kit-plus"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_retirement"]}</p>
                        <strong>
                          <p id="pro-retirement"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_waterfront"]}</p>
                        <strong>
                          <p id="pro-waterfront"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_area-code"]}</p>
                        <strong>
                          <p id="pro-area-code"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_municipality-code"]}</p>
                        <strong>
                          <p id="pro-municipality-code"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_community-code"]}</p>
                        <strong>
                          <p id="pro-community-code"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_area"]}</p>
                        <strong>
                          <p id="pro-area"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_municipality-district"]}</p>
                        <strong>
                          <p id="pro-municipality-district"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_handi-equipped"]}</p>
                        <strong>
                          <p id="pro-handi-equipped"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_area-infl1-out"]}</p>
                        <strong>
                          <p id="pro-area-infl1-out"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_area-infl2-out"]}</p>
                        <strong>
                          <p id="pro-area-infl2-out"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_cable"]}</p>
                        <strong>
                          <p id="pro-cable"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_city-water"]}</p>
                        <strong>
                          <p id="pro-city-water"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_insu-bldg"]}</p>
                        <strong>
                          <p id="pro-insu-bldg"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_oth-struc1-out"]}</p>
                        <strong>
                          <p id="pro-oth-struc1-out"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_oth-struc2-out"]}</p>
                        <strong>
                          <p id="pro-oth-struc2-out"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_rec-room"]}</p>
                        <strong>
                          <p id="pro-rec-room"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sauna"]}</p>
                        <strong>
                          <p id="pro-sauna"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_share-perc"]}</p>
                        <strong>
                          <p id="pro-share-perc"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_spec-des"]}</p>
                        <strong>
                          <p id="pro-spec-des"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sqs-rac-ct"]}</p>
                        <strong>
                          <p id="pro-sqs-rac-ct"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_stories"]}</p>
                        <strong>
                          <p id="pro-stories"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_tennis-ct"]}</p>
                        <strong>
                          <p id="pro-tennis-ct"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_wtr-suptyp"]}</p>
                        <strong>
                          <p id="pro-wtr-suptyp"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_zoning"]}</p>
                        <strong>
                          <p id="pro-zoning"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_prop-mgmt"]}</p>
                        <strong>
                          <p id="pro-prop-mgmt"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_all-inc"]}</p>
                        <strong>
                          <p id="pro-all-inc"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_furnished"]}</p>
                        <strong>
                          <p id="pro-furnished"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_pvt-ent"]}</p>
                        <strong>
                          <p id="pro-pvt-ent"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_laundry"]}</p>
                        <strong>
                          <p id="pro-laundry"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_lease-term"]}</p>
                        <strong>
                          <p id="pro-lease-term"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_pay-freq"]}</p>
                        <strong>
                          <p id="pro-pay-freq"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_pay-meth"]}</p>
                        <strong>
                          <p id="pro-pay-meth"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_app-req"]}</p>
                        <strong>
                          <p id="pro-app-req"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sec-dep"]}</p>
                        <strong>
                          <p id="pro-sec-dep"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_credit-chk"]}</p>
                        <strong>
                          <p id="pro-credit-chk"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_emply-lett"]}</p>
                        <strong>
                          <p id="pro-emply-lett"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_lease"]}</p>
                        <strong>
                          <p id="pro-lease"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_ref-req"]}</p>
                        <strong>
                          <p id="pro-ref-req"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_opt-to-buy"]}</p>
                        <strong>
                          <p id="pro-opt-to-buy"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_laundry-lev"]}</p>
                        <strong>
                          <p id="pro-laundry-lev"></p>
                        </strong>
                      </li>
                    </ul>
                  </div>
                </Tab>
              </Tabs>
              <div>
                <div>{this.state.lang["dtl_remarks"]}</div>
                <div id="pro-remarks" style={{ width: "98%" }}></div>
              </div>
            </div>

            <div className="main_accordinsec section_margin">
              <button className="accordion">
                <i className="fas fa-clipboard-list"></i>
                {this.state.lang.dtl_toggle_census}
              </button>
              <div className="panel">
                <iframe
                  id="iframe_census"
                  src=""
                  style={{
                    width: "100%",
                  }}
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}

              <button className="accordion" id="toggle_community">
                <i className="fas fa-clipboard-list"></i>
                {this.state.lang.dtl_toggle_report}
              </button>
              <div className="panel" id="toggle_community_div">
                <iframe
                  id="iframe_community"
                  src=""
                  style={{
                    width: "100%",
                  }}
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}

              <button className="accordion" id="toggle_mortgage">
                <i className="fas fa-clipboard-list"></i>
                {this.state.lang.dtl_toggle_mortgage}
              </button>
              <div className="panel" id="toggle_mortgage_div">
                <iframe
                  id="iframe_mortgage"
                  src=""
                  style={{
                    width: "100%",
                  }}
                  scrolling="no"
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-school"></i>
                {this.state.lang.dtl_toggle_schools}
              </button>
              <div className="panel">
                <iframe
                  id="iframe_school"
                  src=""
                  style={{
                    width: "100%",
                    height: document.body.clientWidth * 1.59,
                  }}
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-clipboard-list"></i>
                {this.state.lang.dtl_toggle_walkscore}
              </button>
              <div className="panel">
                <iframe
                  id="iframe_walkscore"
                  src=""
                  style={{
                    width: "100%",
                  }}
                  scrolling="no"
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}
            </div>
            {/* main_accordinsec */}
          </div>
          {/* wrapper_margin */}
        </div>
        {/* wrapper */}

        <div id="footer"></div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadDetail() {
    try {
      var url = document.location.toString();
      if (url[url.length - 1] === "=") {
        // remove the last '=' character
        url = url.slice(0, -1);
      }
      let paras = url.split("?").splice(1, 1)[0].split("=");
      let tk = paras[0];
      let ngw = paras[1];
      this.setState({ tk: tk }); // save token
      let tmpngw = replaceAllFixed(ngw, "~", "=");
      let decode = window.atob(tmpngw).split(","); // load detail information
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let paraDTL = "\"{'tk':'" + tk + "','id':'" + decode[1] + "'}\"";
      axios.post("/detail/id", paraDTL).then((res0) => {
        // guard null
        if (!res0.data.apsl) {
          return;
        }
        // save detail data
        this.setState({ dtl: res0.data });

        // Listing Detail Data
        if (this.state.dtl.apsl != undefined) {
          if (this.state.dtl.apsl.source === VOW) {
            SetupVowPage(this.state.dtl.data);
          } else {
            SetupDdfPage(this.state.dtl.data);
          }
        }

        // Load Images
        if (this.state.dtl.apsl !== undefined) {
          let arr = initImageLinks(
            this.state.tk,
            this.state.dtl.apsl.propertyId,
            this.state.dtl.apsl.picBin
          );
          this.setState({ images: arr });
        }

        // Sold/Leased Tag
        var status = "a";
        if (this.state.dtl.apsl.status === "U") {
          status = "c";
          if (
            this.state.dtl.apsl.listingType === "Sale" ||
            this.state.dtl.apsl.listingType === "S"
          ) {
            document.getElementById(
              "soldtag"
            ).src = require("../../../images/sold_stamp.png");
          } else {
            document.getElementById(
              "soldtag"
            ).src = require("../../../images/leased_stamp.png");
          }
        }

        // Census
        this.setState({
          census_loaded: createCensusData(
            this.state.dtl.apsl.zip,
            this.state.tk,
            this.state.census_loaded
          ),
        });
        fixFrameHeight("iframe_census", 0.97);

        // Community, Only if on VOW
        if (this.state.dtl.apsl.source === VOW) {
          this.setState({
            community_loaded: createCommunityChart(
              this.state.dtl.apsl.community,
              this.state.dtl.apsl.nodeType,
              this.state.tk,
              this.state.community_loaded
            ),
          });
          fixFrameHeight("iframe_community", 1.03);

          document.getElementById("toggle_community").hidden = false;
          document.getElementById("toggle_community_div").hidden = false;
        } else {
          document.getElementById("toggle_community").hidden = true;
          document.getElementById("toggle_community_div").hidden = true;
        }

        // Mortgage
        if (
          this.state.dtl.apsl.listingType === "Sale" ||
          this.state.dtl.apsl.listingType === "S"
        ) {
          this.setState({
            mortgage_loaded: createMortgage(
              this.state.dtl.apsl.price,
              this.state.lang.site,
              this.state.mortgage_loaded
            ),
          });
          document.getElementById("iframe_mortgage").style.height =
            window.screen.width * 1.55 + "px";
          // fixFrameHeight("iframe_mortgage", 1.55);
        } else {
          document.getElementById("toggle_mortgage").hidden = true;
          document.getElementById("toggle_mortgage_div").hidden = true;
        }

        // Walkscore
        let addr =
          this.state.dtl.apsl.streetNumber +
          " " +
          this.state.dtl.apsl.streetName +
          " " +
          this.state.dtl.apsl.streetSuffix +
          "," +
          this.state.dtl.apsl.city;
        this.setState({
          walkscore_loaded: createWalkScore(
            addr,
            this.state.tk,
            this.state.walkscore_loaded
          ),
        });
        fixFrameHeight("iframe_walkscore", 0.87);

        // School Report
        this.setState({
          school_loaded: createSchoolReport(
            this.state.dtl.apsl.lat,
            this.state.dtl.apsl.lng,
            this.state.school_loaded
          ),
        });
        if (document.location.host == findschoolhost) {
          fixFrameHeight("iframe_school", 0.55);
        }
      });
    } catch {}
  }

  BackClick() {
    window.history.go(-1);
  }
}
