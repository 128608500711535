import React, { Component } from "react";
import { Button, Alert } from "react-bootstrap";
import axios from "axios";
import { datahost } from "../../config/Const.js";
import { passParams } from "../../config/Connector.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/Contact.css";

export class Activate extends Component {
  state = {
    lang: enj,
    tk: null,
    em: null,
    ph: null,
  };

  componentDidMount() {
    this.initLanguage();
    this.loadDetail();
  }

  render() {
    return (
      <div className="wrapper">
        <Alert variant="danger" id="error_toast" hidden={true}>
          <strong>
            <p id="error_tit"></p>
          </strong>
          <p id="error_msg"></p>
        </Alert>
        <Alert variant="success" id="success_toast" hidden={true}>
          <strong>
            <p>{this.state.lang.regist_success}</p>
          </strong>
          <p>{this.state.lang.regist_success_message}</p>
        </Alert>
        <div className="wrapper_margin">
          <div className="detail_contactsec section_margin" id="contact">
            <div className="main_headingsec">
              <h1>{this.state.lang.regist_validate_title}</h1>
            </div>
            <div id="first">
              <Button className="btncont_field" onClick={() => this.sendCode()}>
                <i className="fas fa-user-tie"></i>
                {this.state.lang.regist_validate_sendcode}
              </Button>
            </div>
            <div id="second" className="detcontact_fieldsec" hidden={true}>
              <div>{this.state.lang.regist_validate_sent}</div>
              <div id="phititle"></div>
              <input
                type="text"
                className="inputcont_field"
                placeholder={this.state.lang.regist_validate}
                id="inputCode"
                maxLength="6"
              />
              <Button
                id="active_submit"
                className="btncont_field"
                onClick={() => this.uploadSubmit()}
              >
                <i className="fas fa-user-tie"></i>
                {this.state.lang.submit}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadDetail() {
    try {
      let url = document.location.toString();
      let paras = url.split("?")[1].split("=");
      let tk = paras[0];
      let em = paras[1];
      this.setState({ tk: tk, em: em });
    } catch {}
  }

  sendCode() {
    try {
      let param =
        "\"{'tk':'" + this.state.tk + "','usr':'" + this.state.em + "'}\"";
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      axios.post("/regist/gencode", param).then((res) => {
        document.getElementById("first").hidden = true;
        document.getElementById("second").hidden = false;
        let ph = res.data.toString();
        let phx = "******" + ph.substr(ph.length - 3, 3);
        document.getElementById("phititle").innerText = phx;
      });
    } catch {}
  }

  uploadSubmit() {
    try {
      let toast = document.getElementById("error_toast");
      let tit = document.getElementById("error_tit");
      let msg = document.getElementById("error_msg");
      let cdx = document.getElementById("inputCode");
      let cd = cdx.value;
      if (cd.length != 6) {
        toast.hidden = false;
        tit.innerHTML = this.state.lang.regist_error_title;
        msg.innerHTML = this.state.lang.regist_error_validate;
        return;
      }
      let param =
        "\"{'tk':'" +
        this.state.tk +
        "','usr':'" +
        this.state.em +
        "','cd':'" +
        cd +
        "'}\"";
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      axios.post("/regist/active", param).then((res) => {
        document.getElementById("active_submit").disabled = true;
        setTimeout(function () {
          document.getElementById("active_submit").disabled = false;
          toast.hidden = true;
          cdx.value = "";
        }, 5000);
        // check response
        if (res.data === "T") {
          toast.hidden = true;
          document.getElementById("success_toast").hidden = false;
          document.getElementById("contact").hidden = true;
          setTimeout(function () {
            passParams("quit", "");
          }, 3000);
        } else {
          toast.hidden = false;
          tit.innerHTML = this.state.lang.regist_error_title;
          msg.innerHTML = this.state.lang.regist_error_validate;
        }
      });
    } catch {}
  }
}
