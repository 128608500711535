export function passParams(keys, values) {
  if (keys === "sharemedia") {
    callClient("shareMedia", values);
  }
  // copy sharing media
  else if (keys === "copySharing") {
    callClient("copySharing", values);
  }
  // MLS & address
  else if (keys === "searchmls") {
    let predef = "l;a;r;s;MC;~;";
    callClient("mapSearch", window.btoa(predef + values));
  }
  // listings
  else if (keys === "listings") {
    callClient("mapSearch", "bDthO3I7czs7Ow==");
  }
  // condos
  else if (keys === "condos") {
    callClient("mapSearch", "bTthO3I7czt4Mzt+OzQ=");
  }
  // rent
  else if (keys === "rent") {
    callClient("mapSearch", "bDthO3I7bDs7Ow==");
  }
  // pricedrop
  else if (keys === "pricedrop") {
    callClient("mapSearch", "bDthO3I7cztCOz07MQ==");
  }
  // soldhistory
  else if (keys === "soldhistory") {
    callClient("mapSearch", "bDtjO3I7czt3Nyx4Mjs9LD47U2xkLDIwMjEtMDEtMDE=");
  }
  // marketreport
  else if (keys === "marketreport") {
    callClient("marketReport", "");
  }
  // drop LatLng
  else if (keys === "droplatlng") {
    callClient("dropLatLng", values);
  }
  // quit current page
  else if (keys === "quit") {
    callClient("quit", "");
  } else {
    // DO NOTHING
  }

  /*
  SUM -> token, searching, target -> POST:T <SERVER Render -> HTML context return>
  */
}

function callClient(keys, values) {
  try {
    window.webkit.messageHandlers.jumpApp.postMessage(keys + ";" + values);
  } catch {}
  try {
    window.agentWeb.jumpApp(keys + ";" + values);
  } catch {}
}
