import {
  ReadValue,
  CheckValue,
  priceSwitch,
  replaceAllFixed,
} from "./Basic.js";
import {
  gesture,
  findschoolhost,
  pic_host,
  pic_link,
  pic_sharing,
} from "../config/Const.js";

export function initImageLinks(tk, propId, picBin) {
  var binStr = "";
  if (picBin == null) {
    binStr = "11111111111111111111111111111111";
  } else {
    let buff = Buffer.from(picBin, "base64");
    for (var j = 0; j < buff.length; j++) {
      binStr += buff[j].toString(2).padStart(8, "0");
    }
  }
  var arr = [];
  for (var i = 0; i < binStr.length; i++) {
    if (binStr[i] === "1") {
      var ngwStr = "3574765509," + propId + ",2028-01-08 15:46:58," + i;
      ngwStr = replaceAllFixed(window.btoa(ngwStr), "=", "~");
      let linkStr = gesture + pic_host + pic_link + tk + "&ngw=" + ngwStr;
      arr.push({ ngw: ngwStr, link: linkStr });
    }
  }
  return arr;
}

export function initImageLinksSharing(sh, propId, picBin) {
  var binStr = "";
  if (picBin == null) {
    binStr = "11111111111111111111111111111111";
  } else {
    let buff = Buffer.from(picBin, "base64");
    for (var j = 0; j < buff.length; j++) {
      binStr += buff[j].toString(2).padStart(8, "0");
    }
  }
  var arr = [];
  for (var i = 0; i < binStr.length; i++) {
    if (binStr[i] === "1") {
      var ngwStr = "3574765509," + propId + ",2028-01-08 15:46:58," + i;
      ngwStr = replaceAllFixed(window.btoa(ngwStr), "=", "~");
      let linkStr = gesture + pic_host + pic_sharing + sh + "&ngw=" + ngwStr;
      arr.push({ ngw: ngwStr, link: linkStr });
    }
  }
  return arr;
}

export function SetupVowPage(data) {
  if (data !== null && !data.toString().includes("Invalid")) {
    document.getElementById("mls").innerHTML = ReadValue(data["mlNum"]);
    let sldprc = ReadValue(data["spDol"]);
    let status = ReadValue(data["status"]);
    if (sldprc != "" && status == "U") {
      document.getElementById("price").innerHTML = "$" + priceSwitch(sldprc);
      document.getElementById("oriprice").innerHTML =
        "$" + priceSwitch(ReadValue(data["lpDol"]));
    } else {
      document.getElementById("price").innerHTML =
        "$" + priceSwitch(ReadValue(data["lpDol"]));
      document.getElementById("oriprice").hidden = true;
    }

    document.getElementById("address").innerHTML =
      ReadValue(data["addr"]) + ", " + ReadValue(data["municipality"]);
    document.getElementById("typestyle").innerHTML = ReadValue(
      data["typeOwn1Out"]
    );

    document.getElementById("pro-type").innerHTML =
      ReadValue(data["typeOwn1Out"]) + " " + ReadValue(data["style"]);
    if (ReadValue(data["brPlus"]) === "") {
      document.getElementById("pro-bedrooms").innerHTML = ReadValue(data["br"]);
      document.getElementById("tit-bedrooms").innerHTML = ReadValue(data["br"]);
    } else {
      document.getElementById("pro-bedrooms").innerHTML =
        ReadValue(data["br"]) + "+" + ReadValue(data["brPlus"]);
      document.getElementById("tit-bedrooms").innerHTML =
        ReadValue(data["br"]) + "+" + ReadValue(data["brPlus"]);
    }
    document.getElementById("pro-kitchen").innerHTML = ReadValue(
      data["numKit"]
    );
    document.getElementById("pro-bathrooms").innerHTML = ReadValue(
      data["bathTot"]
    );
    document.getElementById("tit-bathrooms").innerHTML = ReadValue(
      data["bathTot"]
    );
    // Land
    if (ReadValue(data["frontFt"]) === "") {
      document.getElementById("pro-lotsize").innerHTML = "";
    } else {
      document.getElementById("pro-lotsize").innerHTML =
        ReadValue(data["frontFt"]) + "ft x " + ReadValue(data["depth"]) + "ft";
    }
    // Approx Age
    document.getElementById("pro-yr-built").innerHTML = ReadValue(
      data["yrBuilt"]
    );
    // Taxes / Taxes Year
    if (ReadValue(data["taxes"]) === "") {
      document.getElementById("pro-taxes").innerHTML = "";
    } else {
      document.getElementById("pro-taxes").innerHTML =
        ReadValue(data["taxes"]) + "(" + ReadValue(data["yr"]) + ")";
    }
    // Maintenance
    document.getElementById("pro-maint").innerHTML = ReadValue(data["maint"]);
    // Parkings
    if (ReadValue(data["garSpaces"]) !== "") {
      document.getElementById("pro-parking").innerHTML +=
        ReadValue(data["garSpaces"]) + " " + ReadValue(data["garType"]);
    }
    if (ReadValue(data["parkSpcs"]) !== "") {
      document.getElementById("tit-parking").innerHTML = ReadValue(
        data["parkSpcs"]
      );
      document.getElementById("pro-parking").innerHTML +=
        "<br/>" + ReadValue(data["parkSpcs"]) + " Parking Drive";
    }
    if (ReadValue(data["totParkSpcs"]) !== "") {
      document.getElementById("pro-parking").innerHTML +=
        "<br/>" + ReadValue(data["totParkSpcs"] + " Total Parking Spaces");
    }
    document.getElementById("pro-parking").innerHTML =
      ReadValue(data["garSpaces"]) +
      " " +
      ReadValue(data["garType"]) +
      "<br/>" +
      ReadValue(data["parkSpcs"]) +
      " Parking Drive<br/>" +
      ReadValue(data["totParkSpcs"] + " Total Parking Spaces");
    document.getElementById("pro-listeddate").innerHTML = ReadValue(
      data["inputDate"]
    ).substring(0, 10);
    document.getElementById("pro-updatedate").innerHTML = ReadValue(
      data["timestampSql"]
    ).substring(0, 10);
    document.getElementById("pro-broker").innerHTML = ReadValue(data["rltr"]);
    document.getElementById("pro-basement").innerHTML =
      ReadValue(data["bsmt1Out"]) + " " + ReadValue(data["bsmt2Out"]);

    // Construction Style
    document.getElementById("pro-construction-style").innerHTML = ReadValue(
      data["typeOwn1Out"]
    );
    // Front On
    document.getElementById("pro-front-on").innerHTML = ReadValue(
      data["compPts"]
    );
    // Community
    document.getElementById("pro-community").innerHTML = ReadValue(
      data["community"]
    );
    // Directions/Cross Streets
    document.getElementById("pro-cross-st").innerHTML = ReadValue(
      data["crossSt"]
    );
    // Water Supply
    document.getElementById("pro-water-supply").innerHTML = ReadValue(
      data["water"]
    );
    // AC System
    document.getElementById("pro-ac-system").innerHTML = ReadValue(data["ac"]);
    // Heating Fuel
    document.getElementById("pro-heating-fuel").innerHTML = ReadValue(
      data["fuel"]
    );
    // Heating Type
    document.getElementById("pro-heating-type").innerHTML = ReadValue(
      data["heating"]
    );
    // Fireplace
    if (JSON.stringify(data).includes("Fireplace")) {
      document.getElementById("pro-fire-place").innerHTML = "Yes";
    } else {
      document.getElementById("pro-fire-place").innerHTML = "No";
    }
    // Central Vacum
    document.getElementById("pro-central-vac").innerHTML = CheckValue(
      data["centralVac"]
    );
    // Sewer
    document.getElementById("pro-sewer").innerHTML = ReadValue(data["sewer"]);
    document.getElementById("pro-construction").innerHTML = ReadValue(
      data["constr1Out"]
    );
    document.getElementById("pro-municipality").innerHTML = ReadValue(
      data["municipality"]
    );
    document.getElementById("pro-swimming-pool").innerHTML = CheckValue(
      data["pool"]
    );
    document.getElementById("pro-basement-type").innerHTML = ReadValue(
      data["bsmt1Out"]
    );
    document.getElementById("pro-basement-features").innerHTML = ReadValue(
      data["bsmt2Out"]
    );
    document.getElementById("pro-size-total").innerHTML =
      ReadValue(data["frontFt"]) + " x " + ReadValue(data["depth"]);
    document.getElementById("pro-amenities").innerHTML = (
      ReadValue(data["propFeat1Out"]) +
      " " +
      ReadValue(data["propFeat2Out"]) +
      " " +
      ReadValue(data["propFeat3Out"]) +
      " " +
      ReadValue(data["propFeat4Out"]) +
      " " +
      ReadValue(data["propFeat5Out"]) +
      " " +
      ReadValue(data["propFeat6Out"])
    ).trimEnd();
    document.getElementById("pro-extras").innerHTML = ReadValue(data["extras"]);

    for (var i = 0; i < 12; i++) {
      if (ReadValue(data["rm" + (i + 1) + "Out"]) === "") {
        document.getElementById("pro-room" + i + "-0").hidden = true;
        document.getElementById("pro-room" + i + "-1").hidden = true;
        document.getElementById("pro-room" + i + "-2").hidden = true;
      } else {
        document.getElementById("pro-room" + i + "-0").innerHTML =
          ReadValue(data["rm" + (i + 1) + "Out"]) +
          " (" +
          ReadValue(data["level" + (i + 1)]) +
          ")";
        if (
          ReadValue(data["rm" + (i + 1) + "Len"]) === "" ||
          ReadValue(data["rm" + (i + 1) + "Wth"]) === ""
        ) {
          document.getElementById("pro-room" + i + "-1").innerHTML = "-";
        } else {
          document.getElementById("pro-room" + i + "-1").innerHTML =
            ReadValue(data["rm" + (i + 1) + "Len"]).toFixed(2) +
            "m x " +
            ReadValue(data["rm" + (i + 1) + "Wth"]).toFixed(2) +
            "m";
        }
        if (ReadValue(data["rm" + (i + 1) + "Dc1Out"]) !== "") {
          document.getElementById("pro-room" + i + "-2").innerHTML +=
            ReadValue(data["rm" + (i + 1) + "Dc1Out"]) + ", ";
        }
        if (ReadValue(data["rm" + (i + 1) + "Dc2Out"]) !== "") {
          document.getElementById("pro-room" + i + "-2").innerHTML +=
            ReadValue(data["rm" + (i + 1) + "Dc2Out"]) + ", ";
        }
        if (ReadValue(data["rm" + (i + 1) + "Dc3Out"]) !== "") {
          document.getElementById("pro-room" + i + "-2").innerHTML += ReadValue(
            data["rm" + (i + 1) + "Dc3Out"]
          );
        }
      }
    }

    for (var i = 0; i < 5; i++) {
      if (ReadValue(data["wclosetT" + (i + 1)]) === "") {
        document.getElementById("pro-closet" + i + "-0").hidden = true;
        document.getElementById("pro-closet" + i + "-1").hidden = true;
      } else {
        document.getElementById("pro-closet" + i + "-0").innerHTML =
          "Wash Room (" + ReadValue(data["wclosetT" + (i + 1) + "lvl"] + ")");
        document.getElementById("pro-closet" + i + "-1").innerHTML =
          "Pieces: " + ReadValue(data["wclosetP" + (i + 1)]);
      }
    }

    document.getElementById("pro-remarks").innerHTML = data["adText"];
    // document.getElementById('pro-').innerHTML = ReadValue(data['']);
    // additional data added on May,28 2021
    document.getElementById("pro-ass-year").innerHTML = ReadValue(
      data["assYear"]
    ); // Assessment Year
    document.getElementById("pro-condo-exp").innerHTML = ReadValue(
      data["condoExp"]
    ); // Exposure (Condo)
    document.getElementById("pro-ens-lndry").innerHTML = ReadValue(
      data["ensLndry"]
    ); // Ensuite Laundry (Condo)
    document.getElementById("pro-farm-agri").innerHTML = ReadValue(
      data["farmAgri"]
    ); // Farm/Agriculture (Farm)
    document.getElementById("pro-gas").innerHTML = ReadValue(data["gas"]); // Utilities-Gas(Farm)
    document.getElementById("pro-heat-inc").innerHTML = ReadValue(
      data["heatInc"]
    ); // Heat Included
    document.getElementById("pro-hydro-inc").innerHTML = ReadValue(
      data["hydroInc"]
    ); // Hydro Included
    document.getElementById("pro-irreg").innerHTML = ReadValue(data["irreg"]); // Lot Irregularities
    document.getElementById("pro-locker").innerHTML = ReadValue(data["locker"]); // Locker
    document.getElementById("pro-locker-num").innerHTML = ReadValue(
      data["lockerNum"]
    ); // Locker #
    document.getElementById("pro-occupancy").innerHTML = ReadValue(
      data["occupancy"]
    ); // Occupancy
    document.getElementById("pro-park-chgs").innerHTML = ReadValue(
      data["parkChgs"]
    ); // Park Cost/Mo (Condo)
    document.getElementById("pro-park-desig").innerHTML = ReadValue(
      data["parkDesig"]
    ); // Parking Type
    document.getElementById("pro-park-fac").innerHTML = ReadValue(
      data["parkFac"]
    ); // Parking/Drive
    document.getElementById("pro-park-spc1").innerHTML = ReadValue(
      data["parkSpc1"]
    ); // Parking Spot #1
    document.getElementById("pro-park-spc2").innerHTML = ReadValue(
      data["parkSpc2"]
    ); // Parking Spot #2
    document.getElementById("pro-patio-ter").innerHTML = ReadValue(
      data["patioTer"]
    ); // Balcony
    document.getElementById("pro-pets").innerHTML = ReadValue(data["pets"]); // Pets Permitted
    document.getElementById("pro-pool").innerHTML = ReadValue(data["pool"]); // Pool
    document.getElementById("pro-prkg-inc").innerHTML = ReadValue(
      data["prkgInc"]
    ); // Parking Included
    document.getElementById("pro-secgrd-sys").innerHTML = ReadValue(
      data["secgrdSys"]
    ); // Security Guard System (Condo)
    document.getElementById("pro-sqft").innerHTML = ReadValue(data["sqft"]); // Approx Square Footage
    document.getElementById("pro-unit-num").innerHTML = ReadValue(
      data["unitNum"]
    ); // Unit # (Condo)
    document.getElementById("pro-water-inc").innerHTML = ReadValue(
      data["waterInc"]
    ); // Water Included
    document.getElementById("pro-zip").innerHTML = ReadValue(data["zip"]); // Postal Code
    document.getElementById("pro-kit-plus").innerHTML = ReadValue(
      data["kitPlus"]
    ); // Kitchens Plus
    document.getElementById("pro-retirement").innerHTML = ReadValue(
      data["retirement"]
    ); // Retirement
    document.getElementById("pro-waterfront").innerHTML = ReadValue(
      data["waterfront"]
    ); // Waterfront
    document.getElementById("pro-area-code").innerHTML = ReadValue(
      data["areaCode"]
    ); // Area Code
    document.getElementById("pro-municipality-code").innerHTML = ReadValue(
      data["municipalityCode"]
    ); // Municipality Code
    document.getElementById("pro-community-code").innerHTML = ReadValue(
      data["communityCode"]
    ); // Community Code
    document.getElementById("pro-area").innerHTML = ReadValue(data["area"]); // Area
    document.getElementById("pro-municipality-district").innerHTML = ReadValue(
      data["municipalityDistrict"]
    ); // Municipality District
    document.getElementById("pro-handi-equipped").innerHTML = ReadValue(
      data["handiEquipped"]
    ); // Physically Handicapped-Equipped
    document.getElementById("pro-area-infl1-out").innerHTML = ReadValue(
      data["areaInfl1Out"]
    ); // Area Influences1
    document.getElementById("pro-area-infl2-out").innerHTML = ReadValue(
      data["areaInfl2Out"]
    ); // Area Influences2
    document.getElementById("pro-cable").innerHTML = ReadValue(data["cable"]); // Cable
    document.getElementById("pro-city-water").innerHTML = ReadValue(
      data["cityWater"]
    ); // Utilities-Municipal Water
    document.getElementById("pro-insu-bldg").innerHTML = ReadValue(
      data["insurBldg"]
    ); // Building Insurance Included
    document.getElementById("pro-oth-struc1-out").innerHTML = ReadValue(
      data["othStruc1Out"]
    ); // Other Structures1
    document.getElementById("pro-oth-struc2-out").innerHTML = ReadValue(
      data["othStruc2Out"]
    ); // Other Structures2
    document.getElementById("pro-rec-room").innerHTML = ReadValue(
      data["recRoom"]
    ); // Recreation Rm
    document.getElementById("pro-sauna").innerHTML = ReadValue(data["sauna"]); // Sauna
    document.getElementById("pro-share-perc").innerHTML = ReadValue(
      data["sharePerc"]
    ); // # Shares %
    document.getElementById("pro-spec-des").innerHTML = ReadValue(
      data["specDes"]
    ); // Special Designation
    document.getElementById("pro-sqs-rac-ct").innerHTML = ReadValue(
      data["sqsRacCt"]
    ); // Squash/Racquet Court
    document.getElementById("pro-stories").innerHTML = ReadValue(
      data["stories"]
    ); // Level
    document.getElementById("pro-tennis-ct").innerHTML = ReadValue(
      data["tennisCt"]
    ); // Tennis Court
    document.getElementById("pro-wtr-suptyp").innerHTML = ReadValue(
      data["wtrSuptyp"]
    ); // Water Supply Types
    document.getElementById("pro-zoning").innerHTML = ReadValue(data["zoning"]); // Zoning
    document.getElementById("pro-prop-mgmt").innerHTML = ReadValue(
      data["propMgmt"]
    ); // Property Management Company
    document.getElementById("pro-all-inc").innerHTML = ReadValue(
      data["allInc"]
    ); // All Inclusive Rental
    document.getElementById("pro-furnished").innerHTML = ReadValue(
      data["furnished"]
    ); // Furnished
    document.getElementById("pro-pvt-ent").innerHTML = ReadValue(
      data["pvtEnt"]
    ); // Private Entrance
    document.getElementById("pro-laundry").innerHTML = ReadValue(
      data["laundry"]
    ); // Laundry Access
    document.getElementById("pro-lease-term").innerHTML = ReadValue(
      data["leaseTerm"]
    ); // Lease Term
    document.getElementById("pro-pay-freq").innerHTML = ReadValue(
      data["payFreq"]
    ); // Payment Frequency
    document.getElementById("pro-pay-meth").innerHTML = ReadValue(
      data["payMeth"]
    ); // Payment Method
    document.getElementById("pro-app-req").innerHTML = ReadValue(
      data["appReq"]
    ); // Application Required
    document.getElementById("pro-sec-dep").innerHTML = ReadValue(
      data["secDep"]
    ); // Deposit Required
    document.getElementById("pro-credit-chk").innerHTML = ReadValue(
      data["creditChk"]
    ); // Credit Check
    document.getElementById("pro-emply-lett").innerHTML = ReadValue(
      data["emplyLett"]
    ); // Employment Letter
    document.getElementById("pro-lease").innerHTML = ReadValue(data["lease"]); // Lease Agreement
    document.getElementById("pro-ref-req").innerHTML = ReadValue(
      data["refReq"]
    ); // References Required
    document.getElementById("pro-opt-to-buy").innerHTML = ReadValue(
      data["optToBuy"]
    ); // Buy Option
    document.getElementById("pro-laundry-lev").innerHTML = ReadValue(
      data["laundryLev"]
    ); // Laundry Level
  } else {
    // Do Nothing
  }
}

export function SetupDdfPage(data, extra) {
  if (data !== null && !data.toString().includes("Invalid")) {
    document.getElementById("mls").innerHTML = ReadValue(data["mlsNo"]);
    if (data["price"] !== null) {
      document.getElementById("price").innerHTML =
        "$" + priceSwitch(ReadValue(data["price"]));
    }
    document.getElementById("oriprice").innerHTML = "";
    document.getElementById("address").innerHTML =
      ReadValue(data["address1"]) + ", " + ReadValue(data["city"]);
    document.getElementById("typestyle").innerHTML = ReadValue(data["type"]);

    document.getElementById("pro-type").innerHTML =
      ReadValue(data["type"]) + " " + ReadValue(data["style"]);
    if (ReadValue(data["bedroomNum2"]) === "") {
      document.getElementById("pro-bedrooms").innerHTML = ReadValue(
        data["bedroomNum"]
      );
      document.getElementById("tit-bedrooms").innerHTML = ReadValue(
        data["bedroomNum"]
      );
    } else {
      document.getElementById("pro-bedrooms").innerHTML =
        ReadValue(data["bedroomNum"]) + "+" + ReadValue(data["bedroomNum2"]);
      document.getElementById("tit-bedrooms").innerHTML =
        ReadValue(data["bedroomNum"]) + "+" + ReadValue(data["bedroomNum2"]);
    }
    document.getElementById("pro-kitchen").innerHTML = ReadValue(
      data["kitchenNum"]
    );
    document.getElementById("pro-bathrooms").innerHTML = ReadValue(
      data["bathNum"]
    );
    document.getElementById("tit-bathrooms").innerHTML = ReadValue(
      data["bathNum"]
    );
    // Land TODO
    if (ReadValue(data["frontFt"]) === "") {
      document.getElementById("pro-lotsize").innerHTML = "";
    } else {
      document.getElementById("pro-lotsize").innerHTML =
        ReadValue(data["frontFt"]) + "ft x " + ReadValue(data["depth"]) + "ft";
    }
    // Approx Age TODO
    document.getElementById("pro-yr-built").innerHTML = ReadValue(data["age"]);
    // Taxes / Taxes Year
    if (ReadValue(data["tax"]) === "") {
      document.getElementById("pro-taxes").innerHTML = "";
    } else {
      document.getElementById("pro-taxes").innerHTML =
        ReadValue(data["tax"]) + "(" + ReadValue(data["taxYear"]) + ")";
    }
    // Maintenance
    document.getElementById("pro-maint").innerHTML = ReadValue(
      data["maintenanceFee"]
    );
    // Parkings
    if (ReadValue(data["parkingNum"]) !== "") {
      document.getElementById("tit-parking").innerHTML = ReadValue(
        data["parkingNum"]
      );
      document.getElementById("pro-parking").innerHTML = ReadValue(
        data["parkingNum"]
      );
    }
    // Construction Style
    document.getElementById("pro-listeddate").innerHTML = ReadValue(
      data["createDate"]
    ).substring(0, 10);
    document.getElementById("pro-updatedate").innerHTML = ReadValue(
      data["lastUpdated"]
    ).substring(0, 10);
    document.getElementById("pro-broker").innerHTML = ReadValue(
      data["brokerageName"]
    );
    document.getElementById("pro-basement").innerHTML = ReadValue(
      data["basement"]
    );

    // Construction Style TODO
    document.getElementById("pro-construction-style").innerHTML = ReadValue(
      data["typeOwn1Out"]
    );
    // Front On
    document.getElementById("pro-front-on").innerHTML = ReadValue(
      data["compPts"]
    );
    // Community
    document.getElementById("pro-community").innerHTML = ReadValue(
      data["community"]
    );
    // Directions/Cross Streets
    document.getElementById("pro-cross-st").innerHTML = ReadValue(
      data["crossSt"]
    );
    // Water Supply
    document.getElementById("pro-water-supply").innerHTML = ReadValue(
      data["water"]
    );
    // AC System
    document.getElementById("pro-ac-system").innerHTML = ReadValue(data["ac"]);
    // Heating Fuel
    document.getElementById("pro-heating-fuel").innerHTML = ReadValue(
      data["fuel"]
    );
    // Heating Type
    document.getElementById("pro-heating-type").innerHTML = ReadValue(
      data["heatType"]
    );
    // Fireplace
    if (JSON.stringify(data).includes("Fireplace")) {
      document.getElementById("pro-fire-place").innerHTML = "Yes";
    } else {
      document.getElementById("pro-fire-place").innerHTML = "No";
    }
    // Central Vacum
    document.getElementById("pro-central-vac").innerHTML = CheckValue(
      data["centralVac"]
    );
    // Sewer
    document.getElementById("pro-sewer").innerHTML = ReadValue(data["sewer"]);
    document.getElementById("pro-construction").innerHTML = ReadValue(
      data["constr1Out"]
    );
    document.getElementById("pro-municipality").innerHTML = ReadValue(
      data["municipality"]
    );
    document.getElementById("pro-swimming-pool").innerHTML = ReadValue(
      data["pool"]
    );
    document.getElementById("pro-basement-type").innerHTML = ReadValue(
      data["bsmt1Out"]
    );
    document.getElementById("pro-basement-features").innerHTML = ReadValue(
      data["bsmt2Out"]
    );
    document.getElementById("pro-size-total").innerHTML =
      ReadValue(data["frontFt"]) + " x " + ReadValue(data["depth"]);
    document.getElementById("pro-amenities").innerHTML = (
      ReadValue(data["propFeat1Out"]) +
      " " +
      ReadValue(data["propFeat2Out"]) +
      " " +
      ReadValue(data["propFeat3Out"]) +
      " " +
      ReadValue(data["propFeat4Out"]) +
      " " +
      ReadValue(data["propFeat5Out"]) +
      " " +
      ReadValue(data["propFeat6Out"])
    ).trimEnd();
    document.getElementById("pro-extras").innerHTML = ReadValue(
      data["description"]
    );

    if (extra != null && extra.rooms != null) {
      for (var i = 0; i < extra.rooms.length; i++) {
        if (i < 12) {
          // TODO: need to increase html to handle more rooms information.
          document.getElementById("pro-room" + i + "-0").innerHTML =
            ReadValue(extra.rooms[i].type) +
            " (" +
            ReadValue(extra.rooms[i].description) +
            ")";
          if (
            extra.rooms[i].length === null ||
            extra.rooms[i].width === null ||
            extra.rooms[i].length === 0 ||
            extra.rooms[i].width === 0
          ) {
            document.getElementById("pro-room" + i + "-1").innerHTML = "-";
          } else {
            document.getElementById("pro-room" + i + "-1").innerHTML =
              ReadValue(extra.rooms[i].length.toFixed(2)) +
              "m x " +
              ReadValue(extra.rooms[i].width.toFixed(2)) +
              "m";
          }
        }
      }
    }

    // for (var i = 0; i < 5; i++) {
    //   if (ReadValue(data["wclosetT" + (i + 1)]) === "") {
    //     document.getElementById("pro-closet" + i + "-0").hidden = true;
    //     document.getElementById("pro-closet" + i + "-1").hidden = true;
    //   } else {
    //     document.getElementById("pro-closet" + i + "-0").innerHTML =
    //       "Wash Room (" + ReadValue(data["wclosetT" + (i + 1) + "lvl"] + ")");
    //     document.getElementById("pro-closet" + i + "-1").innerHTML =
    //       "Pieces: " + ReadValue(data["wclosetP" + (i + 1)]);
    //   }
    // }

    document.getElementById("pro-remarks").innerHTML = data["homeFeature"];
    // document.getElementById('pro-').innerHTML = ReadValue(data['']);
    // additional data added on May,28 2021
    document.getElementById("pro-ass-year").innerHTML = ReadValue(
      data["assYear"]
    ); // Assessment Year
    document.getElementById("pro-condo-exp").innerHTML = ReadValue(
      data["condoExp"]
    ); // Exposure (Condo)
    document.getElementById("pro-ens-lndry").innerHTML = ReadValue(
      data["ensLndry"]
    ); // Ensuite Laundry (Condo)
    document.getElementById("pro-farm-agri").innerHTML = ReadValue(
      data["farmAgri"]
    ); // Farm/Agriculture (Farm)
    document.getElementById("pro-gas").innerHTML = ReadValue(data["gas"]); // Utilities-Gas(Farm)
    document.getElementById("pro-heat-inc").innerHTML = ReadValue(
      data["heatInc"]
    ); // Heat Included
    document.getElementById("pro-hydro-inc").innerHTML = ReadValue(
      data["hydroInc"]
    ); // Hydro Included
    document.getElementById("pro-irreg").innerHTML = ReadValue(data["irreg"]); // Lot Irregularities
    document.getElementById("pro-locker").innerHTML = ReadValue(data["locker"]); // Locker
    document.getElementById("pro-locker-num").innerHTML = ReadValue(
      data["lockerNum"]
    ); // Locker #
    document.getElementById("pro-occupancy").innerHTML = ReadValue(
      data["occupancy"]
    ); // Occupancy
    document.getElementById("pro-park-chgs").innerHTML = ReadValue(
      data["parkChgs"]
    ); // Park Cost/Mo (Condo)
    document.getElementById("pro-park-desig").innerHTML = ReadValue(
      data["parkDesig"]
    ); // Parking Type
    document.getElementById("pro-park-fac").innerHTML = ReadValue(
      data["parkFac"]
    ); // Parking/Drive
    document.getElementById("pro-park-spc1").innerHTML = ReadValue(
      data["parkSpc1"]
    ); // Parking Spot #1
    document.getElementById("pro-park-spc2").innerHTML = ReadValue(
      data["parkSpc2"]
    ); // Parking Spot #2
    document.getElementById("pro-patio-ter").innerHTML = ReadValue(
      data["patioTer"]
    ); // Balcony
    document.getElementById("pro-pets").innerHTML = ReadValue(data["pets"]); // Pets Permitted
    document.getElementById("pro-pool").innerHTML = ReadValue(data["pool"]); // Pool
    document.getElementById("pro-prkg-inc").innerHTML = ReadValue(
      data["prkgInc"]
    ); // Parking Included
    document.getElementById("pro-secgrd-sys").innerHTML = ReadValue(
      data["secgrdSys"]
    ); // Security Guard System (Condo)
    document.getElementById("pro-sqft").innerHTML = ReadValue(
      data["propertySize"]
    ); // Approx Square Footage
    document.getElementById("pro-unit-num").innerHTML = ReadValue(
      data["unitNum"]
    ); // Unit # (Condo)
    document.getElementById("pro-water-inc").innerHTML = ReadValue(
      data["waterInc"]
    ); // Water Included
    document.getElementById("pro-zip").innerHTML = ReadValue(data["zip"]); // Postal Code
    document.getElementById("pro-kit-plus").innerHTML = ReadValue(
      data["kitPlus"]
    ); // Kitchens Plus
    document.getElementById("pro-retirement").innerHTML = ReadValue(
      data["retirement"]
    ); // Retirement
    document.getElementById("pro-waterfront").innerHTML = ReadValue(
      data["waterfront"]
    ); // Waterfront
    document.getElementById("pro-area-code").innerHTML = ReadValue(
      data["areaCode"]
    ); // Area Code
    document.getElementById("pro-municipality-code").innerHTML = ReadValue(
      data["municipalityCode"]
    ); // Municipality Code
    document.getElementById("pro-community-code").innerHTML = ReadValue(
      data["communityCode"]
    ); // Community Code
    document.getElementById("pro-area").innerHTML = ReadValue(data["area"]); // Area
    document.getElementById("pro-municipality-district").innerHTML = ReadValue(
      data["municipalityDistrict"]
    ); // Municipality District
    document.getElementById("pro-handi-equipped").innerHTML = ReadValue(
      data["handiEquipped"]
    ); // Physically Handicapped-Equipped
    document.getElementById("pro-area-infl1-out").innerHTML = ReadValue(
      data["areaInfl1Out"]
    ); // Area Influences1
    document.getElementById("pro-area-infl2-out").innerHTML = ReadValue(
      data["areaInfl2Out"]
    ); // Area Influences2
    document.getElementById("pro-cable").innerHTML = ReadValue(data["cable"]); // Cable
    document.getElementById("pro-city-water").innerHTML = ReadValue(
      data["cityWater"]
    ); // Utilities-Municipal Water
    document.getElementById("pro-insu-bldg").innerHTML = ReadValue(
      data["insurBldg"]
    ); // Building Insurance Included
    document.getElementById("pro-oth-struc1-out").innerHTML = ReadValue(
      data["othStruc1Out"]
    ); // Other Structures1
    document.getElementById("pro-oth-struc2-out").innerHTML = ReadValue(
      data["othStruc2Out"]
    ); // Other Structures2
    document.getElementById("pro-rec-room").innerHTML = ReadValue(
      data["recRoom"]
    ); // Recreation Rm
    document.getElementById("pro-sauna").innerHTML = ReadValue(data["sauna"]); // Sauna
    document.getElementById("pro-share-perc").innerHTML = ReadValue(
      data["sharePerc"]
    ); // # Shares %
    document.getElementById("pro-spec-des").innerHTML = ReadValue(
      data["specDes"]
    ); // Special Designation
    document.getElementById("pro-sqs-rac-ct").innerHTML = ReadValue(
      data["sqsRacCt"]
    ); // Squash/Racquet Court
    document.getElementById("pro-stories").innerHTML = ReadValue(
      data["stories"]
    ); // Level
    document.getElementById("pro-tennis-ct").innerHTML = ReadValue(
      data["tennisCt"]
    ); // Tennis Court
    document.getElementById("pro-wtr-suptyp").innerHTML = ReadValue(
      data["wtrSuptyp"]
    ); // Water Supply Types
    document.getElementById("pro-zoning").innerHTML = ReadValue(data["zoning"]); // Zoning
    document.getElementById("pro-prop-mgmt").innerHTML = ReadValue(
      data["propMgmt"]
    ); // Property Management Company
    document.getElementById("pro-all-inc").innerHTML = ReadValue(
      data["allInc"]
    ); // All Inclusive Rental
    document.getElementById("pro-furnished").innerHTML = ReadValue(
      data["furnished"]
    ); // Furnished
    document.getElementById("pro-pvt-ent").innerHTML = ReadValue(
      data["pvtEnt"]
    ); // Private Entrance
    document.getElementById("pro-laundry").innerHTML = ReadValue(
      data["laundry"]
    ); // Laundry Access
    document.getElementById("pro-lease-term").innerHTML = ReadValue(
      data["leaseTerm"]
    ); // Lease Term
    document.getElementById("pro-pay-freq").innerHTML = ReadValue(
      data["payFreq"]
    ); // Payment Frequency
    document.getElementById("pro-pay-meth").innerHTML = ReadValue(
      data["payMeth"]
    ); // Payment Method
    document.getElementById("pro-app-req").innerHTML = ReadValue(
      data["appReq"]
    ); // Application Required
    document.getElementById("pro-sec-dep").innerHTML = ReadValue(
      data["secDep"]
    ); // Deposit Required
    document.getElementById("pro-credit-chk").innerHTML = ReadValue(
      data["creditChk"]
    ); // Credit Check
    document.getElementById("pro-emply-lett").innerHTML = ReadValue(
      data["emplyLett"]
    ); // Employment Letter
    document.getElementById("pro-lease").innerHTML = ReadValue(data["lease"]); // Lease Agreement
    document.getElementById("pro-ref-req").innerHTML = ReadValue(
      data["refReq"]
    ); // References Required
    document.getElementById("pro-opt-to-buy").innerHTML = ReadValue(
      data["optToBuy"]
    ); // Buy Option
    document.getElementById("pro-laundry-lev").innerHTML = ReadValue(
      data["laundryLev"]
    ); // Laundry Level
  } else {
    // Do Nothing
  }
}

// export function createImages(arr) {
//   var st = document.getElementById("testing");
//   for (var i = 0; i < arr.length; i++) {
//     st.children[0].innerHTML = buildImageElement(arr[i]);
//   }
// }

// export function buildImageElement(ele) {
//   let str0 = '<div className="inner_bannersec"><img src="';
//   let str1 = '" alt="" /></div>';
//   return str0 + ele + str1;
// }

export function createLog(arr) {
  var st = document.getElementById("detail_log");
  if (arr !== null && arr !== []) {
    for (var i = 0; i < arr.length; i++) {
      st.children[0].innerHTML += buildLogElement(arr[i]);
      // st.children[0].innerHTML += '<li>' + arr[i] + '</li>';
    }
  }
}

export function createHistory(arr, lang) {
  var st = document.getElementById("detail_history");
  if (arr !== null && arr !== []) {
    for (var i = 0; i < arr.length; i++) {
      let ele = arr[i].split("#");
      if (ele.length === 10) {
        st.children[0].innerHTML += buildHistoryElement10(ele, lang);
      } else if (ele.length === 7) {
        st.children[0].innerHTML += buildHistoryElement7(ele, lang);
        // st.children[0].innerHTML += '<li>' + arr[i] + '</li>';
      }
    }
  }
}

function buildLogElement(ele) {
  var result = "";
  result += '<li><span class="prohis_leftsec"><h1>';
  // result += priceSwitch(ele.Id.split(".")[0]);
  result += ele.KeyName;
  result += "Change</h1>";
  result += "<small>From  ";
  result += priceSwitch(ele.KeyValue0.split(".")[0]);
  result += "  to  ";
  result += priceSwitch(ele.KeyValue1.split(".")[0]);
  result += "</small><p>Date: <b>";
  result += ele.OptDate.split("T")[0];
  result += "</p></span></li>";
  return result;
}

function buildHistoryElement10(arr, lang) {
  // id, price, sold price, MLS number, listed since, sold date, expiry date, last status, last update, listing type
  var result = "";
  var status = "";
  arr[7] = arr[7].toLowerCase();
  result += '<li><span class="prohis_leftsec"><h1>$';

  if (arr[2] !== "") {
    result += priceSwitch(arr[2].split(".")[0]);
    result +=
      "   <small><del>" + priceSwitch(arr[1].split(".")[0]) + "</del></small>";
  } else {
    result += priceSwitch(arr[1].split(".")[0]);
  }

  result += "<br/><small>(mls: #";
  result += arr[3];
  result += ")</small></h1><p>" + lang.dtl_property_history_listedat + ": <b>";
  result += arr[4].split(" ")[0];
  // pc : price change
  if (arr[7] === "new" || arr[7] === "pc") {
    result += "</b> --- " + lang.dtl_property_history_updateat + ": <b>";
    result += arr[8].split(" ")[0];
  } else if (arr[5] !== "") {
    result += "</b> --- " + lang.dtl_property_history_soldat + ": <b>";
    result += arr[5].split(" ")[0];
  } else {
    result += "</b> --- " + lang.dtl_property_history_expiryat + ": <b>";
    result += arr[6].split(" ")[0];
  }

  result +=
    '</b></p></span><span class="prohis_rightsec"><p class="prostatus">';
  if (arr[7] === "new") {
    status = lang.dtl_property_status_new;
  } else if (arr[7] === "sld") {
    status = lang.dtl_property_status_sld;
  } else if (arr[7] === "sc") {
    status = lang.dtl_property_status_sc;
  } else if (arr[7] === "lsd") {
    status = lang.dtl_property_status_lsd;
  } else if (arr[7] === "lsc") {
    status = lang.dtl_property_status_lsc;
  } else if (arr[7] === "sus") {
    status = lang.dtl_property_status_sus;
  } else if (arr[7] === "pc") {
    status = lang.dtl_property_status_pc;
  } else if (arr[7] === "dft") {
    status = lang.dtl_property_status_dft;
  } else if (arr[7] === "exp") {
    status = lang.dtl_property_status_exp;
  } else if (arr[7] === "ext") {
    status = lang.dtl_property_status_ext;
  } else if (arr[7] === "bom") {
    status = lang.dtl_property_status_bom;
  } else if (arr[7] === "ter") {
    status = lang.dtl_property_status_ter;
  } else if (arr[7] === "dom") {
    status = lang.dtl_property_status_dom;
  } else {
    status = lang.dtl_property_status_unknown;
  }
  result += status;
  result += "</p></span></li>";
  return result;
}

function buildHistoryElement7(arr, lang) {
  // id, price, MLS number, listed since, expiry date, last status, last update
  var result = "";
  var status = "";
  arr[5] = arr[5].toLowerCase();
  result += '<li><span class="prohis_leftsec"><h1>$';
  result += priceSwitch(arr[1].split(".")[0]);
  result += "<small>(mls: #";
  result += arr[2];
  result += ")</small></h1><p>" + lang.dtl_property_history_listedat + ": <b>";
  result += arr[3].split(" ")[0];
  if (arr[5] === "new" || arr[5] === "pc") {
    result += "</b> / " + lang.dtl_property_history_updateat + ": <b>";
    result += arr[6].split(" ")[0];
  } else {
    result += "</b> / " + lang.dtl_property_history_expiryat + ": <b>";
    result += arr[6].split(" ")[0];
  }
  // result += arr[4].split(" ")[0];
  result +=
    '</b></p></span><span class="prohis_rightsec"><p class="prostatus">';
  // var status = "";
  // arr[5] = arr[5].toLowerCase();
  if (arr[5] === "new") {
    status = lang.dtl_property_status_new;
  } else if (arr[5] === "sld") {
    status = lang.dtl_property_status_sld;
  } else if (arr[5] === "sc") {
    status = lang.dtl_property_status_sc;
  } else if (arr[5] === "lsd") {
    status = lang.dtl_property_status_lsd;
  } else if (arr[5] === "lsc") {
    status = lang.dtl_property_status_lsc;
  } else if (arr[5] === "sus") {
    status = lang.dtl_property_status_sus;
  } else if (arr[5] === "pc") {
    status = lang.dtl_property_status_pc;
  } else if (arr[5] === "dft") {
    status = lang.dtl_property_status_dft;
  } else if (arr[5] === "exp") {
    status = lang.dtl_property_status_exp;
  } else if (arr[5] === "ext") {
    status = lang.dtl_property_status_ext;
  } else if (arr[5] === "bom") {
    status = lang.dtl_property_status_bom;
  } else if (arr[5] === "ter") {
    status = lang.dtl_property_status_ter;
  } else if (arr[5] === "dom") {
    status = lang.dtl_property_status_dom;
  } else {
    status = lang.dtl_property_status_unknown;
  }
  result += status;
  result += "</p></span></li>";
  return result;
}

export function createMortgage(amt, sid, loaded) {
  var st = document.getElementById("iframe_mortgage");
  if (amt !== null && sid !== null) {
    if (loaded === false) {
      st.hidden = false;
      st.src =
        "https://yiju.ca/eziapp/mortcal.aspx?ngw=" +
        "256345734563" +
        "&sid=" +
        sid +
        "&amt=" +
        amt;
      return true;
    }
  }
  return false;
}

export function createSchoolReport(lat, lng, loaded) {
  var st = document.getElementById("iframe_school");
  if (lat !== null && lng !== null) {
    if (loaded === false) {
      var ngw = replaceAllFixed(window.btoa(lat + "," + lng), "=", "~");
      st.hidden = false;
      st.src = gesture + findschoolhost + "/findschool?" + ngw;
      return true;
    }
  }
  return false;
}

export function createCensusData(zip, tk, loaded) {
  var st = document.getElementById("iframe_census");
  if (zip !== null && zip !== undefined) {
    // if (dtl["zip"] !== null) {
    if (loaded === false) {
      st.hidden = false;
      st.src =
        gesture +
        document.location.host +
        "/census2016?" +
        tk +
        "=" +
        zip.substring(0, 3);
      return true;
    }
    // }
  }
  return false;
}

export function createCensusDataSharing(zip, sh, loaded) {
  var st = document.getElementById("iframe_census");
  if (zip !== null) {
    if (zip !== null) {
      if (loaded === false) {
        st.hidden = false;
        st.src =
          gesture +
          document.location.host +
          "/shcensus2016?" +
          sh +
          "=" +
          zip.substring(0, 3);
        return true;
      }
    }
  }
  return false;
}

export function createWalkScore(address, tk, loaded) {
  var st = document.getElementById("iframe_walkscore");
  if (address !== null) {
    if (loaded === false) {
      let bs64 = replaceAllFixed(window.btoa(address), "=", "~");
      st.hidden = false;
      st.src =
        gesture + document.location.host + "/walkscore?" + tk + "=" + bs64;
      return true;
    }
  }
  return false;
}

export async function createWalkScoreSharing(address, sh, loaded) {
  var st = document.getElementById("iframe_walkscore");
  if (address !== null) {
    if (loaded === false) {
      let bs64 = replaceAllFixed(window.btoa(address), ("=", "~"));
      st.hidden = false;
      st.src =
        gesture + document.location.host + "/shwalkscore?" + sh + "=" + bs64;
      return true;
    }
  }
  return false;
}

export function createCommunityChart(community, nodetype, tk, loaded) {
  var st = document.getElementById("iframe_community");
  if (loaded === false) {
    var cmt = "," + community;
    var curDate = new Date();
    var month = curDate.getFullYear() + "-" + curDate.getMonth() + "-01";
    if (curDate.getMonth() === 0) {
      month = curDate.getFullYear() - 1 + "-12-01";
    }
    if (nodetype === null || nodetype === undefined) {
      nodetype = "0";
    }
    var param = "chart;" + cmt + ";" + "S" + ";" + month + ";3;" + nodetype;
    var bs64 = replaceAllFixed(window.btoa(param), "=", "~");
    st.hidden = false;
    st.src =
      gesture + document.location.host + "/reportchart?" + tk + "=" + bs64;
    return true;
  }
  return false;
}

export function createCommunityChartSharing(community, nodetype, sh, loaded) {
  var st = document.getElementById("iframe_community");
  if (loaded === false) {
    var cmt = "," + community;
    var curDate = new Date();
    var month = curDate.getFullYear() + "-" + curDate.getMonth() + "-01";
    if (curDate.getMonth() === 0) {
      month = curDate.getFullYear() - 1 + "-12-01";
    }
    var param = "chart;" + cmt + ";" + "S" + ";" + month + ";3;" + nodetype;
    var bs64 = replaceAllFixed(window.btoa(param), "=", "~");
    st.hidden = false;
    st.src =
      gesture + document.location.host + "/shreportchart?" + sh + "=" + bs64;
    return true;
  }
  return false;
}

function calcPageHeight(doc) {
  // var cHeight = Math.max(
  //   doc.body.clientHeight,
  //   doc.documentElement.clientHeight
  // );
  var sHeight = Math.max(
    doc.body.scrollHeight,
    doc.documentElement.scrollHeight
  );
  var height = Math.max(12, sHeight);
  return height;
}

export function fixFrameHeight(iframeName, fixSize) {
  var iframe = document.getElementById(iframeName);
  iframe.onload = function () {
    var iDoc =
      iframe.contentDocument || iframe.document || iframe.contentWindow;
    var height = calcPageHeight(iDoc);
    if (height * fixSize > 2150) {
      iframe.style.height = 2150 + "px";
    } else {
      iframe.style.height = height * fixSize + "px";
    }
  };
}

export function adjustFrameHieght(iframeName, fixSize) {
  const parentWindow = window.parent;
  const ifm = parentWindow.document.getElementById(iframeName);
  ifm.style.height = Number(ifm.style.height.split("px")[0]) - fixSize + "px";
}

export function addAccordionClick() {
  var acc = document.getElementsByClassName("accordion");
  var i;
  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }
}

// export function MeterFeetClick(dtl) {
//   var btn = document.getElementById("pro-btn-mf");

//   if (dtl !== null && !dtl.toString().includes("Invalid")) {
//     let data = dtl;
//     if (btn.innerText === "Change To Feet") {
//       for (var i = 0; i < 12; i++) {
//         if (
//           (data["rm" + (i + 1) + "Len"] !== 0 &&
//             data["rm" + (i + 1) + "Len"] !== null) ||
//           (data["rm" + (i + 1) + "Wth"] !== 0 &&
//             data["rm" + (i + 1) + "Wth"] !== null)
//         ) {
//           let len = (ReadValue(data["rm" + (i + 1) + "Len"]) * 3.28)
//             .toString()
//             .substring(0, 5);
//           let wid = (ReadValue(data["rm" + (i + 1) + "Wth"]) * 3.28)
//             .toString()
//             .substring(0, 5);

//           document.getElementById("pro-room" + i + "-1").innerHTML =
//             len + "ft x " + wid + "ft";
//         }
//       }
//       btn.innerText = "Change To Meter";
//     } else {
//       for (var i = 0; i < 12; i++) {
//         if (
//           (data["rm" + (i + 1) + "Len"] !== 0 &&
//             data["rm" + (i + 1) + "Len"] !== null) ||
//           (data["rm" + (i + 1) + "Wth"] !== 0 &&
//             data["rm" + (i + 1) + "Wth"] !== null)
//         ) {
//           document.getElementById("pro-room" + i + "-1").innerHTML =
//             ReadValue(data["rm" + (i + 1) + "Len"]) +
//             "m x " +
//             ReadValue(data["rm" + (i + 1) + "Wth"]) +
//             "m";
//         }
//       }
//       btn.innerText = "Change To Feet";
//     }
//   }
// }

export function MeterFeetClick() {
  var btn = document.getElementById("pro-btn-mf");
  if (btn.innerText === "Change To Feet") {
    for (var i = 0; i < 12; i++) {
      let lw = document.getElementById("pro-room" + i + "-1");
      if (lw !== null && lw.innerText.length > 3) {
        let lenwid = lw.innerText.split("x");
        let len = lenwid[0].trim().substring(0, lenwid[0].length - 2);
        let wid = lenwid[1].trim().substring(0, lenwid[1].length - 2);
        document.getElementById("pro-room" + i + "-1").innerHTML =
          (len * 3.28).toFixed(2) + "ft x " + (wid * 3.28).toFixed(2) + "ft";
      }
    }
    btn.innerText = "Change To Meter";
  } else {
    for (var i = 0; i < 12; i++) {
      let lw = document.getElementById("pro-room" + i + "-1");
      if (lw !== null && lw.innerText.length > 3) {
        let lenwid = lw.innerText.split("x");
        let len = lenwid[0].trim().substring(0, lenwid[0].length - 3);
        let wid = lenwid[1].trim().substring(0, lenwid[1].length - 3);
        document.getElementById("pro-room" + i + "-1").innerHTML =
          (len / 3.28).toFixed(2) + "m x " + (wid / 3.28).toFixed(2) + "m";
      }
    }
    btn.innerText = "Change To Feet";
  }
}
