import React, { Component } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Base64 } from "js-base64";
import { datahost, shkyhost } from "../../config/Const.js";
import { passParams } from "../../config/Connector.js";

import enj from "../../language/en.json";
import zhj from "../../language/zh.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../style/Article.css";

export class Pre_S_Article_Base extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      sh: null,
      media: null,
    };
  }

  componentDidMount() {
    this.loadArticle();
  }

  render() {
    return (
      <div className="wrapper">
        <div className="wrapper_margin">
          <Button
            id="pro-btn-mf"
            className="pull-left"
            onClick={() => this.BackClick()}
          >
            <i className="fa fa-angle-left"></i>
            {this.state.lang.back}
          </Button>
          <Button
            id="pro-btn-mf"
            className="pull-right"
            onClick={() => this.shareMedia()}
          >
            <i className="fa fa-share"></i>
            {this.state.lang.share}
          </Button>
          <br />
          <br />
          <div className="shared_title" id="nTitle"></div>
          <br />
          <br />
          <div className="shared_content" id="nContent"></div>
        </div>

        <div id="footer"></div>
      </div>
    );
  }

  initLanguage() {
    let language = (
      navigator.browserLanguage || navigator.language
    ).toLowerCase();
    if (language.indexOf("zh") > -1) {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadArticle() {
    try {
      let url = document.location.toString();
      let paras = url.split("?")[1];
      let tk = paras.split("=")[0];
      let mediaId = paras.split("=")[1];
      this.setState({ tk: tk });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param0 = "\"{'tk':'" + tk + "','id':'" + mediaId + "','hd':'1'}\"";
      axios.post("/share/token", param0).then((res0) => {
        // create and save share token
        this.setState({ sh: res0.data });
        // launch shared page
        let param1 = "\"{'sh':'" + res0.data + "'}\"";
        axios.post("/share/media", param1).then((res1) => {
          this.setState({ media: res1.data });
          let nt = document.getElementById("nTitle");
          nt.innerText = res1.data.data.nTitle;
          let nc = document.getElementById("nContent");
          nc.innerHTML = res1.data.data.nContent;
        });
      });
    } catch {}
  }

  shareMedia() {
    let md = this.state.media.data;
    let url = shkyhost + "/shmedia?" + this.state.sh;
    let str = url + ";" + md.nTitle + ";" + md.nBrief + ";" + md.firstImage;
    let params = Base64.encode(str);
    passParams("sharemedia", params);
  }

  BackClick() {
    window.history.go(-1);
  }
}
